import {useEffect, useRef, useState} from "react";
import {unixTimestampToDateTime} from "../service/DateService";
import RedditMentionsChart from './RedditMentionsChart'; // Import the new component

const dayFilters = ['1D', '2D', '3D', '4D', '5D', '6D', '7D'];

function AnalyzeReddit(props) {
    const comments = props.analyzeInfo.redditComments;
    const [clickedIndex, setClickedIndex] = useState(null);
    const [selectedDayFilter, setSelectedDayFilter] = useState('1D');
    let contentRefs = useRef([]);

    const handleArrowClick = (index) => {
        setClickedIndex(clickedIndex === index ? null : index);
        if (clickedIndex !== index) {
            setTimeout(() => {
                const commentsElements = document.querySelectorAll('.comments');
                commentsElements.forEach(el => {
                    if (el.scrollTop > 0) {
                        el.scrollTop = 0;
                    }
                });
            }, 0);
        }
    };

    useEffect(() => {
        contentRefs.current = contentRefs.current.slice(0, comments?.length);
    }, [comments]);

    const handleDayFilterClick = (day) => {
        setSelectedDayFilter(day);
    };

    const filterCommentsByTime = (comments, dayFilter) => {
        const now = Math.floor(Date.now() / 1000);
        const dayFilterSeconds = parseInt(dayFilter) * 24 * 60 * 60;

        return comments.filter(comment => now - comment.created <= dayFilterSeconds);
    };

    function highlightWordInText(text, word) {
        const highlighted = text.replace(new RegExp(`(${word})`, 'gi'), '<strong style="border: #b619ff 2px solid; border-radius: 5px; padding-left: 3px; padding-right: 2px">$1</strong>');
        return {__html: highlighted};
    }

    return (
        <div className={"admin-dashboard-content fade-in"} style={{overflow: 'visible', maxHeight: "max-content"}}
             onClick={() => props.setSuggestions([])}>
            {props.errorSuccessMessage && (
                <div className="success-or-error"
                     style={{color: props.errorSuccessMessage.props.style.color, opacity: props.errorSuccessOpacity}}>
                    {props.errorSuccessMessage}
                </div>
            )}
            {comments !== null && comments.length > 0 ?
                <div className={"reddit-mentions-chart-container fade-in"}>
                    <div className={"reddit-chart-heading"} style={{fontWeight: 'bold'}}>Mentions (7D)</div>
                    <RedditMentionsChart redditComments={comments}/>
                </div>
                :
                null
            }
            {comments === null || comments.length < 1 ? null
                : (
                    <>
                        <div className={"reddit-switch-buttons"}>
                            {dayFilters.map((day, index) => (
                                <div key={index} className={"reddit-switch-button"}
                                     onClick={() => handleDayFilterClick(day)}
                                     style={{border: selectedDayFilter === day ? 'solid 2px #b619ff' : 'none'}}>{day}</div>
                            ))}
                        </div>
                        <div className={"reddit-comments"}>

                            {comments.map((comment, index) => {
                                const filteredContents = filterCommentsByTime(comment.redditContents, selectedDayFilter);
                                if (filteredContents.length > 0) {
                                    return (
                                        <div key={index}>
                                            <div
                                                className={`comments ${clickedIndex === index ? 'expanded' : ''}`}
                                                style={{
                                                    maxHeight: clickedIndex !== index ? `50px` : `600px`
                                                }}
                                                ref={el => {
                                                    contentRefs.current[index] = el;
                                                    if (el && clickedIndex === index) {
                                                        el.style.maxHeight = `600px`;
                                                    }
                                                }}
                                            >
                                                <button
                                                    type={"button"}
                                                    className={`expand-collapse-button ${clickedIndex === index ? 'expanded' : ''}`}
                                                    onClick={() => handleArrowClick(index)}>V
                                                </button>

                                                <div
                                                    className={`comment-subreddit-name ${clickedIndex === index ? 'expanded' : ''}`}
                                                    onClick={() => handleArrowClick(index)}
                                                >
                                                    r/{comment.subreddit} ({new Intl.NumberFormat('en-US').format(filteredContents.length.toFixed(2))})
                                                </div>
                                                <div>
                                                    {filteredContents.sort((a, b) => b.created - a.created)
                                                        .map((redditContent) => (
                                                            <div className={"reddit-comment-row"}
                                                                 key={redditContent.permalink}>
                                                                <div
                                                                    className={"reddit-comment-date"}>{unixTimestampToDateTime(redditContent.created)}</div>

                                                                <div className={"reddit-comment"}
                                                                     dangerouslySetInnerHTML={highlightWordInText(redditContent.body?.replaceAll("\n", "<br>"), props.symbol)}/>
                                                                <div className={"reddit-comment-author"}>
                                                                    <a className={"dashboard-link"}
                                                                       href={`https://reddit.com/user/${redditContent.author}`}
                                                                       target="_blank"
                                                                       rel="noopener noreferrer">
                                                                        - u/{redditContent.author}
                                                                    </a></div>
                                                                <div className={"reddit-comment-link"}>
                                                                    <a className={"dashboard-link"}
                                                                       href={`https://reddit.com${redditContent.permalink}`}
                                                                       target="_blank"
                                                                       rel="noopener noreferrer">
                                                                        view on r/{comment.subreddit}
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })
                            }
                        </div>
                    </>
                )}
        </div>
    )
}

export default AnalyzeReddit;
