import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {resetPassword} from "../service/ResetPasswordService";
import Loading from "./Loading";
import {red, green} from "../constants/Constants";

function ResetPassword() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [confirmationToken, setConfirmationToken] = useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const confirmationToken = searchParams.get("reset");
        setConfirmationToken(confirmationToken);
    }, []);

    const [errorSuccessMessage, setErrorSuccessMessage] = useState(null);
    const [errorSuccessOpacity, setErrorSuccessOpacity] = useState(1);

    function addErrorSuccess(message, color, passwordRestriction) {
        if (window.innerWidth >= 991 || color === green) {
            setErrorSuccessMessage(
                <div
                    className={`success-or-error ${passwordRestriction ? 'success-or-error-password-restriction' : ''}`}
                    style={{color: color, opacity: errorSuccessOpacity}}>
                    {message}
                </div>
            );
            setErrorSuccessOpacity(1);

            setTimeout(() => {
                setErrorSuccessOpacity(0);
            }, 2000);

            setTimeout(() => {
                setErrorSuccessOpacity(1);
                setErrorSuccessMessage(null);
            }, 3000);
        } else {
            window.alert(message);
        }
    }

    function validatePassword() {
        // Regex explanation:
        // ^                 : start of string
        // (?=.*[a-z])       : at least one lowercase letter
        // (?=.*[A-Z])       : at least one uppercase letter
        // (?=.*\d)          : at least one number
        // .{8,}             : at least 8 characters
        // $                 : end of string
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

        return regex.test(password);
    }

    const sentForm = (
        <div className="content center">
            <div className={"no-account"}>Your password has been reset.</div>
            <div className={"no-account"}>
                <span>Please click </span>
                <a href={"/login"} className={"click-here"}>here</a>
                <span> to log in</span></div>
        </div>
    )

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validatePassword()) {
            addErrorSuccess('Password must be at least 8 characters long and must include at least 1 uppercase letter, 1 lowercase letter, and 1 number.', red, true)
        } else if (password !== confirmPassword) {
            addErrorSuccess('Passwords do not match.')
        } else {
            setLoading(true);
            try {
                resetPassword(email, password, confirmPassword, confirmationToken)
                    .then(resetEmailResponse => {
                        if (resetEmailResponse === true) {
                            setSent(true);
                        } else {
                            addErrorSuccess("Could not reset password.", red);
                        }
                        setLoading(false)
                        // navigate("/account");
                    })
                    .catch(error => {
                        console.error("Error resetting password:", error);
                        addErrorSuccess("Could not reset password.", red);
                        setLoading(false);
                    });

            } catch (error) {
                console.error('Password reset failed:', error);
                addErrorSuccess("Could not reset password.", red);
                setLoading(false);
            }
        }
    };

    const resetForm = (
        <form onSubmit={handleSubmit} className={"fade-in center"} style={{position: "relative"}}>
            {errorSuccessMessage && (
                <div className="success-or-error-login" style={{ color: errorSuccessMessage.props.style.color, opacity: errorSuccessOpacity }}>
                    {errorSuccessMessage.props.children}
                </div>
            )}
            <input className={"contact-form-input"}
                   required
                   type={"email"}
                   placeholder={"Email"}
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   type={"password"}
                   placeholder={"New Password"}
                   value={password}
                   onChange={(e) => setPassword(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   type={"password"}
                   placeholder={"Confirm Password"}
                   value={confirmPassword}
                   onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className={"send-wrapper mobile-margin-bottom-0"}>
                <button type={"submit"} className="search">Submit</button>
            </div>
        </form>
    )

    return (
        <div className={"login page"}>
            <Header/>
            <div className={"small-heading"}>Hello!</div>
            <div className={"content"}>
                <div className={"section enclosed row"}>
                    <div className={"half-section login-form"}>
                        {loading ? <Loading text={"Sending Reset Email"}/>
                            :
                            <>
                                <div className={"fade-in"} style={{margin: 'auto', position: "relative"}}>
                                    <button onClick={() => navigate("/login")} className={"search fade-in"}
                                            style={{position: "absolute", left: '0', top: '0'}}
                                    >&larr;</button>
                                </div>
                                <div className={"section-subheading center"} style={{marginBottom:'1rem'}}>Reset Password</div>
                                {!sent ?
                                    resetForm
                                    :
                                    sentForm}
                            </>
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ResetPassword;