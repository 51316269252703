import React, {useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment-timezone';
import {green, grey, red, white} from "../constants/Constants";

const RedditMentionsChart = ({redditComments}) => {
    const [series, setSeries] = useState([]);

    useEffect(() => {
        const now = Math.floor(Date.now() / 1000);
        const sevenDaysAgo = now - 7 * 24 * 60 * 60;

        // Filter comments from the last 7 days and count mentions per day
        const mentionsPerDay = Array(7).fill(0); // Initialize array for 7 days
        redditComments.forEach(commentGroup => {
            commentGroup.redditContents.forEach(comment => {
                if (comment.created >= sevenDaysAgo) {
                    const dayIndex = Math.floor((now - comment.created) / (24 * 60 * 60));
                    mentionsPerDay[6 - dayIndex] += 1;
                }
            });
        });

        const categories = Array(7).fill(0).map((_, i) => `${7 - i}D`);

        setSeries([{
            name: 'Mentions',
            data: mentionsPerDay,
            color: mentionsPerDay[0] < mentionsPerDay[mentionsPerDay.length - 1] ? green : mentionsPerDay[0] > mentionsPerDay[mentionsPerDay.length - 1] ? red : grey
        }]); // Set the color here
        setOptions((prevOptions) => ({
            ...prevOptions,
            xaxis: {...prevOptions.xaxis, categories}
        }));
    }, [redditComments]);

    const [options, setOptions] = useState({
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            animations: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: 2,
        },
        grid: {
            yaxis: {
                lines: {
                    show: false
                }
            },
            xaxis: {
                lines: {
                    show: false
                }
            }
        },
        xaxis: {
            categories: [],
            labels: {
                style: {
                    colors: '#fff'
                }
            },
            axisBorder: {
                show: true,
                color: white,
                height: 1,
                width: '100%',
                offsetX: -1,
                offsetY: 2,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#fff'
                },
                formatter: (value) => {
                    return Math.floor(value) === value ? value : '';
                },
            },
            axisBorder: {
                show: true,
                color: white,
                width: 1,
                offsetX: -2,
                offsetY: 0,
            },
        },
        tooltip: {
            theme: 'dark',
            marker: {
                show: true
            },
            x: {
                show: false,
            },
            fixed: {
                enabled: false,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0,
            },
        },
        legend: {
            show: false
        }
    });

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={150}
            width={'100%'}
        />
    );
};

export default RedditMentionsChart;
