import {refreshToken} from "./AuthService";
import {properties} from "../properties";
import {rfAccessToken, rfRefreshToken} from "../constants/Constants";

export function fetchHomeContent(firstCall) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchHomeContent(false).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to fetch content."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/home/content", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);
        xhttp.send();
    });
}

export function fetchHomeContentNoAccount() {
    return new Promise((resolve, reject) => {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else {
                    reject(new Error("Failed to fetch content."));
                }
            }
        };

        // // Properly store the array in localStorage as a JSON string
        // localStorage.setItem("watchListSymbols", JSON.stringify(['AAPL']));

// When sending the request, ensure it's encoded correctly
        const watchListSymbols = JSON.stringify(JSON.parse(localStorage.getItem("rfWatchListSymbols")));
        const encodedSymbols = encodeURIComponent(watchListSymbols);


        xhttp.open("GET", properties.host + "/noaccount/home/content?watchlist=" + encodedSymbols, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.send();
    });
}

export function fetchUserDashboardSubscription(firstCall, subscriptionId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchUserDashboardSubscription(false, subscriptionId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to fetch subscription."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/dashboard/get-subscription?subscriptionId=" + subscriptionId, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function editPaymentMethod(firstCall, paymentMethodId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        editPaymentMethod(false, paymentMethodId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to edit payment method."));
                }
            }
        };
        xhttp.open("POST", properties.host + "/dashboard/edit-payment-method", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        const paymentMethod = {
            paymentMethodId: paymentMethodId
        };

        xhttp.send(JSON.stringify(paymentMethod));
    });
}

export function fetchUserDashboardPaymentLink(firstCall, paymentLinkId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchUserDashboardPaymentLink(false, paymentLinkId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to fetch payment link."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/dashboard/get-payment-link?paymentLinkId=" + paymentLinkId, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}


export function fetchUserDashboardCharge(firstCall, chargeId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchUserDashboardCharge(false, chargeId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to fetch charge."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/dashboard/get-charge?chargeId=" + chargeId, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function addWatch(firstCall, symbol) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    if (this.responseText === null || this.responseText === undefined || this.responseText === "") {
                        reject(new Error("Failed to add watch list symbol."));
                    } else {
                        resolve(JSON.parse(this.responseText));
                    }
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        addWatch(false, symbol).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to add watch list symbol."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/home/add-watchlist-entry?symbol=" + symbol, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function addWatchNoAccount(symbol) {
    return new Promise((resolve, reject) => {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    if (this.responseText === null || this.responseText === undefined || this.responseText === "") {
                        reject(new Error("Failed to add watch list symbol."));
                    } else {
                        resolve(JSON.parse(this.responseText));
                    }
                } else {
                    reject(new Error("Failed to add watch list symbol."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/noaccount/home/add-watchlist-entry?symbol=" + symbol, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.send();
    });
}

export function removeWatch(firstCall, symbol) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        removeWatch(false, symbol).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to remove watch list symbol."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/home/remove-watchlist-entry?symbol=" + symbol, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function saveNotificationCall(firstCall, notification) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        saveNotificationCall(false, notification).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to save notification."));
                }
            }
        };
        xhttp.open("POST", properties.host + "/home/save-notification", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);
        xhttp.send(JSON.stringify(notification));
    });
}

export function deleteNotificationCall(firstCall, notification) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        deleteNotificationCall(false, notification).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to save notification."));
                }
            }
        };
        xhttp.open("POST", properties.host + "/home/delete-notification", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);
        xhttp.send(JSON.stringify(notification));
    });
}

    export function fetchUserContent(firstCall) {
        return new Promise((resolve, reject) => {
            const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
            let xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        resolve(JSON.parse(this.responseText));
                    } else if (this.status === 403 && firstCall) {
                        // If the token is expired, try to refresh it
                        refreshToken().then(newAccessToken => {
                            localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                            // Retry the original request with the new access token
                            fetchUserContent(false).then(resolve).catch(reject);
                        }).catch(() => {
                            localStorage.removeItem(rfAccessToken);
                            localStorage.removeItem(rfRefreshToken);
                            reject({ error: new Error("Failed to refresh token."), status: 403 });
                        });
                    } else {
                        reject(new Error("Failed to remove watch list symbol."));
                    }
                }
            };
            xhttp.open("GET", properties.host + "/users/userinfo", true);
            xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

            xhttp.send();
        });
    }