import React, {useMemo, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment-timezone';
import {
    blue,
    ema12,
    ema12Display,
    ema26,
    ema26Display,
    green,
    lowerBollinger,
    lowerBollingerDisplay,
    orange,
    price,
    priceDisplay,
    purple,
    red,
    sma50,
    sma50Display,
    sma200Display,
    upperBollinger,
    upperBollingerDisplay,
    white, sma200, yellow
} from "../constants/Constants";

const YearChart = React.memo((props) => {
    const prices = props.yearStockPoints.map(point =>
        isNaN(point.price) ? null : point.price
    );
    const upperBollingers = props.yearStockPoints.map(point =>
        isNaN(point.upperBollinger) ? null : point.upperBollinger
    );
    const lowerBollingers = props.yearStockPoints.map(point =>
        isNaN(point.lowerBollinger) ? null : point.lowerBollinger
    );
    const sma50s = props.yearStockPoints.map(point =>
        isNaN(point.sma50) ? null : point.sma50
    );
    const sma200s = props.yearStockPoints.map(point =>
        isNaN(point.sma200) ? null : point.sma200
    );
    const ema12s = props.yearStockPoints.map(point =>
        isNaN(point.ema12) ? null : point.ema12
    );
    const ema26s = props.yearStockPoints.map(point =>
        isNaN(point.ema26) ? null : point.ema26
    );
    const timeStamps = props.yearStockPoints.map(point => point.timeStamp);
    const previousClose = props.yearStockPoints[0].price;

    const pricesObject = {
        id: 0,
        name: priceDisplay,
        data: prices,
        color: props.color,
    };

    const upperBollingersObject = {
        id: 1,
        name: upperBollingerDisplay,
        data: upperBollingers,
        color: purple,
    };

    const lowerBollingersObject = {
        id: 2,
        name: lowerBollingerDisplay,
        data: lowerBollingers,
        color: purple
    };

    const sma50sObject = {
        id: 3,
        name: sma50Display,
        data: sma50s,
        color: blue,
    };

    const sma200sObject = {
        id: 4,
        name: sma200Display,
        data: sma200s,
        color: white,
    };

    const ema12sObject = {
        id: 5,
        name: ema12Display,
        data: ema12s,
        color: yellow,
    };

    const ema26sObject = {
        id: 6,
        name: ema26Display,
        data: ema26s,
        color: orange,
    };

    let [seriesList, setSeriesList] = useState([pricesObject]);

    const series = useMemo(() => seriesList, [seriesList]);

    const options = useMemo(() => {
        const allData = [...prices, ...upperBollingers, ...lowerBollingers, ...sma50s, ...sma200s, ...ema12s, ...ema26s];
        const minValue = Math.min(...allData.filter(value => value !== null));
        const maxValue = Math.max(...allData.filter(value => value !== null));

        return {
            chart: {
                type: 'line',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
                animations: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'straight',
                width: 2,
            },
            grid: {
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            xaxis: {
                categories: timeStamps,
                tickAmount: Math.floor(timeStamps.length / 20),
                labels: {
                    style: {
                        colors: white,
                    },
                    formatter: function (value) {
                        const date = moment.tz(parseInt(value), 'America/New_York');
                        return date.format('M/D/YY');
                    },
                },
                axisBorder: {
                    show: true,
                    color: white,
                    height: 1,
                    width: '100%',
                    offsetX: -1,
                    offsetY: 2,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                // min: Math.min(minValue, previousClose) - (maxValue - minValue) * 0.1,
                // max: Math.max(maxValue, previousClose) + (maxValue - minValue) * 0.1,
                labels: {
                    style: {
                        colors: white,
                    },
                    formatter: function (value) {
                        if (value !== null && value !== undefined) {
                            return '$' + value.toFixed(2);
                        }
                    },
                },
                axisBorder: {
                    show: true,
                    color: white,
                    width: 1,
                    offsetX: -2,
                    offsetY: 0,
                },
            },
            tooltip: {
                theme: 'dark',
                marker: {
                    show: true,
                },
                x: {
                    show: false,
                    formatter: function (value) {
                        const date = moment.tz(parseInt(timeStamps[value - 1]), 'America/New_York');
                        return date.format('M/D/YY');
                    },
                },
                y: {
                    formatter: function (value) {
                        if (value !== null && value !== undefined) {
                            return '$' + value.toFixed(2);
                        }
                    },
                },
                fixed: {
                    enabled: false,
                    position: 'topRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            annotations: {
                yaxis: [
                    {
                        y: previousClose,
                        label: {
                            style: {
                                color: white,
                            },
                        },
                    },
                ],
            },
            legend: {
                show: false,
                labels: {
                    colors: white,
                    style: {
                        marginTop: '10px',
                    },
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 15,
                },
            },
        };
    }, [timeStamps, previousClose, prices, upperBollingers, lowerBollingers, sma50s, sma200s, ema12s, ema26s]);

    const [pricesBorder, setPricesBorder] = useState(props.borders.prices);
    const [bbuBorder, setBbuBorder] = useState(props.noBorder);
    const [bblBorder, setBblBorder] = useState(props.noBorder);
    const [sma50Border, setSma50Border] = useState(props.noBorder);
    const [sma200Border, setSma200Border] = useState(props.noBorder);
    const [ema12Border, setEma12Border] = useState(props.noBorder);
    const [ema26Border, setEma26Border] = useState(props.noBorder);

    function handleSeriesChange(series) {
        if (series === price) {
            if (seriesList.some(obj => obj.id === pricesObject.id)) {
                if (seriesList.length > 1) {
                    setPricesBorder(props.noBorder);
                    setSeriesList(seriesList.filter(obj => obj.id !== pricesObject.id));
                } else {
                    setPricesBorder(props.borders.prices);
                    setSeriesList([pricesObject]);
                }
            } else {
                setPricesBorder(props.borders.prices);
                setSeriesList([...seriesList, pricesObject]);
            }
        } else if (series === upperBollinger) {
            if (seriesList.some(obj => obj.id === upperBollingersObject.id)) {
                if (seriesList.length > 1) {
                    setSeriesList(seriesList.filter(obj => obj.id !== upperBollingersObject.id));
                } else {
                    setPricesBorder(props.borders.prices);
                    setSeriesList([pricesObject]);
                }
                setBbuBorder(props.noBorder);
            } else {
                setBbuBorder(props.borders.upperBollingers);
                setSeriesList([...seriesList, upperBollingersObject]);
            }
        } else if (series === lowerBollinger) {
            if (seriesList.some(obj => obj.id === lowerBollingersObject.id)) {
                if (seriesList.length > 1) {
                    setSeriesList(seriesList.filter(obj => obj.id !== lowerBollingersObject.id));
                } else {
                    setPricesBorder(props.borders.prices);
                    setSeriesList([pricesObject]);
                }
                setBblBorder(props.noBorder);
            } else {
                setBblBorder(props.borders.lowerBollingers);
                setSeriesList([...seriesList, lowerBollingersObject]);
            }
        } else if (series === sma50) {
            if (seriesList.some(obj => obj.id === sma50sObject.id)) {
                if (seriesList.length > 1) {
                    setSeriesList(seriesList.filter(obj => obj.id !== sma50sObject.id));
                } else {
                    setPricesBorder(props.borders.prices);
                    setSeriesList([pricesObject]);
                }
                setSma50Border(props.noBorder);
            } else {
                setSma50Border(props.borders.sma50s);
                setSeriesList([...seriesList, sma50sObject]);
            }
        } else if (series === sma200) {
            if (seriesList.some(obj => obj.id === sma200sObject.id)) {
                if (seriesList.length > 1) {
                    setSeriesList(seriesList.filter(obj => obj.id !== sma200sObject.id));
                } else {
                    setPricesBorder(props.borders.prices);
                    setSeriesList([pricesObject]);
                }
                setSma200Border(props.noBorder);
            } else {
                setSma200Border(props.borders.sma200s);
                setSeriesList([...seriesList, sma200sObject]);
            }
        } else if (series === ema12) {
            if (seriesList.some(obj => obj.id === ema12sObject.id)) {
                if (seriesList.length > 1) {
                    setSeriesList(seriesList.filter(obj => obj.id !== ema12sObject.id));
                } else {
                    setPricesBorder(props.borders.prices);
                    setSeriesList([pricesObject]);
                }
                setEma12Border(props.noBorder);
            } else {
                setEma12Border(props.borders.ema12s);
                setSeriesList([...seriesList, ema12sObject]);
            }
        } else if (series === ema26) {
            if (seriesList.some(obj => obj.id === ema26sObject.id)) {
                if (seriesList.length > 1) {
                    setSeriesList(seriesList.filter(obj => obj.id !== ema26sObject.id));
                } else {
                    setPricesBorder(props.borders.prices);
                    setSeriesList([pricesObject]);
                }
                setEma26Border(props.noBorder);
            } else {
                setEma26Border(props.borders.ema26s);
                setSeriesList([...seriesList, ema26sObject]);
            }
        }

        setSeriesList(seriesList => seriesList.sort((a, b) => a.id - b.id));
    }

    return (
        <div>
            <div className={"chart-container"}>
                <ReactApexChart
                    options={options}
                    series={series}
                    type="line"
                    height={'100%'}
                    width={'100%'}/>
            </div>
            <div className={'chart-indicator-buttons'}>
                <button className={'chart-indicator-button'}
                        onClick={() => handleSeriesChange(price)}
                        style={{border: pricesBorder + props.color, color: props.color}}>{priceDisplay}</button>
                <button className={'chart-indicator-button'}
                        onClick={() => handleSeriesChange(upperBollinger)}
                        style={{border: bbuBorder, color: purple}}>{upperBollingerDisplay}</button>
                <button className={'chart-indicator-button'}
                        onClick={() => handleSeriesChange(lowerBollinger)}
                        style={{border: bblBorder, color: purple}}>{lowerBollingerDisplay}</button>
                <button className={'chart-indicator-button'}
                        onClick={() => handleSeriesChange(sma50)}
                        style={{border: sma50Border, color: blue}}>{sma50Display}</button>
                <button className={'chart-indicator-button'}
                        onClick={() => handleSeriesChange(sma200)}
                        style={{border: sma200Border, color: white}}>{sma200Display}</button>
                <button className={'chart-indicator-button'}
                        onClick={() => handleSeriesChange(ema12)}
                        style={{border: ema12Border, color: yellow}}>{ema12Display}</button>
                <button className={'chart-indicator-button'}
                        onClick={() => handleSeriesChange(ema26)}
                        style={{border: ema26Border, color: orange}}>{ema26Display}</button>
            </div>
        </div>
    );
});

export default YearChart;
