import {refreshToken} from "./AuthService";
import {properties} from "../properties";
import {rfAccessToken, rfRefreshToken} from "../constants/Constants";

export function deleteAccountCall(firstCall) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        deleteAccountCall(false).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to delete account."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/users/delete-account", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);
        xhttp.send();
    });
}