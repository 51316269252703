export const rfAccessToken = "rfAccessToken";
export const rfRefreshToken = "rfRefreshToken";
export const blank = '';
export const transparent = 'transparent';
export const green = '#00FF00';
export const red = '#FF0000';
export const grey = '#C0C0C0';
export const purple = '#b619ff';
export const blue = '#1e90ff';
export const orange = '#ff7f00';
export const yellow = '#FFFF00'
export const white = '#fff';
export const eq = '=';
export const ne = '!=';
export const lt = '<';
export const lte = '<='
export const gt = '>';
export const gte = '>=';
export const price = 'price';
export const change = 'change';
export const changePercentage = 'changePercentage';
export const ask = 'ask';
export const bid = 'bid';
export const askSize = 'askSize';
export const bidSize = 'bidSize';
export const open = 'open';
export const previousClose = 'previousClose';
export const dayLow = 'dayLow';
export const dayHigh = 'dayHigh';
export const yearLow = 'yearLow';
export const yearHigh = 'yearHigh';
export const volume = 'volume';
export const averageVolume = 'averageVolume';
export const sma50 = 'sma50';
export const sma200 = 'sma200';
export const ema12 = 'ema12';
export const ema26 = 'ema26';
export const rsi = 'rsi';
export const aroon = 'aroon';
export const stochastic = 'stochastic';
export const upperBollinger = 'upperBollinger';
export const lowerBollinger = 'lowerBollinger';
export const macd = 'macd';
export const macdSignal = 'macdSignal';
export const spread = 'spread';
export const spreadPercentage = 'spreadPercentage';
export const priceDisplay = 'Price';
export const changeDisplay = 'Change';
export const changePercentageDisplay = 'Change %';
export const askDisplay = 'Ask';
export const bidDisplay = 'Bid';
export const askSizeDisplay = 'Ask Size';
export const bidSizeDisplay = 'Bid Size';
export const openDisplay = 'Open';
export const previousCloseDisplay = 'Prev. Close';
export const dayLowDisplay = 'Day Low';
export const dayHighDisplay = 'Day High';
export const yearLowDisplay = 'Year Low';
export const yearHighDisplay = 'Year High';
export const volumeDisplay = 'Volume';
export const averageVolumeDisplay = 'Avg. Volume(50)';
export const sma50Display = 'SMA(50)';
export const sma200Display = 'SMA(200)';
export const ema12Display = 'EMA(12)';
export const ema26Display = 'EMA(26)';
export const rsiDisplay = 'RSI(14)';
export const aroonDisplay = 'Aroon(25)';
export const stochasticDisplay = 'Stoch(14)';
export const upperBollingerDisplay = 'BB(20,2) U';
export const lowerBollingerDisplay = 'BB(20,2) L';
export const macdDisplay = 'MACD(12,26)';
export const macdSignalDisplay = 'MACD Sig(9)';
export const spreadDisplay = 'Spread';
export const spreadPercentageDisplay = 'Spread %';
