import {properties} from "../properties";

export function forgotPassword(email) {
    return new Promise((resolve, reject) => {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else {
                    reject(new Error("Failed to reset password"));
                }
            }
        };
        xhttp.open("GET", properties.host + "/auth/forgot-password?email=" + email, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

        xhttp.send();
    });
}

export function resetPassword(email, newPassword, confirmationPassword, confirmationToken) {
    return new Promise((resolve, reject) => {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else {
                    reject(new Error("Failed to reset password"));
                }
            }
        };
        xhttp.open("POST", properties.host + "/auth/reset-password", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        const resetPasswordRequest = {
            email: email,
            newPassword: newPassword,
            confirmationPassword: confirmationPassword,
            confirmationToken: confirmationToken
        };
        xhttp.send(JSON.stringify(resetPasswordRequest));
    });
}