import {blue, green, red} from "../constants/Constants";


function Politician(props) {

    const congressTrades = props.congressInfo.trades;

    function formatNumberShort(num) {
        if (num >= 1000 && num < 1000000) {
            return (num / 1000).toFixed(0) + 'k';
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else {
            return num;
        }
    }

    return (
        <div className={"admin-dashboard-content"}
             style={{overflow: 'visible', maxHeight: "max-content"}}
             onClick={() => props.setSuggestions([])}>
            {props.errorSuccessMessage && (
                <div className="success-or-error"
                     style={{color: props.errorSuccessMessage.props.style.color, opacity: props.errorSuccessOpacity}}>
                    {props.errorSuccessMessage}
                </div>
            )}
            {/*<div className={"section-subheading admin-subheading center"}>{props.name}</div>*/}
            {/*{props.searchForm}heading admin-subheading center"}>{props.name}</div>*/}
            {/*{props.searchForm}*/}
            <div className={"reddit-comments fade-in"}>

                {congressTrades === null || congressTrades.length < 1 ?
                    !props.congressInfo.name ? null :
                        <div className={"nothing-here"}>Nothing here..</div>
                    : (
                        <div style={{marginTop: '1rem'}}>
                            <table className={"dashboard-table fade-in"}>
                                <thead>
                                <tr className={"dashboard-tr dashboard-th congress-td"}>
                                    <th className={"th-width-15-percent"}>Symbol</th>
                                    <th className={"th-width-12-percent"}>Action</th>
                                    <th className={"th-width-20-percent"}>Amount</th>
                                    <th className={"th-width-15-percent"}>Traded</th>
                                    <th className={"th-width-15-percent"}>Filed</th>
                                    <th className={"th-width-1-percent"}>Security</th>
                                </tr>
                                </thead>
                            </table>
                            <div className={"max-height-600-400"} style={{overflow: 'scroll'}}>
                                <table className={"dashboard-table fade-in"}>
                                    {/*<div></div>*/}
                                    <thead>
                                    <tr className={"dashboard-tr dashboard-th congress-td"}
                                        style={{borderBottom: 'none', color: 'transparent', lineHeight: '0'}}>
                                        <th className={"th-width-15-percent"}>Symbol</th>
                                        <th className={"th-width-12-percent"}>Action</th>
                                        <th className={"th-width-20-percent"}>Amount</th>
                                        <th className={"th-width-15-percent"}>Trade Date</th>
                                        <th className={"th-width-15-percent"}>File Date</th>
                                        <th className={"th-width-1-percent"}>Security</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {congressTrades.map((trade, index) => (
                                        <tr key={index} className={"dashboard-tr"}>
                                            <td className={"dashboard-td congress-td"}>

                                                <div className={"dashboard-td-inner"}><a
                                                    href={"/analyze/" + trade.stock + "?content=congress"}
                                                    className={"dashboard-link"}
                                                >{trade.stock}</a>
                                                </div>
                                            </td>
                                            <td className={"dashboard-td congress-td"}
                                                style={{color: trade.transactionType === "Buy" ? green : trade.transactionType === "Sell" ? red : ''}}>
                                                {trade.transactionType}
                                            </td>
                                            <td className={"dashboard-td congress-td congress-amount"}>
                                                {trade.transactionCeiling > 0 &&
                                                    // `$${new Intl.NumberFormat('en-US').format(trade.transactionFloor)} - $${new Intl.NumberFormat('en-US').format(trade.transactionCeiling)}`
                                                    `$${formatNumberShort(trade.transactionFloor)} - $${formatNumberShort(trade.transactionCeiling)}`
                                                }
                                            </td>
                                            <td className={"dashboard-td congress-td"}>
                                                {trade.tradedDate}
                                            </td>
                                            <td className={"dashboard-td congress-td"}>
                                                {trade.filedDate}
                                            </td>
                                            <td className={"dashboard-td congress-td"}>
                                                {trade.securityType}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    )}

            </div>
        </div>
    )
}

export default Politician;