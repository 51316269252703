import Header from "./Header";
import Loading from "./Loading";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {green, grey, red, rfAccessToken} from "../constants/Constants";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getJwtRole} from "../service/AuthService";
import {fetchAdminResponse} from "../service/AdminService";

const border = 'solid 2px #b619ff';
const none = 'none';

function Admin() {
    const navigate = useNavigate();
    const [contentError, setContentError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [locations, setLocations] = useState([]);


    useEffect(() => {
        if (!localStorage.getItem(rfAccessToken)) {
            navigate("/login");
        } else if (getJwtRole() !== "ROLE_ADMIN") {
            navigate("/");
        }
        try {
            setLoading(true);
            fetchAdminResponse(true)
                .then(response => {
                    setUsers(response.adminUsers);
                    setLocations(response.locations);

                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error loading admin content:", error);
                    setContentError(true);
                    setLoading(false);
                    if (error.status === 403) {
                        navigate("/login");
                    }
                });
        } catch (error) {
            console.error("Error loading admin content:", error);
            setContentError(true);
            setLoading(false);
            if (error.status === 403) {
                navigate("/login");
            }
        }
    }, []);

    const locationsContent = (

        <div className={"admin-dashboard-content fade-in"} style={{overflow: 'visible', maxWidth: '1000px'}}
        >
            <div className={"section-subheading admin-subheading center"}>Locations</div>
            {!users?.length ?
                <div className={"center"}>No Locations
                </div>
                :
                <table className={"dashboard-table"}>
                    <thead>
                    <tr className={"dashboard-tr dashboard-th"}>
                        <th className={""}>City</th>
                        <th className={""}>State</th>
                        <th className={""}>Country</th>
                        <th className={""}>Visits</th>
                    </tr>
                    </thead>
                    <tbody>
                    {locations.map((location, index) => (
                        <tr key={index} className={"dashboard-tr"}>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner admin-user-td-text"}>{location.city}
                                </div>
                            </td>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner admin-user-td-text"}>{location.state}
                                </div>
                            </td>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner admin-user-td-text"}>{location.country}
                                </div>
                            </td>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner admin-user-td-text"}>{location.visits}
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            }
        </div>

    )

    const usersContent = (

        <div className={"admin-dashboard-content fade-in"} style={{overflow: 'visible', maxWidth: '1000px'}}
        >
            <div className={"section-subheading admin-subheading center"}>Users</div>
            {!users?.length ?
                <div className={"center"}>No Users
                </div>
                :
                <table className={"dashboard-table"}>
                    <thead>
                    <tr className={"dashboard-tr dashboard-th"}>
                        <th className={""}>First</th>
                        <th className={""}>Last</th>
                        <th className={"th-width-50-percent"}>Email</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user, index) => (
                        <tr key={index} className={"dashboard-tr"}>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner admin-user-td-text"}><a
                                    href={"/admin/user/" + user.id}
                                    className={"dashboard-link"}
                                >{user.firstName}</a>
                                </div>
                            </td>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner admin-user-td-text"}><a
                                    href={"/admin/user/" + user.id}
                                    className={"dashboard-link"}
                                >{user.lastName}</a>
                                </div>
                            </td>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner admin-user-td-text"}><a
                                    href={"/admin/user/" + user.id}
                                    className={"dashboard-link"}
                                >{user.email}</a>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            }
        </div>

    )

    const [searchParams, setSearchParams] = useSearchParams();
    const content = searchParams.get('content');

    const [usersBorder, setUsersBorder] = useState(content !== 'locations'
        ? border : none);
    const [locationsBorder, setLocationsBorder] = useState(content === 'locations' ? border : none);
    const [contentEntryText, setContentEntryText] = useState(content);

    function handleContentEntryClick(entry) {
        if (entry === 'users') {
            setSearchParams({content: 'users'})
            setUsersBorder(border);
            setLocationsBorder(none);
            setContentEntryText('users');
        } else {
            setSearchParams({content: 'locations'})
            setLocationsBorder(border);
            setUsersBorder(none);
            setContentEntryText('locations');
        }

    }

    const contentBody = (
        <>
            <div className={"reddit-dashboard-entries"} style={{marginBottom: '.5rem'}}>
                <div className={"analyze-dashboard-entry dashboard-link"}
                     onClick={() => handleContentEntryClick('users')}
                     style={{border: usersBorder}}>Users
                </div>
                <div className={"analyze-dashboard-entry dashboard-link"}
                     onClick={() => handleContentEntryClick('locations')}
                     style={{border: locationsBorder}}>Locations
                </div>
            </div>
            {
                contentError ?
                    <div className={"error-loading"}>Error loading analyze content</div>
                    :
                    contentEntryText === 'locations' ?
                        locationsContent
                        :
                        usersContent
            }
        </>
    );

    return (
        <div className={"login page"}>
            <Header/>
            <div className={"small-heading"}>ADMIN</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"} style={{minHeight: '222px'}}>
                    {loading ?
                        <Loading/>
                        :
                        contentError ?
                            <div className={"error-loading"}>Error loading admin content</div>
                            :
                            contentBody
                    }
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Admin;