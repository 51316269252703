import {
    eq, ne, lt, lte, gt, gte,
    price, change, changePercentage, ask, bid, askSize, bidSize, open, previousClose,
    dayLow, dayHigh, yearLow, yearHigh, volume, averageVolume, sma50, sma200, ema12, ema26, rsi,
    aroon, stochastic, upperBollinger, lowerBollinger, macd, macdSignal, spread, spreadPercentage,
    priceDisplay, changeDisplay, changePercentageDisplay, askDisplay, bidDisplay, askSizeDisplay,
    bidSizeDisplay, openDisplay, previousCloseDisplay, dayLowDisplay, dayHighDisplay, yearLowDisplay,
    yearHighDisplay, volumeDisplay, averageVolumeDisplay, sma50Display, sma200Display, ema12Display,
    ema26Display, rsiDisplay, aroonDisplay, stochasticDisplay, upperBollingerDisplay, lowerBollingerDisplay,
    macdDisplay, macdSignalDisplay, spreadDisplay, spreadPercentageDisplay, blank, red, green
} from "../constants/Constants";
import {useEffect, useRef, useState} from "react";
import {deleteNotificationCall, saveNotificationCall} from "../service/NotifyService";
import Loading from "./Loading";
import {useNavigate} from "react-router-dom";

const initialNotification = {
    "active": true,
    "name": "Notification Name",
    "notificationConditions": [
        {
            "id": null,
            "conditionLeft": price,
            "symbolLeft": blank,
            "comparator": eq,
            "conditionRight": price,
            "symbolRight": blank,
            "number": false
        }
    ]
}

const initialNotificationsList = [initialNotification];

function Notification(props) {
    const navigate = useNavigate();
    const [originalNotificationsList, setOriginalNotificationsList] = useState(props.homeInfo.notifications);
    const [clickedIndex, setClickedIndex] = useState(null);
    let contentRefs = useRef([]);
    const [notificationsList, setNotificationsList] = useState(props.homeInfo.notifications.length ? props.homeInfo.notifications : initialNotificationsList);
    const [suggestions, setSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [currentInput, setCurrentInput] = useState({notificationIndex: null, conditionIndex: null, key: ''});
    const suggestionRefs = useRef([]);
    const [savingNotification, setSavingNotification] = useState(false);
    const [deletingNotification, setDeletingNotification] = useState(false);

    const handleArrowClick = (index) => {
        setClickedIndex(clickedIndex === index ? null : index);
    };

    useEffect(() => {
        contentRefs.current = contentRefs.current.slice(0, notificationsList.length);
    }, [notificationsList]);

    useEffect(() => {
        if (highlightedIndex >= 0 && suggestionRefs.current[highlightedIndex]) {
            suggestionRefs.current[highlightedIndex].scrollIntoView({
                block: 'nearest',
            });
        }
    }, [highlightedIndex]);

    const [errorSuccessMessage, setErrorSuccessMessage] = useState(null);
    const [errorSuccessOpacity, setErrorSuccessOpacity] = useState(1);

    function addErrorSuccess(message, color) {
        if (window.innerWidth >= 991 || color === green) {
            setErrorSuccessMessage(
                <div className="success-or-error-notifications" style={{color: color, opacity: errorSuccessOpacity}}>
                    {message}
                </div>
            );
            setErrorSuccessOpacity(1);

            setTimeout(() => {
                setErrorSuccessOpacity(0);
            }, 2000);

            setTimeout(() => {
                setErrorSuccessOpacity(1);
                setErrorSuccessMessage(null);
            }, 3000);
        } else {
            window.alert(message);
        }
    }

    function checkIfNumber(input) {
        const num = Number(input);
        return !isNaN(num);
    }

    const toggleNumber = (notificationIndex, conditionIndex) => {
        const updatedList = notificationsList.map((notification, nIndex) => {
            if (nIndex === notificationIndex) {
                const updatedConditions = notification.notificationConditions.map((condition, cIndex) => {
                    if (cIndex === conditionIndex) {
                        return {
                            ...condition,
                            number: !condition.number,
                            conditionRight: condition.number && checkIfNumber(condition.conditionRight) ? price : condition.conditionRight
                        };
                    }
                    return condition;
                });
                return {...notification, notificationConditions: updatedConditions};
            }
            return notification;
        });
        setNotificationsList(updatedList);
    };

    const addCondition = (notificationIndex) => {
        if (notificationsList[notificationIndex].notificationConditions.length >= 10) {
            addErrorSuccess("Notifications can only have 10 conditions.", red);
            return;
        }
        const newCondition = {
            id: null,
            conditionLeft: price,
            symbolLeft: blank,
            comparator: eq,
            conditionRight: price,
            symbolRight: blank,
            number: false
        };
        const updatedList = notificationsList.map((notification, nIndex) => {
            if (nIndex === notificationIndex) {
                return {
                    ...notification,
                    notificationConditions: [...notification.notificationConditions, newCondition]
                };
            }
            return notification;
        });
        setNotificationsList(updatedList);
        setTimeout(() => {
            const currentContent = contentRefs.current[notificationIndex];
            if (currentContent) {
                contentRefs.current[notificationIndex].style.maxHeight = `${currentContent.scrollHeight}px`;
            }
        }, 0);
    };

    const removeCondition = (notificationIndex) => {
        if (notificationsList[notificationIndex].notificationConditions.length <= 1) {
            addErrorSuccess("Notifications must have at least one condition.", red);
            return;
        }
        const updatedList = notificationsList.map((notification, nIndex) => {
            if (nIndex === notificationIndex) {
                return {
                    ...notification,
                    notificationConditions: notification.notificationConditions.slice(0, -1)
                };
            }
            return notification;
        });
        setNotificationsList(updatedList);
        setTimeout(() => {
            const currentContent = contentRefs.current[notificationIndex];
            if (currentContent) {
                contentRefs.current[notificationIndex].style.maxHeight = `${currentContent.scrollHeight}px`;
            }
        }, 0);
    };

    const blockInvalidChar = (e) => ['e', 'E', '+', checkNeg(e.target.value)].includes(e.key) && e.preventDefault();

    function checkNeg(val) {
        return val.length > 0 ? '-' : '';
    }

    const handleInputChange = (notificationIndex, conditionIndex, field, value) => {
        const updatedList = notificationsList.map((notification, nIndex) => {
            if (nIndex === notificationIndex) {
                const updatedConditions = notification.notificationConditions.map((condition, cIndex) => {
                    if (cIndex === conditionIndex) {
                        return {...condition, [field]: value};
                    }
                    return condition;
                });
                return {...notification, notificationConditions: updatedConditions};
            }
            return notification;
        });
        setNotificationsList(updatedList);
    };

    const handleNameChange = (notificationIndex, value) => {
        const updatedList = notificationsList.map((notification, nIndex) => {
            if (nIndex === notificationIndex) {
                return {...notification, name: value};
            }
            return notification;
        });
        setNotificationsList(updatedList);
    };

    const handleActiveToggleChange = (index) => {
        const updatedList = notificationsList.map((notification, nIndex) => {
            if (nIndex === index) {
                return {...notification, active: !notification.active};
            }
            return notification;
        });
        setNotificationsList(updatedList);
    };

    const handleSuggestionClick = (symbol) => {
        const {notificationIndex, conditionIndex, key} = currentInput;
        handleInputChange(notificationIndex, conditionIndex, key, symbol);
        setSuggestions([]);
        setCurrentInput({notificationIndex: null, conditionIndex: null, key: ''});
    };

    const handleSymbolInputChange = (notificationIndex, conditionIndex, field, e) => {
        const value = e.target.value.toUpperCase();
        handleInputChange(notificationIndex, conditionIndex, field, value);
        setCurrentInput({notificationIndex, conditionIndex, key: field});
        if (value.length > 0) {
            const filteredSuggestions = props.homeInfo.symbols.filter(symbol => symbol.startsWith(value));
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
        setHighlightedIndex(-1);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex(prevIndex => Math.min(prevIndex + 1, suggestions.length - 1));
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (e.key === 'Enter' && highlightedIndex >= 0) {
            e.preventDefault();
            handleSuggestionClick(suggestions[highlightedIndex]);
        }
    };

    function rightConditions(notification, notificationIndex, conditionIndex) {
        if (!notification.notificationConditions[conditionIndex].number) {
            return (
                <div>
                    <select id={"conditionRightNotify" + conditionIndex} className={"filter-dropdown-notification"}
                            defaultValue={notification.notificationConditions[conditionIndex].conditionRight}
                            onChange={(e) => handleInputChange(notificationIndex, conditionIndex, 'conditionRight', e.target.value)}
                            disabled={false}
                    >
                        <option className={"filter-option"} value={price}>{priceDisplay}</option>
                        <option className={"filter-option"} value={change}>{changeDisplay}</option>
                        <option className={"filter-option"} value={changePercentage}>{changePercentageDisplay}</option>
                        <option className={"filter-option"} value={ask}>{askDisplay}</option>
                        <option className={"filter-option"} value={bid}>{bidDisplay}</option>
                        <option className={"filter-option"} value={spread}>{spreadDisplay}</option>
                        <option className={"filter-option"} value={spreadPercentage}>{spreadPercentageDisplay}</option>
                        <option className={"filter-option"} value={askSize}>{askSizeDisplay}</option>
                        <option className={"filter-option"} value={bidSize}>{bidSizeDisplay}</option>
                        <option className={"filter-option"} value={open}>{openDisplay}</option>
                        <option className={"filter-option"} value={previousClose}>{previousCloseDisplay}</option>
                        <option className={"filter-option"} value={dayLow}>{dayLowDisplay}</option>
                        <option className={"filter-option"} value={dayHigh}>{dayHighDisplay}</option>
                        <option className={"filter-option"} value={yearLow}>{yearLowDisplay}</option>
                        <option className={"filter-option"} value={yearHigh}>{yearHighDisplay}</option>
                        <option className={"filter-option"} value={volume}>{volumeDisplay}</option>
                        <option className={"filter-option"} value={averageVolume}>{averageVolumeDisplay}</option>
                        <option className={"filter-option"} value={sma50}>{sma50Display}</option>
                        <option className={"filter-option"} value={sma200}>{sma200Display}</option>
                        <option className={"filter-option"} value={ema12}>{ema12Display}</option>
                        <option className={"filter-option"} value={ema26}>{ema26Display}</option>
                        <option className={"filter-option"} value={upperBollinger}>{upperBollingerDisplay}</option>
                        <option className={"filter-option"} value={lowerBollinger}>{lowerBollingerDisplay}</option>
                        <option className={"filter-option"} value={rsi}>{rsiDisplay}</option>
                        <option className={"filter-option"} value={aroon}>{aroonDisplay}</option>
                        <option className={"filter-option"} value={stochastic}>{stochasticDisplay}</option>
                        <option className={"filter-option"} value={macd}>{macdDisplay}</option>
                        <option className={"filter-option"} value={macdSignal}>{macdSignalDisplay}</option>
                    </select>
                    <div className={"notify-of-symbol-wrapper"}>
                        <span className={"notify-of"}>of</span>
                        <input id={"symbolRight" + conditionIndex}
                               maxLength={5}
                               className={"ticker-input-notification"}
                               placeholder={'Symbol'}
                               required
                               value={notification.notificationConditions[conditionIndex].symbolRight}
                               onChange={(e) => handleSymbolInputChange(notificationIndex, conditionIndex, 'symbolRight', e)}
                               onKeyDown={handleKeyDown}
                               disabled={false}
                               autoComplete={"off"}
                        />
                        {currentInput.notificationIndex === notificationIndex && currentInput.conditionIndex === conditionIndex && currentInput.key === 'symbolRight' && suggestions.length > 0 && (
                            <div className={"suggestions-wrapper"}>
                                <ul className="suggestions suggestions-notification">
                                    {suggestions.sort((a, b) => a.localeCompare(b)).map((suggestion, index) => (
                                        <li key={index}
                                            ref={el => suggestionRefs.current[index] = el}
                                            className={`suggestion dashboard-link ${index === highlightedIndex ? 'highlighted' : ''}`}
                                            onClick={() => handleSuggestionClick(suggestion)}>
                                            {suggestion}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            )
        } else {
            return (
                <input
                    type={"number"}
                    pattern={'0-9.-'}
                    step={"any"}
                    id={"conditionRightNotify" + conditionIndex}
                    className={"filter-number"}
                    placeholder={'Number'}
                    onInput={() => {
                        const inputElement = document.getElementById("conditionRightNotify" + conditionIndex);
                        if (inputElement.value.length > 12) {
                            inputElement.value = inputElement.value.slice(0, 12);
                        }
                        // Force reflow fix
                        inputElement.style.display = 'none';
                        // eslint-disable-next-line no-unused-expressions
                        inputElement.offsetHeight; // Force reflow
                        inputElement.style.display = '';
                    }}
                    onKeyDown={blockInvalidChar}
                    required
                    value={notification.notificationConditions[conditionIndex].conditionRight}
                    onChange={(e) => handleInputChange(notificationIndex, conditionIndex, 'conditionRight', e.target.value)}
                    disabled={false}
                    autoComplete={"off"}
                />
            )
        }
    }

    function conditionTable(notification, notificationIndex, conditionIndex) {
        return (
            <table className={"notification-table"} key={conditionIndex}>
                <tbody>
                <tr>
                    <td>
                        <select id={"conditionLeftNotify" + conditionIndex} className={"filter-dropdown-notification"}
                                defaultValue={notification.notificationConditions[conditionIndex].conditionLeft}
                                onChange={(e) => handleInputChange(notificationIndex, conditionIndex, 'conditionLeft', e.target.value)}
                                disabled={false}>
                            <option className={"filter-option"} value={price}>{priceDisplay}</option>
                            <option className={"filter-option"} value={change}>{changeDisplay}</option>
                            <option className={"filter-option"}
                                    value={changePercentage}>{changePercentageDisplay}</option>
                            <option className={"filter-option"} value={ask}>{askDisplay}</option>
                            <option className={"filter-option"} value={bid}>{bidDisplay}</option>
                            <option className={"filter-option"} value={spread}>{spreadDisplay}</option>
                            <option className={"filter-option"}
                                    value={spreadPercentage}>{spreadPercentageDisplay}</option>
                            <option className={"filter-option"} value={askSize}>{askSizeDisplay}</option>
                            <option className={"filter-option"} value={bidSize}>{bidSizeDisplay}</option>
                            <option className={"filter-option"} value={open}>{openDisplay}</option>
                            <option className={"filter-option"} value={previousClose}>{previousCloseDisplay}</option>
                            <option className={"filter-option"} value={dayLow}>{dayLowDisplay}</option>
                            <option className={"filter-option"} value={dayHigh}>{dayHighDisplay}</option>
                            <option className={"filter-option"} value={yearLow}>{yearLowDisplay}</option>
                            <option className={"filter-option"} value={yearHigh}>{yearHighDisplay}</option>
                            <option className={"filter-option"} value={volume}>{volumeDisplay}</option>
                            <option className={"filter-option"} value={averageVolume}>{averageVolumeDisplay}</option>
                            <option className={"filter-option"} value={sma50}>{sma50Display}</option>
                            <option className={"filter-option"} value={sma200}>{sma200Display}</option>
                            <option className={"filter-option"} value={ema12}>{ema12Display}</option>
                            <option className={"filter-option"} value={ema26}>{ema26Display}</option>
                            <option className={"filter-option"} value={upperBollinger}>{upperBollingerDisplay}</option>
                            <option className={"filter-option"} value={lowerBollinger}>{lowerBollingerDisplay}</option>
                            <option className={"filter-option"} value={rsi}>{rsiDisplay}</option>
                            <option className={"filter-option"} value={aroon}>{aroonDisplay}</option>
                            <option className={"filter-option"} value={stochastic}>{stochasticDisplay}</option>
                            <option className={"filter-option"} value={macd}>{macdDisplay}</option>
                            <option className={"filter-option"} value={macdSignal}>{macdSignalDisplay}</option>
                        </select>
                        <div className={"notify-of-symbol-wrapper"}>
                            <span className={"notify-of"}>of</span>
                            <input id={"symbolLeft" + conditionIndex}
                                   maxLength={5}
                                   className={"ticker-input-notification"}
                                   placeholder={'Symbol'}
                                   required
                                   value={notification.notificationConditions[conditionIndex].symbolLeft}
                                   onChange={(e) => handleSymbolInputChange(notificationIndex, conditionIndex, 'symbolLeft', e)}
                                   onKeyDown={handleKeyDown}
                                   disabled={false}
                                   autoComplete={"off"}
                            />
                            {currentInput.notificationIndex === notificationIndex && currentInput.conditionIndex === conditionIndex && currentInput.key === 'symbolLeft' && suggestions.length > 0 && (
                                <div className={"suggestions-wrapper"}>
                                    <ul className="suggestions suggestions-notification">
                                        {suggestions.sort((a, b) => a.localeCompare(b)).map((suggestion, index) => (
                                            <li key={index}
                                                ref={el => suggestionRefs.current[index] = el}
                                                className={`suggestion dashboard-link ${index === highlightedIndex ? 'highlighted' : ''}`}
                                                onClick={() => handleSuggestionClick(suggestion)}>
                                                {suggestion}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </td>
                    <td>
                        <select id={"notifyComparator" + conditionIndex}
                                className={"filter-dropdown filter-comparator-dropdown"}
                                defaultValue={notification.notificationConditions[conditionIndex].comparator}
                                onChange={(e) => handleInputChange(notificationIndex, conditionIndex, 'comparator', e.target.value)}
                                disabled={false}
                        >
                            <option className={"filter-option"} value={eq}>{eq}</option>
                            <option className={"filter-option"} value={ne}>{ne}</option>
                            <option className={"filter-option"} value={lt}>{lt}</option>
                            <option className={"filter-option"} value={lte}>{lte}</option>
                            <option className={"filter-option"} value={gt}>{gt}</option>
                            <option className={"filter-option"} value={gte}>{gte}</option>
                        </select>
                    </td>
                    <td>
                        {rightConditions(notification, notificationIndex, conditionIndex)}
                    </td>
                    <td style={{display: "inline"}}>
                        <button type={"button"}
                                className={"filter-number-button-notify"}
                                onClick={() => toggleNumber(notificationIndex, conditionIndex)}  // Adjust index if needed
                                disabled={false}
                        >
                            <div id={"filter-number-button-text"}>#</div>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

    function handleCancelNotification() {
        setNotificationsList(originalNotificationsList);
    }

    function notificationIsValid(notification) {
        for (const condition of notification.notificationConditions) {
            if (!props.homeInfo.symbols.includes(condition.symbolLeft)) {
                addErrorSuccess(condition.symbolLeft + " is not in symbols list", red);
                return false;
            }
            if (!condition.number) {
                if (!props.homeInfo.symbols.includes(condition.symbolRight)) {
                    addErrorSuccess(condition.symbolRight + " is not in symbols list", red);
                    return false;
                }
                if (condition.conditionLeft === condition.conditionRight && condition.symbolLeft === condition.symbolRight) {
                    addErrorSuccess(condition.conditionLeft + " of " + condition.symbolLeft + " " + condition.comparator + " " + condition.conditionRight + " of " + condition.symbolRight + " is not a valid comparison", red);
                    return false;
                }
            }
        }
        return true;
    }

    function saveNotification(notification) {
        try {
            if (!notificationIsValid(notification)) {
                return;
            }
            setSavingNotification(true);
            saveNotificationCall(true, notification)
                .then(response => {
                    if (response) {
                        props.setHomeInfo((prevState) => ({
                            ...prevState,
                            notifications: response
                        }));
                        setOriginalNotificationsList(response);
                        setNotificationsList(response);
                    }
                    setSavingNotification(false);
                    addErrorSuccess("Notification saved!", green)
                })
                .catch(error => {
                    setSavingNotification(false);
                    addErrorSuccess("Could not save notification", red);
                    console.error("Error saving notification:", error);
                    if (error.status === 403) {
                        navigate("/login");
                    }
                });

        } catch (error) {
            setSavingNotification(false);
            addErrorSuccess("Could not save notification", red);
            console.error("Error saving notification:", error);
        }
    }

    const handleSaveNotification = (e, notification) => {
        e.preventDefault();
        saveNotification(notification);
    }

    function addNotification() {
        if (notificationsList.length >= 5) {
            addErrorSuccess("Only 5 notifications allowed at one time", red)
            return;
        }
        setNotificationsList(prevNotifications => [...prevNotifications, initialNotification]);
        handleArrowClick(notificationsList.length);
    }

    function deleteNotification(notification, index) {
        try {
            if (notification.id == null) {
                handleArrowClick(index);
                handleCancelNotification();
            } else {
                setDeletingNotification(true);
                deleteNotificationCall(true, notification)
                    .then(response => {
                        if (response) {
                            props.setHomeInfo((prevState) => ({
                                ...prevState,
                                notifications: response
                            }));
                            setNotificationsList(response);
                            setOriginalNotificationsList(response);
                        }
                        setDeletingNotification(false);
                        addErrorSuccess("Notification Deleted!", green)
                    })
                    .catch(error => {
                        setDeletingNotification(false);
                        addErrorSuccess("Could not delete notification", red);
                        console.error("Error deleting notification:", error);
                        if (error.status === 403) {
                            navigate("/login");
                        }
                    });

            }
        } catch (error) {
            setDeletingNotification(false);
            addErrorSuccess("Could not delete notification", red);
            console.error("Error deleting notification:", error);
        }
    }

    return (
        <div className={"admin-dashboard-content fade-in"} style={{overflow: 'visible'}} onClick={() => setSuggestions([])}>
            {errorSuccessMessage && (
                <div className="success-or-error-notifications" style={{ color: errorSuccessMessage.props.style.color, opacity: errorSuccessOpacity }}>
                    {errorSuccessMessage.props.children}
                </div>
            )}
            <div className={"section-subheading admin-subheading center"}>Notifications</div>
            {savingNotification ? <Loading text={"Saving notification"}/> :
                deletingNotification ? <Loading text={"Deleting notification"}/> :
                    <>
                        {notificationsList == null || !notificationsList.length ?
                        <div className={"nothing-here margin-bottom-1"}>Notifications will appear here</div>
                        :
                        notificationsList.map((notification, index) => (
                            <form key={index} onSubmit={(e) => handleSaveNotification(e, notification)}>
                                <div
                                    className={`notification ${clickedIndex === index ? 'expanded' : ''}`}
                                    style={{
                                        maxHeight: clickedIndex === index ? `${contentRefs.current[index]?.scrollHeight}px` : '50px'
                                    }}
                                    ref={el => {
                                        contentRefs.current[index] = el;
                                        if (el && clickedIndex === index) {
                                            el.style.maxHeight = `${el.scrollHeight}px`;
                                        }
                                    }}
                                >
                                    <button
                                        type={"button"}
                                        className={`expand-collapse-button ${clickedIndex === index ? 'expanded' : ''}`}
                                        onClick={() => handleArrowClick(index)}>V
                                    </button>

                                    <div className={"notification-name-container"}>
                                        <input
                                            disabled={clickedIndex !== index}
                                            className={`notification-name ${clickedIndex === index ? 'expanded' : ''}`}
                                            defaultValue={notification.name}
                                            onChange={(e) => handleNameChange(index, e.target.value)}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                    <div>
                                        {notification.notificationConditions.map((notificationCondition, conditionIndex) => (
                                            conditionTable(notification, index, conditionIndex)
                                        ))}
                                        <div className="notification-condition-buttons">
                                            <button className={"filter-add-subtract-button"} type={"button"}
                                                    onClick={() => addCondition(index)}>
                                                <div className={"filter-add-subtract-text dashboard-link"}>+</div>
                                            </button>
                                            <button className={"filter-add-subtract-button"} type={"button"}
                                                    onClick={() => removeCondition(index)}>
                                                <div className={"filter-add-subtract-text dashboard-link"}>-</div>
                                            </button>
                                            <div className={"notify-active"}>
                                                <div className={"active-text"}>Active</div>
                                                <div className="toggle-switch">
                                                    <input
                                                        id={`toggle-${index}`}
                                                        type="checkbox"
                                                        checked={notification.active}
                                                        onChange={() => handleActiveToggleChange(index)}
                                                        autoComplete={"off"}
                                                    />
                                                    <label htmlFor={`toggle-${index}`} className="switch"></label>
                                                </div>
                                            </div>
                                            <button className={"notification-save"}
                                                    type={"submit"}
                                                    style={{marginRight: '2px'}}
                                            >Save
                                            </button>
                                            <button
                                                type={"button"}
                                                className={"notification-save"}
                                                style={{marginLeft: '2px'}}
                                                onClick={() => handleCancelNotification()}
                                            >Cancel
                                            </button>
                                            <button
                                                type={"button"}
                                                className={"notification-save"}
                                                style={{display: "block"}}
                                                onClick={() => deleteNotification(notification, index)}
                                            >Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        ))}
                        <div className="notification-condition-buttons">
                            <button className={"filter-add-subtract-button"} type={"button"}
                                    onClick={() => addNotification()}>
                                <div className={"filter-add-subtract-text dashboard-link"}>+</div>
                            </button>
                        </div>
                    </>
            }
        </div>
    );
}

export default Notification;
