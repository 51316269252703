import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import Loading from "./Loading";
import {useNavigate} from "react-router-dom";
import {rfAccessToken} from "../constants/Constants";
import {fetchHomeContent, fetchHomeContentNoAccount} from "../service/UserService";
import WatchList from "./WatchList";
import {isUserLoggedIn} from "../service/AuthService";

const border = 'solid 2px #b619ff';
const none = 'none';


function Home() {
    const navigate = useNavigate();

    const [toggle, setToggle] = useState(true);
    const [loading, setLoading] = useState(true);
    const [contentError, setContentError] = useState(false);
    const [homeInfo, setHomeInfo] = useState(null);
    const loggedIn = isUserLoggedIn();

    useEffect(() => {
        if (!localStorage.getItem(rfAccessToken)) {
            // navigate("/login");
        }
        if (loggedIn) {
            try {
                setLoading(true);
                fetchHomeContent(true)
                    .then(response => {
                        setHomeInfo(response);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Error loading home content:", error);
                        setContentError(true);
                        setLoading(false);
                        if (error.status === 403) {
                            navigate("/login");
                        }
                    });
            } catch (error) {
                console.error("Error loading home content:", error);
                setContentError(true);
                setLoading(false);
                if (error.status === 403) {
                    navigate("/login");
                }
            }
        } else {
            try {
                setLoading(true);
                fetchHomeContentNoAccount()
                    .then(response => {
                        setHomeInfo(response);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Error loading home content:", error);
                        setContentError(true);
                        setLoading(false);
                    });
            } catch (error) {
                console.error("Error loading home content:", error);
                setContentError(true);
                setLoading(false);
            }
        }
    }, [toggle]);

    const watchListContent = loading ? null :
        contentError ? <div>error</div>
            : (
                <WatchList homeInfo={homeInfo} setHomeInfo={setHomeInfo}/>
            );

    return (
        <div className={"login page"}>
            <Header home={true}/>
            {!loading && !contentError && homeInfo.firstName ?
                <div className={"small-heading"}>Hello, {homeInfo.firstName}!</div>
                :
                <div className={"small-heading"}>Hello!</div>
            }
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"} style={{minHeight: '150px'}}>
                    {loading ?
                        <Loading text={"Fetching Content"}/>
                        :
                        contentError ?
                            <div className={"error-loading"}>Error loading watch list content</div>
                            :
                            watchListContent
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Home;