import Header from "./Header";
import Footer from "./Footer";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {forgotPassword} from "../service/ResetPasswordService";
import Loading from "./Loading";
import {red, green} from "../constants/Constants";

function ForgotPassword() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);

    const [errorSuccessMessage, setErrorSuccessMessage] = useState(null);
    const [errorSuccessOpacity, setErrorSuccessOpacity] = useState(1);

    function addErrorSuccess(message, color) {
        if (window.innerWidth >= 991 || color === green) {
            setErrorSuccessMessage(
                <div className="success-or-error-notifications" style={{color: color, opacity: errorSuccessOpacity}}>
                    {message}
                </div>
            );
            setErrorSuccessOpacity(1);

            setTimeout(() => {
                setErrorSuccessOpacity(0);
            }, 2000);

            setTimeout(() => {
                setErrorSuccessOpacity(1);
                setErrorSuccessMessage(null);
            }, 3000);
        } else {
            window.alert(message);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            forgotPassword(email)
                .then(forgotEmailResponse => {
                    if (forgotEmailResponse === true) {
                        setSent(true);
                    } else {
                        addErrorSuccess("Could not send reset email.", red)
                    }
                    setLoading(false)
                    // navigate("/account");
                })
                .catch(error => {
                    console.error("Error logging in:", error);
                    addErrorSuccess("Could not send reset email.", red)
                    setLoading(false);
                });

        } catch (error) {
            console.error('Login failed:', error);
            setLoading(false);
        }
    };

    const sentForm = (
        <div className="content ">
            <div className={"no-account"}>An email has been sent to {email}.</div>
            <div className={"no-account"}>If you cannot find it, be sure to check your spam folder.</div>
        </div>
    )

    const forgotPasswordForm = (
        <form onSubmit={handleSubmit} className={"fade-in center"} style={{position: "relative"}}>
            {errorSuccessMessage && (
                <div className="success-or-error-login" style={{ color: errorSuccessMessage.props.style.color, opacity: errorSuccessOpacity }}>
                    {errorSuccessMessage.props.children}
                </div>
            )}
            <input className={"contact-form-input"}
                   required
                   type={"email"}
                   placeholder={"Email"}
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
            />
            <div className={"send-wrapper mobile-margin-bottom-0"}>
                <button type={"submit"} className="search">Submit</button>
            </div>

        </form>
    )


    return (
        <div className={"login page"}>
            <Header/>
            <div className={"small-heading"}>Hello!</div>
            <div className={"content"}>
                <div className={"section enclosed row"}>
                    <div className={"half-section login-form"}>
                        {loading ? <Loading text={"Sending Reset Email"}/>
                            :
                            <>
                                <div className={"fade-in"} style={{margin: 'auto', position: "relative"}}>
                                    <button onClick={() => navigate("/login")} className={"search fade-in"}
                                            style={{position: "absolute", left: '0', top: '0'}}
                                    >&larr;</button>
                                </div>
                                <div className={"section-subheading center"} style={{marginBottom:'1rem'}}>Reset Password</div>

                                {!sent ?
                                    forgotPasswordForm
                                    :
                                    sentForm}
                            </>
                        }

                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ForgotPassword;