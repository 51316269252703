import {properties} from "../properties";
import {rfAccessToken, rfRefreshToken} from "../constants/Constants";

export function preRegister (email) {
    return new Promise((resolve, reject) => {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else {
                    reject(new Error("Failed to register"));
                }
            }
        };
        xhttp.open("GET", properties.host + "/auth/preregister?email=" + email, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.send();
    });
}

export function register(firstName, lastName, email, password, confirmationToken) {
    return new Promise((resolve, reject) => {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else {
                    reject(new Error("Failed to register"));
                }
            }
        };
        xhttp.open("POST", properties.host + "/auth/register", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        const user = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            confirmationToken: confirmationToken
        };
        xhttp.send(JSON.stringify(user));
    });
}

export function authenticate(email, password) {
    return new Promise((resolve, reject) => {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else {
                    if (this.status === 500) {
                        resolve(500);
                    } else {
                        reject(new Error("Failed to log in"));
                    }
                }
            }
        };
        xhttp.open("POST", properties.host + "/auth/authenticate", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        const user = {
            email: email,
            password: password
        };
        xhttp.send(JSON.stringify(user));
    });
}

export function logoutCall(firstCall) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    localStorage.removeItem(rfAccessToken);
                    localStorage.removeItem(rfRefreshToken);
                    resolve(true);
                    // resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        logoutCall(false).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to log out."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/auth/logout", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

// export function logout() {
//
//     try {
//         logoutCall(true).then(r => {
//             localStorage.removeItem(rfAccessToken);
//             localStorage.removeItem(rfRefreshToken);
//         } );
//
//         return true;
//     } catch (e) {
//         return false;
//     }
//
// }

export function isUserLoggedIn() {
    const token = localStorage.getItem(rfAccessToken);
    if (!token || token === 'undefined') {
        return false;
    }
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const { exp } = JSON.parse(jsonPayload);
        const currentUnixTime = Math.floor(Date.now() / 1000);

        // return exp > currentUnixTime;
        // as long as they havent been logged out, it will show "Account" as opposed to log in.
        // if I use the above logic, it will show log in in the header if the access token is expired,
        // however, the refresh token may still work
        return exp > 0;
    } catch (error) {
        console.error('Error checking JWT expiration:', error);
        return false;
    }
}

export function getJwtRole() {
    const token = localStorage.getItem(rfAccessToken);
    if (!token) {
        return null;
    }
    try {
        const base64Url = token.split('.')[1]; // Get the payload part of the token
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const { role } = JSON.parse(jsonPayload); // Extract the role from the payload
        return role;
    } catch (error) {
        console.error('Error extracting role from JWT:', error);
        return null;
    }
}

export function refreshToken() {
    return new Promise((resolve, reject) => {
        // Retrieve the refresh token from storage
        const refreshToken = localStorage.getItem(rfRefreshToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    // Parse the response and resolve the promise with the new access token
                    const response = JSON.parse(this.responseText);
                    resolve(response.access_token);
                } else {
                    // If the refresh token request fails, reject the promise
                    reject({ error: new Error("Failed to refresh token."), status: 403 });
                }
            }
        };
        xhttp.open("POST", properties.host + "/auth/refresh", false);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        // Set the Authorization header with the refresh token
        xhttp.setRequestHeader("Authorization", "Bearer " + refreshToken);

        xhttp.send();
    });
}