import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import Loading from "./Loading";
import {useNavigate, useSearchParams} from "react-router-dom";
import {green, red, rfAccessToken, rfRefreshToken} from "../constants/Constants";
import {fetchUserContent} from "../service/UserService";
import {logout, logoutCall} from "../service/AuthService";
import {changePassword} from "../service/ChangePasswordService";
import {deleteAccountCall} from "../service/DeleteAccountService";

const border = 'solid 2px #b619ff';
const none = 'none';

function UserAccount() {
    const navigate = useNavigate();

    const [toggle, setToggle] = useState(true);
    const [loading, setLoading] = useState(true);
    const [contentError, setContentError] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [deleteDisabled, setDeleteDisabled] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();


    const content = searchParams.get('content');
    const [contentEntryText, setContentEntryText] = useState(content);


    const [errorSuccessMessage, setErrorSuccessMessage] = useState(null);
    const [errorSuccessOpacity, setErrorSuccessOpacity] = useState(1);

    function addErrorSuccess(message, color, passwordRestriction) {
        if (window.innerWidth >= 991 || color === green) {
            setErrorSuccessMessage(
                <div
                    className={`success-or-error ${passwordRestriction ? 'success-or-error-password-restriction' : ''}`}
                    style={{color: color, opacity: errorSuccessOpacity}}>
                    {message}
                </div>
            );
            setErrorSuccessOpacity(1);

            setTimeout(() => {
                setErrorSuccessOpacity(0);
            }, 2000);

            setTimeout(() => {
                setErrorSuccessOpacity(1);
                setErrorSuccessMessage(null);
            }, 3000);
        } else {
            window.alert(message);
        }
    }

    function handleBottomButtonClick(command) {
        if (deleteDisabled) {
            return;
        }
        if (command === "logout") {
            try {
                logoutCall(true).then(loginResponse => {
                    if (loginResponse === 500) {
                        window.alert("Could not log out");
                    } else {
                        navigate("/login")
                    }
                })
                    .catch(error => {
                        console.error("Error logging out:", error);
                        window.alert("Could not log out");
                    });
            } catch (error) {
                console.error("Error logging out:", error);
                window.alert("Could not log out");
            }
        } else if (command === "changePassword") {
            setSearchParams({content: "password"})
            setContentEntryText("password")
        } else if (command === 'deleteAccount') {
            const deleteAccount = window.confirm("Are you sure you want to delete your account?");
            if (deleteAccount) {
                try {
                    setLoading(true);
                    deleteAccountCall(true)
                        .then(response => {
                            if (response === true) {
                                addErrorSuccess("Account Deleted!", green);
                                setTimeout(() => {
                                    localStorage.removeItem(rfAccessToken);
                                    localStorage.removeItem(rfRefreshToken);
                                    setDeleteDisabled(true);
                                    navigate("/login")
                                }, 2000); // 2000 milliseconds = 2 seconds
                            } else {
                                addErrorSuccess("Could not delete account", red);
                            }
                            setLoading(false);
                        })
                        .catch(error => {
                            addErrorSuccess("Could not delete account", red);
                            console.error("Error deleting account:", error);
                            setContentError(true);
                            setLoading(false);
                            if (error.status === 403) {
                                navigate("/login");
                            }
                        });
                } catch (error) {
                    addErrorSuccess("Could not delete account", red);
                    console.error("Error deleting account:", error);
                    setContentError(true);
                    setLoading(false);
                    if (error.status === 403) {
                        navigate("/login");
                    }
                }
            }
        } else {
            setSearchParams({content: "account"})
            setContentEntryText("account")
        }
        // } else if (command === "account") {
        //     role === 'ROLE_ADMIN' ? navigate("/admin") : navigate("/account");
        // } else if (command === "login") {
        //     navigate("/login");
        // } else {
        //     navigate("/signup");
        // }
    }

    useEffect(() => {
        if (content === null) {
            setSearchParams({content: 'account'})
        }
        if (!localStorage.getItem(rfAccessToken)) {
            navigate("/login");
        }
        try {
            setLoading(true);
            fetchUserContent(true)
                .then(response => {
                    setUserInfo(response);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error loading home content:", error);
                    setContentError(true);
                    setLoading(false);
                    if (error.status === 403) {
                        navigate("/login");
                    }
                });
        } catch (error) {
            console.error("Error loading home content:", error);
            setContentError(true);
            setLoading(false);
            if (error.status === 403) {
                navigate("/login");
            }
        }
    }, [toggle]);

    function timestampToDate(timestamp) {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: '2-digit',
        });
        return formattedDate;
    }

    const accountContent = loading ? null :
        contentError ? <div>error</div>
            : (
                <div className={"admin-dashboard-content fade-in"} style={{overflow: 'visible'}}>
                    {errorSuccessMessage && (
                        <div className="success-or-error"
                             style={{color: errorSuccessMessage.props.style.color, opacity: errorSuccessOpacity}}>
                            {errorSuccessMessage}
                        </div>
                    )}
                    <table className={"dashboard-table fade-in"} style={{maxWidth: '600px', margin: 'auto'}}>
                        <thead>
                        <tr className={"dashboard-tr dashboard-th"}>
                            <th className={"th-width-60-percent"}>First Name</th>
                            <th className={""}>Last Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className={"dashboard-tr"}>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner"}>{userInfo?.firstName}</div>
                            </td>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner"}>{userInfo?.lastName}</div>
                            </td>
                        </tr>
                        </tbody>
                        {/*<br/>*/}
                        <thead>
                        <tr className={"dashboard-tr dashboard-th"}>
                            <th className={"th-width-60-percent"} style={{paddingTop: '1.5rem'}}>Email</th>
                            <th className={""} style={{paddingTop: '1.5rem'}}>Date Created</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className={"dashboard-tr"}>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner"}>{userInfo?.email}</div>
                            </td>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner"}>{timestampToDate(userInfo?.dateCreated)}</div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className={"user-account-links"}>
                        <div className={"user-account-link"}
                             onClick={() => handleBottomButtonClick("changePassword")}
                        >
                            Change Password
                        </div>
                        <div className={"user-account-link"}
                             onClick={() => handleBottomButtonClick("logout")}
                        >
                            Log Out
                        </div>
                        <div className={"user-account-link"} style={{color: red}}
                             onClick={() => handleBottomButtonClick("deleteAccount")}
                        >
                            Delete Account
                        </div>
                    </div>
                </div>
            );

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    function validatePassword() {
        // Regex explanation:
        // ^                 : start of string
        // (?=.*[a-z])       : at least one lowercase letter
        // (?=.*[A-Z])       : at least one uppercase letter
        // (?=.*\d)          : at least one number
        // .{8,}             : at least 8 characters
        // $                 : end of string
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

        return regex.test(newPassword);
    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        if (!validatePassword()) {
            addErrorSuccess('Password must be at least 8 characters long and must include at least 1 uppercase letter, 1 lowercase letter, and 1 number.', red, true);
        } else if (newPassword !== confirmPassword) {
            addErrorSuccess('Passwords do not match.', red);
        } else {
            try {
                setLoading(true);
                changePassword(true, currentPassword, newPassword, confirmPassword)
                    .then(response => {
                        if (response === true) {
                            setCurrentPassword("");
                            setNewPassword("");
                            setConfirmPassword("");
                            addErrorSuccess("Password Changed!", green);
                            setTimeout(() => {
                                handleBottomButtonClick("account");
                            }, 2000); // 2000 milliseconds = 2 seconds

                        } else {
                            addErrorSuccess("Could not change password", red);
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Error changing password:", error);
                        addErrorSuccess("Could not change password", red);
                        setContentError(true);
                        setLoading(false);
                        if (error.status === 403) {
                            navigate("/login");
                        }
                    });
            } catch (error) {
                console.error("Error changing password:", error);
                addErrorSuccess("Could not change password", red);
                setContentError(true);
                setLoading(false);
                if (error.status === 403) {
                    navigate("/login");
                }
            }
        }
    };

    const changePasswordForm = (
        <form onSubmit={handleChangePassword} className={"fade-in"}
        >
            <input className={"contact-form-input"}
                   required
                   type={"password"}
                   placeholder={"Password"}
                   value={currentPassword}
                   onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   type={"password"}
                   placeholder={"New Password"}
                   value={newPassword}
                   onChange={(e) => setNewPassword(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   type={"password"}
                   placeholder={"Confirm Password"}
                   value={confirmPassword}
                   onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className={"send-wrapper mobile-margin-bottom-0"}>
                <button type={"submit"} className="search">Submit</button>
            </div>
        </form>
    );

    const changePasswordContent = loading ? null :
        contentError ? <div>error</div>
            :
            <div className={"fade-in"} style={{margin: 'auto', position: "relative"}}>
                <button onClick={() => handleBottomButtonClick("account")} className={"search fade-in"}
                        style={{position: "absolute", left: '0', top: '0'}}
                >&larr;</button>
                <div className={"section-subheading fade-in"} style={{marginBottom: '1rem'}}>Change Password</div>
                {errorSuccessMessage && (
                    <div className="success-or-error"
                         style={{color: errorSuccessMessage.props.style.color, opacity: errorSuccessOpacity}}>
                        {errorSuccessMessage}
                    </div>
                )}
                {changePasswordForm}
            </div>


    return (
        <div className={"login page"}>
            <Header home={false}/>
            {!loading && !contentError ?
                <div className={"small-heading"}>Hello, {userInfo.firstName}!</div>
                :
                <div className={"small-heading"}>Hello</div>
            }
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"}>
                    {loading ? <Loading/> :
                        contentEntryText === 'password' ?
                            changePasswordContent
                            :
                            accountContent}
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default UserAccount;
