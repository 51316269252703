import Header from "./Header";
import Loading from "./Loading";
import Footer from "./Footer";
import {useState} from "react";
import {fetchAnalyzeContent} from "../service/AnalyzeService";
import {green, red} from "../constants/Constants";
import {excludeSymbolCall} from "../service/ExclusionService";
import {useNavigate} from "react-router-dom";


function Exclusion() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [symbol, setSymbol] = useState("");
    const [errorSuccessMessage, setErrorSuccessMessage] = useState(null);
    const [errorSuccessOpacity, setErrorSuccessOpacity] = useState(1);

    function addErrorSuccess(message, color) {
        if (window.innerWidth >= 991 || color === green) {
            setErrorSuccessMessage(
                <div className="success-or-error" style={{color: color, opacity: errorSuccessOpacity}}>
                    {message}
                </div>
            );
            setErrorSuccessOpacity(1);

            setTimeout(() => {
                setErrorSuccessOpacity(0);
            }, 2000);

            setTimeout(() => {
                setErrorSuccessOpacity(1);
                setErrorSuccessMessage(null);
            }, 3000);
        } else {
            window.alert(message);
        }

    }



    function excludeSymbol() {
        try {
            setLoading(true);
            excludeSymbolCall(true, symbol)
                .then(response => {
                    if (response === true) {
                        addErrorSuccess("Symbol added!", green)
                        setSymbol("");
                        setLoading(false);
                    } else {
                        addErrorSuccess("Could not add symbol", red)
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error("Error loading analyze content:", error);
                    addErrorSuccess("Server Error", red)
                    setLoading(false);
                    if (error.status === 403) {
                        navigate("/login");
                    }
                });
        } catch (error) {
            console.error("Error loading analyze content:", error);
            addErrorSuccess("Server Error", red)
            setLoading(false);
        }
    }

    return (
        <div className={"login page"}>
            <Header home={false}/>
            <div className={"small-heading"}>EXCLUSION</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"} style={{position: 'relative'}}>
                    {errorSuccessMessage && (
                        <div className="success-or-error"
                             style={{color: errorSuccessMessage.props.style.color, opacity: errorSuccessOpacity}}>
                            {errorSuccessMessage}
                        </div>
                    )}
                    {loading ?
                        <Loading text={"Fetching Content"}/>
                        :
                        <form style={{margin: "auto"}}
                              className={"fade-in"}
                              onSubmit={(e) => {
                                  e.preventDefault();
                                  excludeSymbol();
                              }}>

                            <div className={"ticker-input-submit-container"}>
                                <input id={"symbol-to-search"}
                                       required={true}
                                       maxLength={5}
                                       className={"ticker-input"}
                                       value={symbol}
                                       placeholder={'Symbol'}
                                       onChange={(e) => {
                                           setSymbol(e.target.value.toUpperCase());
                                       }}
                                       autoComplete={"off"}
                                />
                                <div className={"center"}>
                                    <button type={"submit"} className={"search"}>Exclude</button>
                                </div>
                                <div className={"center"} style={{marginTop: '5px'}}>
                                    <button type={"button"} className={"search"}>Load DB</button>
                                </div>
                            </div>


                        </form>
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )


}

export default Exclusion;