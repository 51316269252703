import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import Loading from "./Loading";
import {authenticate, getJwtRole, isUserLoggedIn} from "../service/AuthService";
import {useNavigate} from "react-router-dom";
import {green, red, rfAccessToken, rfRefreshToken} from "../constants/Constants";

function LogIn() {

    const navigate = useNavigate();

    let [role, setRole] = useState(getJwtRole());
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const [errorSuccessMessage, setErrorSuccessMessage] = useState(null);
    const [errorSuccessOpacity, setErrorSuccessOpacity] = useState(1);

    function addErrorSuccess(message, color) {
        if (window.innerWidth >= 991 || color === green) {
            setErrorSuccessMessage(
                <div className="success-or-error-notifications" style={{color: color, opacity: errorSuccessOpacity}}>
                    {message}
                </div>
            );
            setErrorSuccessOpacity(1);

            setTimeout(() => {
                setErrorSuccessOpacity(0);
            }, 2000);

            setTimeout(() => {
                setErrorSuccessOpacity(1);
                setErrorSuccessMessage(null);
            }, 3000);
        } else {
            window.alert(message);
        }
    }

    useEffect(() => {
       if (isUserLoggedIn()) {
           navigate("/");
       }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            authenticate(email, password)
                .then(loginResponse => {
                    if (loginResponse === 500) {
                        addErrorSuccess("Could not log in", red);
                        setLoading(false);
                    } else {
                        localStorage.setItem(rfAccessToken, JSON.stringify(loginResponse.access_token));
                        localStorage.setItem(rfRefreshToken, JSON.stringify(loginResponse.refresh_token));
                        role = getJwtRole();
                        setRole(role);
                        // role === 'ROLE_ADMIN' ? navigate("/admin") : navigate("/account");
                        navigate("/");
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error("Error logging in:", error);
                    addErrorSuccess("Could not log in", red);
                    setLoading(false);
                });

        } catch (error) {
            console.error('Login failed:', error);
            setLoading(false);
        }
    };

    const loginForm = (
        <form onSubmit={handleSubmit} className={"fade-in center"} style={{position: "relative"}}>
            {errorSuccessMessage && (
                <div className="success-or-error-login" style={{ color: errorSuccessMessage.props.style.color, opacity: errorSuccessOpacity }}>
                    {errorSuccessMessage.props.children}
                </div>
            )}
            <input className={"contact-form-input"}
                   required
                   type={"email"}
                   placeholder={"Email"}
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
            />
            <input className={"contact-form-input"}
                   required
                   type={"password"}
                   placeholder={"Password"}
                   value={password}
                   onChange={(e) => setPassword(e.target.value)}
            />
            <div className={"send-wrapper mobile-margin-bottom-0"}>
                <button type={"submit"} className="search">Log In</button>
            </div>
        </form>
    );

    return (
        <div className={"login page"}>
            <Header/>
            <div className={"small-heading"}>Hello!</div>
            <div className={"content"}>
                <div className={"section enclosed row"}>
                    {loading ? <Loading text={"Logging In"}/>
                        :
                        <div className={"half-section login-form"}>
                            <>
                                <div className={"section-subheading center"} style={{marginBottom: '1rem'}}>Log In</div>
                                {loginForm}
                                <div className={"have-account"}>
                                    <span>New to Rexius Finance? Click </span>
                                    <a href={"/signup"} className={"click-here"}>here</a>
                                    <span> to sign up!</span>
                                </div>
                                <div className={"have-account"}>
                                    <a href={"/forgot-password"} className={"click-here"}>Forgot your password?</a>
                                </div>
                            </>

                        </div>
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default LogIn;