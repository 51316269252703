import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useRef, useState} from "react";
import Loading from "./Loading";
import {useNavigate, useSearchParams} from "react-router-dom";
import {red, green, blue, rfAccessToken} from "../constants/Constants";
import {fetchPolitician, fetchPoliticians} from "../service/CongressService";
import Policitian from "./Policitian";
import {isUserLoggedIn} from "../service/AuthService";

const border = 'solid 2px #b619ff';
const none = 'none';


function Congress() {

    const navigate = useNavigate();

    const [toggle, setToggle] = useState(true);
    const [loading, setLoading] = useState(true);
    const [contentError, setContentError] = useState(false);
    const [congressInfo, setCongressInfo] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();


    const id = searchParams.get('id');
    const politicianRef = useRef(null);
    const suggestionRefs = useRef([]);


    const [suggestions, setSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1); // Step 1: Track the highlighted suggestion index
    const name = !congressInfo || congressInfo.name === null ? "Search a Politician" : congressInfo.name;

    const loggedIn = isUserLoggedIn();

    useEffect(() => {
        if (highlightedIndex >= 0 && suggestionRefs.current[highlightedIndex]) {
            suggestionRefs.current[highlightedIndex].scrollIntoView({
                // behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [highlightedIndex]);


    const [politicianInput, setPoliticianInput] = useState("");

    const handleSuggestionClick = (symbol) => {
        setPoliticianInput(symbol);
        setSuggestions([]);
        politicianRef.current.focus();
        setHighlightedIndex(-1); // Reset the highlighted index when input changes
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex(prevIndex => Math.min(prevIndex + 1, suggestions.length - 1));
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (e.key === 'Enter' && highlightedIndex >= 0) {
            e.preventDefault();
            handleSuggestionClick(suggestions[highlightedIndex]);
        }
    };


    const [errorSuccessMessage, setErrorSuccessMessage] = useState(null);
    const [errorSuccessOpacity, setErrorSuccessOpacity] = useState(1);

    function addErrorSuccess(message, color) {
        if (window.innerWidth >= 991 || color === green) {
            setErrorSuccessMessage(
                <div className="success-or-error-congress" style={{color: color, top: !congressInfo || congressInfo.name === null ? '-9rem' : '-10rem', opacity: errorSuccessOpacity}}>
                    {message}
                </div>
            );
            setErrorSuccessOpacity(1);

            setTimeout(() => {
                setErrorSuccessOpacity(0);
            }, 2000);

            setTimeout(() => {
                setErrorSuccessOpacity(1);
                setErrorSuccessMessage(null);
            }, 3000);
        } else {
            window.alert(message);
        }


    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        setPoliticianInput(value);
        if (value.length > 0) {
            const filteredSuggestions = congressInfo.politicians.filter(politician => politician.toLowerCase().includes(value.toLowerCase()));
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
        setHighlightedIndex(-1); // Reset the highlighted index when input changes
    };

    function searchPolitician(politician) {
        if (!congressInfo.politicians.map(p => p.toLowerCase()).includes(politician.toLowerCase())) {
            addErrorSuccess(politician + " is not in politicians list", red);
            return;
        }
        const id = congressInfo.politicians.findIndex(item => item.toLowerCase() === politician.toLowerCase());

        navigate("/congress?id=" + id);
        setToggle(!toggle);
        setSuggestions([]);
    }

    const searchForm = (
        <form style={{margin: "auto", width: '100%'}}
              onSubmit={(e) => {
                  e.preventDefault();
                  searchPolitician(document.getElementById("politician-to-search").value);
              }}>

            <div className={"ticker-input-submit-container"}>
                <input id={"politician-to-search"}
                       required={true}
                       ref={politicianRef}
                       className={"politician-input"}
                       value={politicianInput}
                       placeholder={'Politician'}
                       onChange={(e) => {
                           setPoliticianInput(e.target.value);
                           handleInputChange(e)
                       }}
                       onKeyDown={handleKeyDown} // Step 2: Handle keyboard events
                       autoComplete={"off"}
                />
                {suggestions.length > 0 && (
                    <div className={"suggestions-wrapper"}>
                        <ul className="suggestions" style={{width: '200px'}}>
                            {suggestions.sort((a, b) => a.localeCompare(b)).map((suggestion, index) => (
                                <li key={index}
                                    ref={el => suggestionRefs.current[index] = el}
                                    className={`suggestion dashboard-link ${index === highlightedIndex ? 'highlighted' : ''}`}
                                    onClick={() => handleSuggestionClick(suggestion)}>
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className={"center"}>
                    <button type={"submit"} className={"search"}>Search</button>
                </div>
            </div>


        </form>
    );


    useEffect(() => {
        if (!localStorage.getItem(rfAccessToken)) {
            // navigate("/login");
        }
        if (loggedIn) {
            try {
                setContentError(false);
                setLoading(true);
                fetchPolitician(true, id)
                    .then(response => {
                        setCongressInfo(response);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Error loading congress content:", error);
                        try {
                            fetchPoliticians(true)
                                .then(response => {
                                    setCongressInfo({
                                        id: null,
                                        name: null,
                                        congressType: null,
                                        party: null,
                                        trades: null,
                                        politicians: response
                                    })
                                })
                        } catch
                            (error) {
                            console.error("Error loading politicians:", error);
                        }
                        setContentError(true);
                        setLoading(false);
                        if (error.status === 403) {
                            navigate("/login");
                        }
                    });
            } catch (error) {
                console.error("Error loading congress content:", error);
                setContentError(true);
                setLoading(false);
                if (error.status === 403) {
                    navigate("/login");
                }
            }
        } else {
            setLoading(false);
        }
    }, [toggle]);

    const content = (
        <Policitian congressInfo={congressInfo}
                    setCongressInfo={setCongressInfo}
                    id={id}
                    toggle={toggle}
                    setToggle={setToggle}
                    searchForm={searchForm}
                    setSuggestions={setSuggestions}
                    errorSuccessMessage={errorSuccessMessage}
                    errorSuccessOpacity={errorSuccessOpacity}
                    name={name}
        />
    );

    const contentBody = (
        <>
            <div className={"section-subheading admin-subheading fade-in"}
                 style={{width: "100%", marginTop: 0}}>{name}</div>
            {congressInfo != null && congressInfo.congressType != null && congressInfo.party != null &&
                <div className={"congress-party fade-in"}><span>{congressInfo.congressType} / </span>
                    <span
                        style={{color: congressInfo.party === 'R' ? red : congressInfo.party === 'D' ? blue : ''}}>{congressInfo.party}</span>
                </div>
            }
            {searchForm}
            {
                contentError ?
                    <div className={"error-loading"}>Could not perform search</div>
                    :
                    content

            }
        </>
    );

    return (
        <div className={"login page"}
             onClick={() => setSuggestions([])}>
            <Header home={false}/>
            <div className={"small-heading"}>CONGRESS</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"} style={{minHeight: '150px'}}>
                    {loading ?
                        <Loading text={"Fetching Content"}/>
                        :
                        loggedIn ?
                            contentBody
                            :
                            <div className={"must-log-in fade-in"} style={{margin: "auto", alignSelf: 'center'}}>
                                <span><a href={"/login"}
                                         className={"click-here"}>Log in</a> to search politicians</span>
                            </div>
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Congress;