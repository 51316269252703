import DayChart from "./DayChart";
import YearChart from "./YearChart";
import {useEffect, useState} from "react";
import Day5Chart from "./Day5Chart";
import Month1Chart from "./Month1Chart";
import Month6Chart from "./Month6Chart";
import {blue, green, grey, orange, purple, red, white, yellow} from "../constants/Constants";

const border = 'solid 2px #b619ff';
const noBorder = 'none';

const borders = {
    prices: 'solid 2px ',
    upperBollingers: 'solid 2px ' + purple,
    lowerBollingers: 'solid 2px ' + purple,
    sma50s: 'solid 2px ' + blue,
    sma200s: 'solid 2px ' + white,
    ema12s: 'solid 2px ' + yellow,
    ema26s: 'solid 2px ' + orange,
}

function Charts(props) {

    const [day1Border, setDay1Border] = useState(border);
    const [day5Border, setDay5Border] = useState(noBorder);
    const [month1Border, setMonth1Border] = useState(noBorder);
    const [month6Border, setMonth6Border] = useState(noBorder);
    const [year1Border, setYear1Border] = useState(noBorder);
    let [changePercentage, setChangePercentage] = useState(props.analyzeInfo?.ticker?.changePercentage);

    const [chart, setChart] = useState(
        props.analyzeInfo.stockPoints && props.analyzeInfo.stockPoints.length ?
            <DayChart stockPoints={props.analyzeInfo.stockPoints}
                      previousClose={props.analyzeInfo.ticker?.previousClose}
                      borders={borders}
                      noBorder={noBorder}
                      color={changePercentage > 0 ? green : changePercentage < 0 ? red : grey}/>
            : null
    )

    function calculateChangePercentage(initialValue, finalValue) {
        if (initialValue === 0) {
            // throw new Error("Initial value cannot be zero when calculating percentage change.");
            return 0;
        }

        const change = finalValue - initialValue;
        const changePercentage = (change / initialValue) * 100;

        return changePercentage;
    }

    function handleChartSwitch(chart) {
        if (props.analyzeInfo.ticker === null) {
            return;
        }
        if (chart === '1d') {
            changePercentage = props.analyzeInfo.ticker.changePercentage;
            setChangePercentage(changePercentage);
            setChart(<DayChart stockPoints={props.analyzeInfo.stockPoints}
                               previousClose={props.analyzeInfo.ticker?.previousClose}
                               borders={borders}
                               noBorder={noBorder}
                               color={changePercentage > 0 ? green : changePercentage < 0 ? red : grey}
            />)
            setDay1Border(border);
            setDay5Border(noBorder);
            setMonth1Border(noBorder);
            setMonth6Border(noBorder);
            setYear1Border(noBorder);
        } else if (chart === '5d') {
            const stockPoints = props.analyzeInfo.fiveDayStockPoints;
            changePercentage = calculateChangePercentage(stockPoints[0].price, stockPoints[stockPoints.length - 1].price);
            setChangePercentage(changePercentage);
            setChart(<Day5Chart fiveDayStockPoints={stockPoints}
                                borders={borders}
                                noBorder={noBorder}
                                color={changePercentage > 0 ? green : changePercentage < 0 ? red : grey}

            />)
            setDay1Border(noBorder);
            setDay5Border(border);
            setMonth1Border(noBorder);
            setMonth6Border(noBorder);
            setYear1Border(noBorder);
        } else if (chart === '1m') {
            const stockPoints = props.analyzeInfo.yearStockPoints.slice(-22);
            changePercentage = calculateChangePercentage(stockPoints[0].price, stockPoints[stockPoints.length - 1].price);
            setChangePercentage(changePercentage);
            setChart(<Month1Chart yearStockPoints={stockPoints}
                                  borders={borders}
                                  noBorder={noBorder}
                                  color={changePercentage > 0 ? green : changePercentage < 0 ? red : grey}

            />)
            setDay1Border(noBorder);
            setDay5Border(noBorder);
            setMonth1Border(border);
            setMonth6Border(noBorder);
            setYear1Border(noBorder);
        } else if (chart === '6m') {
            const stockPoints = props.analyzeInfo.yearStockPoints.slice(-130);
            changePercentage = calculateChangePercentage(stockPoints[0].price, stockPoints[stockPoints.length - 1].price);
            setChangePercentage(changePercentage);
            setChart(<Month6Chart yearStockPoints={stockPoints}
                                  borders={borders}
                                  noBorder={noBorder}
                                  color={changePercentage > 0 ? green : changePercentage < 0 ? red : grey}

            />)
            setDay1Border(noBorder);
            setDay5Border(noBorder);
            setMonth1Border(noBorder);
            setMonth6Border(border);
            setYear1Border(noBorder);
        } else if (chart === 'year') {
            const stockPoints = props.analyzeInfo.yearStockPoints;
            changePercentage = calculateChangePercentage(stockPoints[0].price, stockPoints[stockPoints.length - 1].price);
            setChangePercentage(changePercentage);
            setChart(<YearChart yearStockPoints={stockPoints}
                                borders={borders}
                                noBorder={noBorder}
                                color={changePercentage > 0 ? green : changePercentage < 0 ? red : grey}

            />)
            setDay1Border(noBorder);
            setDay5Border(noBorder);
            setMonth1Border(noBorder);
            setMonth6Border(noBorder);
            setYear1Border(border);
        }
    }

    useEffect(() => {
        if (props.analyzeInfo.stockPoints && props.analyzeInfo.stockPoints.length) {
            handleChartSwitch('1d');
        } else if (props.analyzeInfo.fiveDayStockPoints && props.analyzeInfo.fiveDayStockPoints.length) {
            handleChartSwitch('5d');
        } else if (props.analyzeInfo.yearStockPoints && props.analyzeInfo.yearStockPoints.length) {
            handleChartSwitch('1m');
        }
    }, [])

    return (
        <div className={"admin-dashboard-content"} style={{overflow: 'visible', maxHeight: "max-content"}}
             onClick={() => props.setSuggestions([])}>
            {props.errorSuccessMessage && (
                <div className="success-or-error"
                     style={{color: props.errorSuccessMessage.props.style.color, opacity: props.errorSuccessOpacity}}>
                    {props.errorSuccessMessage}
                </div>
            )}
            {props.ticker !== null &&
                (
                    (props.analyzeInfo.stockPoints && props.analyzeInfo.stockPoints.length > 0)
                    || (props.analyzeInfo.fiveDayStockPoints && props.analyzeInfo.fiveDayStockPoints.length > 0)
                    || (props.analyzeInfo.yearStockPoints && props.analyzeInfo.yearStockPoints.length > 0)
                )
                &&
                <div className={'chart-switch-buttons'}>
                    {props.analyzeInfo.stockPoints && props.analyzeInfo.stockPoints.length > 0 &&
                        <button className={'chart-switch-button'} style={{border: day1Border}}
                                onClick={() => handleChartSwitch('1d')}>1D
                        </button>
                    }
                    {props.analyzeInfo.fiveDayStockPoints && props.analyzeInfo.fiveDayStockPoints.length >= 5 &&
                        <button className={'chart-switch-button'} style={{border: day5Border}}
                                onClick={() => handleChartSwitch('5d')}>5D
                        </button>
                    }
                    {props.analyzeInfo.yearStockPoints && props.analyzeInfo.yearStockPoints.length >= 22 &&
                        <button className={'chart-switch-button'} style={{border: month1Border}}
                                onClick={() => handleChartSwitch('1m')}>1M
                        </button>
                    }
                    {props.analyzeInfo.yearStockPoints && props.analyzeInfo.yearStockPoints.length >= 130 &&
                        <button className={'chart-switch-button'} style={{border: month6Border}}
                                onClick={() => handleChartSwitch('6m')}>6M
                        </button>
                    }
                    {props.analyzeInfo.yearStockPoints && props.analyzeInfo.yearStockPoints.length >= 130 &&
                        <button className={'chart-switch-button'} style={{border: year1Border}}
                                onClick={() => handleChartSwitch('year')}>1Y
                        </button>
                    }
                </div>
            }
            {props.ticker !== null && props.ticker.change !== null &&
                <div className={"chart-change-percentage"}
                     style={{color: changePercentage > 0 ? green : changePercentage < 0 ? red : grey}}>{changePercentage > 0 ? '+' : ''}
                    {new Intl.NumberFormat('en-US').format(changePercentage.toFixed(2))}%
                </div>
            }
            <div className={"fade-in"}>
                {props.ticker !== null && (
                    chart ?
                        chart
                        :
                        <div className={'center'}>No Charts Available</div>
                )}
            </div>
        </div>

    )
}

export default Charts;