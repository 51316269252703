import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import Loading from "./Loading";
import {useNavigate} from "react-router-dom";
import {rfAccessToken} from "../constants/Constants";
import Notification from "./Notification";
import {fetchNotifications} from "../service/NotifyService";
import {isUserLoggedIn} from "../service/AuthService";

function Notify() {
    const navigate = useNavigate();

    const [toggle, setToggle] = useState(true);
    const [loading, setLoading] = useState(true);
    const [contentError, setContentError] = useState(false);
    const [homeInfo, setHomeInfo] = useState(null);
    const loggedIn = isUserLoggedIn();

    useEffect(() => {
        if (!localStorage.getItem(rfAccessToken)) {
            // navigate("/login");
        }
        if (loggedIn) {
            try {
                setLoading(true);
                fetchNotifications(true)
                    .then(response => {
                        setHomeInfo(response);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Error fetching notifications:", error);
                        setContentError(true);
                        setLoading(false);
                        if (error.status === 403) {
                            navigate("/login");
                        }
                    });
            } catch (error) {
                console.error("Error fetching notifications:", error);
                setContentError(true);
                setLoading(false);
                if (error.status === 403) {
                    navigate("/login");
                }
            }
        } else {
            setLoading(false);
        }
    }, [toggle]);


    const notificationsContent = loading ? null :
        contentError ? <div>error</div>
            : (
                <Notification homeInfo={homeInfo} setHomeInfo={setHomeInfo}/>
            );


    return (
        <div className={"login page"}>
            <Header/>
            <div className={"small-heading"}>NOTIFY</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"} style={{minHeight: '150px'}}>
                    {loading ?
                        <Loading text={"Fetching Content"}/>
                        :
                        contentError ?
                            <div className={"error-loading"}>Error loading notify content</div>
                            :
                            loggedIn ?
                                notificationsContent
                                :
                                <div className={"must-log-in fade-in"} style={{margin: "auto", alignSelf: 'center'}}>
                                <span><a href={"/login"}
                                         className={"click-here"}>Log in</a> to set up notifications</span>
                                </div>
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Notify;