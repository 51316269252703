import {rfAccessToken, rfRefreshToken} from "../constants/Constants";
import {refreshToken} from "./AuthService";
import {properties} from "../properties";

export function fetchNotifications(firstCall) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchNotifications(false).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to fetch content."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/notify/get-notifications", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function saveNotificationCall(firstCall, notification) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        saveNotificationCall(false, notification).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to save notification."));
                }
            }
        };
        xhttp.open("POST", properties.host + "/notify/save-notification", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);
        xhttp.send(JSON.stringify(notification));
    });
}

export function deleteNotificationCall(firstCall, notification) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        deleteNotificationCall(false, notification).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to save notification."));
                }
            }
        };
        xhttp.open("POST", properties.host + "/notify/delete-notification", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);
        xhttp.send(JSON.stringify(notification));
    });
}