import {
    priceDisplay, changeDisplay, askDisplay, bidDisplay, askSizeDisplay,
    bidSizeDisplay, openDisplay, previousCloseDisplay, dayLowDisplay, dayHighDisplay, yearLowDisplay,
    yearHighDisplay, volumeDisplay, averageVolumeDisplay, sma50Display, sma200Display, ema12Display,
    ema26Display, rsiDisplay, aroonDisplay, stochasticDisplay, upperBollingerDisplay, lowerBollingerDisplay,
    macdDisplay, macdSignalDisplay, spreadDisplay, red, green, grey
} from "../constants/Constants";


function Stats(props) {


    return (
        <div className={"stats-dashboard-content"} style={{overflow: 'visible', maxHeight: "max-content"}}
             onClick={() => props.setSuggestions([])}>
            {props.errorSuccessMessage && (
                <div className="success-or-error"
                     style={{color: props.errorSuccessMessage.props.style.color, opacity: props.errorSuccessOpacity}}>
                    {props.errorSuccessMessage}
                </div>
            )}
            {/*<div className={"section-subheading admin-subheading center"}>{props.name}</div>*/}
            {/*{props.searchForm}*/}
            {props.ticker !== null && (

                <table className={"indicators-table fade-in"}>
                    <tbody>
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{changeDisplay}</td>
                        <td className={"indicators-td"}
                            style={{color: props.ticker.change > 0 ? green : props.ticker.change < 0 ? red : grey}}>{props.ticker.changePercentage > 0 ? '+' : ''}
                            {new Intl.NumberFormat('en-US').format(props.ticker.changePercentage.toFixed(2))}%
                            (${Math.abs(props.ticker.change.toFixed(4))})
                        </td>
                    </tr>
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{priceDisplay}</td>
                        <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.price)}</td>
                    </tr>
                    {props.ticker.ask > 0 && props.ticker.bid > 0 &&
                        <>
                            <tr className={"indicators-tr"}>
                                <td className={"indicators-td indicator-left"}>{askDisplay}</td>
                                <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.ask)}</td>
                            </tr>
                            <tr className={"indicators-tr"}>
                                <td className={"indicators-td indicator-left"}>{bidDisplay}</td>
                                <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.bid)}</td>
                            </tr>
                            <tr className={"indicators-tr"}>
                                <td className={"indicators-td indicator-left"}>{spreadDisplay}</td>
                                <td className={"indicators-td"}>
                                    {new Intl.NumberFormat('en-US').format(Math.abs(props.ticker.spreadPercentage.toFixed(2)))}%
                                    (${new Intl.NumberFormat('en-US').format(Math.abs(props.ticker.spread))})
                                </td>
                            </tr>
                        </>
                    }
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{askSizeDisplay}</td>
                        <td className={"indicators-td"}>{props.ticker.askSize}</td>
                    </tr>
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{bidSizeDisplay}</td>
                        <td className={"indicators-td"}>{props.ticker.bidSize}</td>
                    </tr>
                    {props.ticker.openPrice > 0 &&
                        <tr className={"indicators-tr"}>
                            <td className={"indicators-td indicator-left"}>{openDisplay}</td>
                            <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.openPrice)}</td>
                        </tr>
                    }
                    {props.ticker.previousClose > 0 &&
                        <tr className={"indicators-tr"}>
                            <td className={"indicators-td indicator-left"}>{previousCloseDisplay}</td>
                            <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.previousClose)}</td>
                        </tr>
                    }
                    {props.ticker.dayLow > 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{dayLowDisplay}</td>
                        <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.dayLow)}</td>
                    </tr>
                    }
                    {props.ticker.dayHigh > 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{dayHighDisplay}</td>
                        <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.dayHigh)}</td>
                    </tr>
                    }
                    {props.ticker.yearLow > 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{yearLowDisplay}</td>
                        <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.yearLow)}</td>
                    </tr>
                    }
                    {props.ticker.yearHigh > 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{yearHighDisplay}</td>
                        <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.yearHigh)}</td>
                    </tr>
                    }
                    {props.ticker.volume > 0 &&
                        <tr className={"indicators-tr"}>
                            <td className={"indicators-td indicator-left"}>{volumeDisplay}</td>
                            <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.volume)}</td>
                        </tr>
                    }
                    {props.ticker.averageVolume > 0 &&
                        <tr className={"indicators-tr"}>
                            <td className={"indicators-td indicator-left"}>{averageVolumeDisplay}</td>
                            <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.averageVolume)}</td>
                        </tr>
                    }
                    {props.ticker.sma50 > 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{sma50Display}</td>
                        <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.sma50)}</td>
                    </tr>
                    }
                    {props.ticker.sma200 > 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{sma200Display}</td>
                        <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.sma200)}</td>
                    </tr>
                    }
                    {props.ticker.ema12 > 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{ema12Display}</td>
                        <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.ema12)}</td>
                    </tr>
                    }
                    {props.ticker.ema26 > 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{ema26Display}</td>
                        <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.ema26)}</td>
                    </tr>
                    }
                    {props.ticker.macd !== 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{macdDisplay}</td>
                        <td className={"indicators-td"}>{props.ticker.macd}</td>
                    </tr>
                    }
                    {props.ticker.macdSignal !== 0 &&
                        <tr className={"indicators-tr"}>
                            <td className={"indicators-td indicator-left"}>{macdSignalDisplay}</td>
                            <td className={"indicators-td"}>{props.ticker.macdSignal}</td>
                        </tr>
                    }
                    {props.ticker.lowerBollinger !== 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{lowerBollingerDisplay}</td>
                        <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.lowerBollinger)}</td>
                    </tr>
                    }
                    {props.ticker.upperBollinger !== 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{upperBollingerDisplay}</td>
                        <td className={"indicators-td"}>${new Intl.NumberFormat('en-US').format(props.ticker.upperBollinger)}</td>
                    </tr>
                    }
                    {props.ticker.rsi !== 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{rsiDisplay}</td>
                        <td className={"indicators-td"}>{props.ticker.rsi}</td>
                    </tr>
                    }
                    {props.ticker.aroon !== 0 &&
                    <tr className={"indicators-tr"}>
                        <td className={"indicators-td indicator-left"}>{aroonDisplay}</td>
                        <td className={"indicators-td"}>{props.ticker.aroon}</td>
                    </tr>
                    }
                    {props.ticker.stochastic !== 0 &&
                        <tr className={"indicators-tr"}>
                            <td className={"indicators-td indicator-left"}>{stochasticDisplay}</td>
                            <td className={"indicators-td"}>{props.ticker.stochastic}</td>
                        </tr>
                    }
                    </tbody>
                </table>
            )}


        </div>
    )
}

export default Stats;