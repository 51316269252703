import {addWatch, addWatchNoAccount, removeWatch} from "../service/UserService";
import {useEffect, useRef, useState} from "react";
import {green, grey, red} from "../constants/Constants";
import {useNavigate} from "react-router-dom";
import {isUserLoggedIn} from "../service/AuthService";


function WatchList(props) {
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const suggestionRefs = useRef([]);

    const [removingWatch, setRemovingWatch] = useState(false);
    const [addingWatch, setAddingWatch] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [watchListTooBig, setWatchListTooBig] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1); // Step 1: Track the highlighted suggestion index
    const loggedIn = isUserLoggedIn();

    useEffect(() => {
        if (highlightedIndex >= 0 && suggestionRefs.current[highlightedIndex]) {
            suggestionRefs.current[highlightedIndex].scrollIntoView({
                // behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [highlightedIndex]);

    const [watchSymbolToAdd, setWatchSymbolToAdd] = useState("");


    const handleSuggestionClick = (symbol) => {
        setWatchSymbolToAdd(symbol);
        setSuggestions([]);
        inputRef.current.focus();
        setHighlightedIndex(-1); // Reset the highlighted index when input changes
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex(prevIndex => Math.min(prevIndex + 1, suggestions.length - 1));
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (e.key === 'Enter' && highlightedIndex >= 0) {
            e.preventDefault();
            handleSuggestionClick(suggestions[highlightedIndex]);
        }
    };

    const handleClickOutside = (event) => {
        // if (inputRef.current && !inputRef.current.contains(event.target)) {
        //     setSuggestions([]);
        // }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (props.homeInfo != null && props.homeInfo.watchList != null) {
            if (loggedIn) {
                if (props.homeInfo.watchList.length < 10) {
                    setWatchListTooBig(false);
                } else {
                    setWatchListTooBig(true);
                }
            } else {
                if (props.homeInfo.watchList.length < 5) {
                    setWatchListTooBig(false);
                } else {
                    setWatchListTooBig(true);
                }
            }
        } else {
            setWatchListTooBig(false);
        }
    }, [props.homeInfo]);

    const [errorSuccessMessage, setErrorSuccessMessage] = useState(null);
    const [errorSuccessOpacity, setErrorSuccessOpacity] = useState(1);

    function addErrorSuccess(message, color) {
        if (window.innerWidth >= 991 || color === green) {
            setErrorSuccessMessage(
                <div className="success-or-error" style={{color: color, opacity: errorSuccessOpacity}}>
                    {message}
                </div>
            );
            setErrorSuccessOpacity(1);

            setTimeout(() => {
                setErrorSuccessOpacity(0);
            }, 2000);

            setTimeout(() => {
                setErrorSuccessOpacity(1);
                setErrorSuccessMessage(null);
            }, 3000);
        } else {
            window.alert(message);
        }

    }


    function addWatchListEntry(symbol) {
        symbol = symbol.toUpperCase();
        if (props.homeInfo.watchList.some(item => item.symbol === symbol)) {
            addErrorSuccess(symbol + " is already in watch list", red);
            return;
        }
        if (loggedIn) {
            if (props.homeInfo.watchList.length >= 10) {
                addErrorSuccess("Watch list can not be larger than 10", red);
                return;
            }
        } else {
            if (props.homeInfo.watchList.length >= 5) {
                addErrorSuccess("Log in to add more symbols!", red);
                return;
            }
        }

        if (!props.homeInfo.symbols.includes(symbol)) {
            addErrorSuccess(symbol + " is not in symbols list", red);
            return;
        }
        if (loggedIn) {
            try {
                setAddingWatch(true);
                addWatch(true, symbol)
                    .then(response => {
                        if (response.symbol) {
                            props.setHomeInfo((prevState) => {
                                const newEntry = {
                                    symbol: response.symbol,
                                    price: response.price,
                                    change: response.change,
                                    changePercentage: response.changePercentage,
                                    redditMentions: response.redditMentions
                                };
                                return {
                                    ...prevState,
                                    watchList: [...prevState.watchList, newEntry]
                                };
                            });
                        }
                        setAddingWatch(false);
                        setSuggestions([]);
                        setWatchSymbolToAdd("");
                    })
                    .catch(error => {
                        addErrorSuccess("Could not add " + symbol + " to watch list.", red);
                        console.error("Error adding to list:", error);
                        setAddingWatch(false);
                        if (error.status === 403) {
                            navigate("/login");
                        }
                    });
            } catch (error) {
                addErrorSuccess("Could not add " + symbol + " to watch list.", red);
                console.error("Error adding to list:", error);
                setAddingWatch(false);
            }
        } else {
            try {
                setAddingWatch(true);
                addWatchNoAccount(symbol)
                    .then(response => {
                        if (response.symbol) {
                            // Update the watchList with the new symbol entry
                            props.setHomeInfo((prevState) => {
                                const newEntry = {
                                    symbol: response.symbol,
                                    price: response.price,
                                    change: response.change,
                                    changePercentage: response.changePercentage,
                                    redditMentions: response.redditMentions
                                };
                                const updatedWatchList = [...prevState.watchList, newEntry];

                                // Store the updated watchList symbols in localStorage
                                const watchListSymbols = updatedWatchList.map(item => item.symbol);
                                localStorage.setItem("rfWatchListSymbols", JSON.stringify(watchListSymbols));

                                // Return the updated state with the new watchList
                                return {
                                    ...prevState,
                                    watchList: updatedWatchList
                                };
                            });
                        }
                        setAddingWatch(false);
                        setSuggestions([]);
                        setWatchSymbolToAdd("");
                    })
                    .catch(error => {
                        addErrorSuccess("Could not add " + symbol + " to watch list.", red);
                        console.error("Error adding to list:", error);
                        setAddingWatch(false);
                    });
            } catch (error) {
                addErrorSuccess("Could not add " + symbol + " to watch list.", red);
                console.error("Error adding to list:", error);
                setAddingWatch(false);
            }

        }
    }

    const handleInputChange = (e) => {
        const value = e.target.value.toUpperCase();
        setWatchSymbolToAdd(value);
        if (value.length > 0) {
            const filteredSuggestions = props.homeInfo.symbols.filter(symbol => symbol.startsWith(value));
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
        setHighlightedIndex(-1); // Reset the highlighted index when input changes
    };

    const addForm = (
        <form onSubmit={(e) => {
            e.preventDefault();
            addWatchListEntry(document.getElementById("watch-symbol-to-add").value);
        }}>
            {watchListTooBig ?
                <div className={"ticker-input-submit-container center"} style={{marginTop: '1rem'}}>
                    {loggedIn ?
                        <span>Watch list can only hold 10 symbols</span>
                        :
                        <span><a href={"/login"} className={"click-here"}>Log in</a> to add more symbols</span>
                    }
                </div>
                :
                <div className={"ticker-input-submit-container margin-top-half-rem"}>
                    <input id={"watch-symbol-to-add"}
                           required={true}
                           ref={inputRef}
                           maxLength={5}
                           className={"ticker-input"}
                           value={watchSymbolToAdd}
                           placeholder={'Symbol'}
                           onChange={(e) => {
                               setWatchSymbolToAdd(e.target.value);
                               handleInputChange(e)
                           }}
                           onKeyDown={handleKeyDown} // Step 2: Handle keyboard events
                           autoComplete={"off"}
                    />
                    {suggestions.length > 0 && (
                        <div className={"suggestions-wrapper"}>
                            <ul className="suggestions">
                                {suggestions.sort((a, b) => a.localeCompare(b)).map((suggestion, index) => (
                                    <li key={index}
                                        ref={el => suggestionRefs.current[index] = el}
                                        className={`suggestion dashboard-link ${index === highlightedIndex ? 'highlighted' : ''}`}
                                        onClick={() => handleSuggestionClick(suggestion)}>
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <div className={"center"}>
                        <button type={"submit"} className={"filter-add-subtract-button"} disabled={addingWatch}>+
                        </button>
                    </div>
                </div>
            }


        </form>
    );

    function removeWatchListEntry(symbol) {
        symbol = symbol.toUpperCase();
        if (loggedIn) {
            try {
                setRemovingWatch(true);
                removeWatch(true, symbol)
                    .then(response => {
                        if (response === true) {
                            props.setHomeInfo((prevState) => ({
                                ...prevState,
                                watchList: prevState.watchList.filter(item => item.symbol !== symbol)
                            }));
                        } else {
                            addErrorSuccess("Could not remove " + symbol + " from watch list.", red);
                        }
                        setRemovingWatch(false);
                    })
                    .catch(error => {
                        addErrorSuccess("Could not remove " + symbol + " from watch list.", red);
                        console.error("Error removing from list:", error);
                        setRemovingWatch(false);
                        if (error.status === 403) {
                            navigate("/login");
                        }
                    });
            } catch (error) {
                addErrorSuccess("Could not remove " + symbol + " from watch list.", red);
                console.error("Error removing from list:", error);
                setRemovingWatch(false);
            }
        } else {
            props.setHomeInfo((prevState) => {
                const updatedWatchList = prevState.watchList.filter(item => item.symbol !== symbol);

                // Update localStorage with the new list of symbols
                const watchListSymbols = updatedWatchList.map(item => item.symbol);
                localStorage.setItem("rfWatchListSymbols", JSON.stringify(watchListSymbols));

                return {
                    ...prevState,
                    watchList: updatedWatchList
                };
            });
        }
    }

    return (
        <div className={"admin-dashboard-content fade-in"} style={{overflow: 'visible', maxWidth: '1000px'}}
             onClick={() => setSuggestions([])}>
            {errorSuccessMessage && (
                <div className="success-or-error"
                     style={{color: errorSuccessMessage.props.style.color, opacity: errorSuccessOpacity}}>
                    {errorSuccessMessage}
                </div>
            )}
            <div className={"section-subheading admin-subheading center"}>Watch List</div>
            {!props.homeInfo.watchList?.length ?
                <div className={"center"}>Add a symbol to get started
                </div>
                :
                <table className={"dashboard-table"}>
                    <thead>
                    <tr className={"dashboard-tr dashboard-th"}>
                        <th className={"th-width-15-percent"}>Symbol</th>
                        <th className={"th-width-15-percent"}>Change</th>
                        {/*<th className={"th-width-10-percent"}></th>*/}
                        <th className={"th-width-15-percent"}>Price</th>
                        <th className={"th-width-1-percent"}>Mentions(24h)</th>
                        {/*<th className={"th-width-1-percent"}></th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {props.homeInfo.watchList.sort((a, b) => a.symbol.localeCompare(b.symbol))
                        .map((watchList, index) => (
                            <tr key={index} className={"dashboard-tr"}>
                                <td className={"dashboard-td"}>
                                    <div className={"dashboard-td-inner"}><a
                                        href={"/analyze/" + watchList.symbol}
                                        className={"dashboard-link"}
                                        // style={{color: watchList.change > 0 ? green : watchList.change < 0 ? red : grey}}>{watchList.symbol}</a>
                                    >{watchList.symbol}</a>
                                    </div>
                                </td>
                                <td className={"dashboard-td"} style={{paddingRight: '2px'}}>
                                    <div className={"dashboard-td-inner"}><a
                                        href={"/analyze/" + watchList.symbol}
                                        className={"dashboard-link"}
                                        style={{color: watchList.change > 0 ? green : watchList.change < 0 ? red : grey}}>{watchList.changePercentage > 0 ? '+' : ''}{new Intl.NumberFormat('en-US').format(watchList.changePercentage.toFixed(2))}%</a>
                                    </div>
                                </td>
                                {/*<td className={"dashboard-td"} style={{paddingRight: 0}}>*/}
                                {/*    <div className={"dashboard-td-inner"}><a*/}
                                {/*        href={"/analyze/" + watchList.symbol}*/}
                                {/*        className={"dashboard-link"}*/}
                                {/*        style={{color: watchList.change > 0 ? green : watchList.change < 0 ? red : grey}}>{watchList.change !== 0 ? '($' + new Intl.NumberFormat('en-US').format(Math.abs(watchList.change)) + ')' : ''}</a>*/}
                                {/*    </div>*/}
                                {/*</td>*/}
                                <td className={"dashboard-td"} style={{paddingRight: '0'}}>
                                    <div className={"dashboard-td-inner"}><a
                                        href={"/analyze/" + watchList.symbol}
                                        className={"dashboard-link"}
                                        // style={{color: watchList.change > 0 ? green : watchList.change < 0 ? red : grey}}>${new Intl.NumberFormat('en-US').format(watchList.price)}</a>
                                    >${new Intl.NumberFormat('en-US').format(watchList.price)}</a>
                                    </div>
                                </td>
                                <td className={"dashboard-td"} style={{position: 'relative'}}>
                                    <div className={"dashboard-td-inner"}><a
                                        href={"/analyze/" + watchList.symbol + "?content=reddit"}
                                        className={"dashboard-link mention-link"}>{watchList.redditMentions}</a>
                                    </div>
                                    <button className={"watchlist-remove-button"} type={"button"}
                                            onClick={() => removeWatchListEntry(watchList.symbol)}
                                            disabled={removingWatch}>
                                        <div className={"watchlist-remove-button-text dashboard-link"}>x</div>
                                    </button>
                                </td>
                                {/*<td>*/}
                                {/*    <button className={"filter-add-subtract-button"} type={"button"}*/}
                                {/*            onClick={() => removeWatchListEntry(watchList.symbol)}*/}
                                {/*            disabled={removingWatch}>*/}
                                {/*        <div className={"filter-add-subtract-text dashboard-link"}>x</div>*/}
                                {/*    </button>*/}
                                {/*</td>*/}
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
            {addForm}
        </div>
    )
}

export default WatchList;