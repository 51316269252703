export function unixTimestampToDate(timestamp) {
    // Create a new JavaScript Date object based on the timestamp
    // Multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const date = new Date(timestamp * 1000);

    // Convert the date to the desired format (M/D/YY)
    const formattedDate = date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
    });

    // Render the formatted date
    return formattedDate;
}

export function unixTimestampToDateTime(timestamp) {
    // Create a new JavaScript Date object based on the timestamp
    // Multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const date = new Date(timestamp * 1000);

    // Convert the date to the desired format (M/D/YY)
    const formattedDate = date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
        // timeZoneName: 'short'
    });

    // Render the formatted date
    return formattedDate;
}

export function unixTimestampToDateAndTime(timestamp) {
    // Create a new JavaScript Date object based on the timestamp
    // Multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const date = new Date(timestamp * 1000);

    const formattedDateAndTime = date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short'
    });

    // Render the formatted date
    return formattedDateAndTime;
}