import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Loading from "./Loading";
import {
    aroon, aroonDisplay, ask, askDisplay, askSize, askSizeDisplay, averageVolume,
    averageVolumeDisplay, bid, bidDisplay, bidSize, bidSizeDisplay, change, changeDisplay,
    changePercentage, changePercentageDisplay, dayHigh, dayHighDisplay, dayLow, dayLowDisplay,
    ema12, ema12Display, ema26, ema26Display, eq, green, gt, gte, lowerBollinger, lowerBollingerDisplay,
    lt, lte, macd, macdDisplay, macdSignal, macdSignalDisplay, ne, open, openDisplay, previousClose,
    previousCloseDisplay, price, priceDisplay, red, rfAccessToken, rsi, rsiDisplay, sma200, sma200Display,
    sma50, sma50Display, spread, spreadDisplay, spreadPercentage, spreadPercentageDisplay, stochastic,
    stochasticDisplay, upperBollinger, upperBollingerDisplay, volume, volumeDisplay, yearHigh,
    yearHighDisplay, yearLow, yearLowDisplay
} from "../constants/Constants";
import {searchConditionsCall} from "../service/FilterService";

const initialCondition = {
    "id": 0,
    "conditionLeft": price,
    "comparator": eq,
    "conditionRight": price,
    "number": false
};

const initialConditionsList = [initialCondition];

function Notify() {
    const [conditionsList, setConditionsList] = useState(initialConditionsList);
    const [errorSuccessMessage, setErrorSuccessMessage] = useState(null);
    const [errorSuccessOpacity, setErrorSuccessOpacity] = useState(1);
    const [loading, setLoading] = useState(false);
    const [contentError, setContentError] = useState(false);
    const [symbolsList, setSymbolsList] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem(rfAccessToken)) {
            // navigate("/login");
        }
    }, [])


    function addErrorSuccess(message, color) {
        if (window.innerWidth >= 991 || color === green) {
            setErrorSuccessMessage(
                <div className="success-or-error-notifications" style={{color: color, opacity: errorSuccessOpacity}}>
                    {message}
                </div>
            );
            setErrorSuccessOpacity(1);

            setTimeout(() => {
                setErrorSuccessOpacity(0)
            }, 2000);

            setTimeout(() => {
                setErrorSuccessOpacity(1);
                setErrorSuccessMessage(null);
            }, 3000);
        } else {
            window.alert(message);
        }
    }

    function checkIfNumber(input) {
        const num = Number(input);
        return !isNaN(num);
    }

    const blockInvalidChar = (e) => ['e', 'E', '+', checkNeg(e.target.value)].includes(e.key) && e.preventDefault();

    function checkNeg(val) {
        return val.length > 0 ? '-' : '';
    }

    const handleInputChange = (conditionIndex, field, value) => {
        const updatedList = conditionsList.map((condition, cIndex) => {
            if (cIndex === conditionIndex) {
                return {...condition, [field]: value};
            }
            return condition;
        });
        setConditionsList(updatedList);
    };

    const toggleNumber = (conditionIndex) => {
        const updatedList = conditionsList.map((condition, cIndex) => {
            if (cIndex === conditionIndex) {
                return {
                    ...condition,
                    number: !condition.number,
                    conditionRight: condition.number && checkIfNumber(condition.conditionRight) ? price : condition.conditionRight
                };
            }
            return condition;
        });
        setConditionsList(updatedList);
    };

    const addCondition = () => {
        if (conditionsList.length < 10) {
            setConditionsList([...conditionsList, {...initialCondition, id: conditionsList.length}]);
        } else {
            addErrorSuccess("Conditions list can only have 10 conditions.", red);
        }
    };

    const removeCondition = (conditionIndex) => {
        if (conditionsList.length > 1) {
            setConditionsList(conditionsList.filter((_, index) => index !== conditionIndex));
        } else {
            addErrorSuccess("Conditions list must have at least one condition.", red);
        }
    };

    const rightConditions = (condition, conditionIndex) => {
        if (!condition.number) {
            return (
                <div>
                    <select id={"conditionRightNotify" + conditionIndex} className={"filter-dropdown-notification"}
                            defaultValue={condition.conditionRight}
                            onChange={(e) => handleInputChange(conditionIndex, 'conditionRight', e.target.value)}
                    >
                        <option className={"filter-option"} value={price}>{priceDisplay}</option>
                        <option className={"filter-option"} value={change}>{changeDisplay}</option>
                        <option className={"filter-option"} value={changePercentage}>{changePercentageDisplay}</option>
                        <option className={"filter-option"} value={ask}>{askDisplay}</option>
                        <option className={"filter-option"} value={bid}>{bidDisplay}</option>
                        <option className={"filter-option"} value={spread}>{spreadDisplay}</option>
                        <option className={"filter-option"} value={spreadPercentage}>{spreadPercentageDisplay}</option>
                        <option className={"filter-option"} value={askSize}>{askSizeDisplay}</option>
                        <option className={"filter-option"} value={bidSize}>{bidSizeDisplay}</option>
                        <option className={"filter-option"} value={open}>{openDisplay}</option>
                        <option className={"filter-option"} value={previousClose}>{previousCloseDisplay}</option>
                        <option className={"filter-option"} value={dayLow}>{dayLowDisplay}</option>
                        <option className={"filter-option"} value={dayHigh}>{dayHighDisplay}</option>
                        <option className={"filter-option"} value={yearLow}>{yearLowDisplay}</option>
                        <option className={"filter-option"} value={yearHigh}>{yearHighDisplay}</option>
                        <option className={"filter-option"} value={volume}>{volumeDisplay}</option>
                        <option className={"filter-option"} value={averageVolume}>{averageVolumeDisplay}</option>
                        <option className={"filter-option"} value={sma50}>{sma50Display}</option>
                        <option className={"filter-option"} value={sma200}>{sma200Display}</option>
                        <option className={"filter-option"} value={ema12}>{ema12Display}</option>
                        <option className={"filter-option"} value={ema26}>{ema26Display}</option>
                        <option className={"filter-option"} value={upperBollinger}>{upperBollingerDisplay}</option>
                        <option className={"filter-option"} value={lowerBollinger}>{lowerBollingerDisplay}</option>
                        <option className={"filter-option"} value={rsi}>{rsiDisplay}</option>
                        <option className={"filter-option"} value={aroon}>{aroonDisplay}</option>
                        <option className={"filter-option"} value={stochastic}>{stochasticDisplay}</option>
                        <option className={"filter-option"} value={macd}>{macdDisplay}</option>
                        <option className={"filter-option"} value={macdSignal}>{macdSignalDisplay}</option>
                    </select>
                </div>
            );
        } else {
            return (
                <input
                    type={"number"}
                    pattern={'0-9.-'}
                    step={"any"}
                    id={"conditionRightNotify" + conditionIndex}
                    className={"filter-number"}
                    placeholder={'Number'}
                    onInput={(e) => {
                        if (e.target.value.length > 12) e.target.value = e.target.value.slice(0, 12);
                        // Force reflow fix
                        const inputElement = e.target;
                        inputElement.style.display = 'none';
                        // eslint-disable-next-line no-unused-expressions
                        inputElement.offsetHeight; // Force reflow
                        inputElement.style.display = '';
                    }}
                    onKeyDown={blockInvalidChar}
                    value={condition.conditionRight}
                    onChange={(e) => handleInputChange(conditionIndex, 'conditionRight', e.target.value)}
                    autoComplete={"off"}
                />
            );
        }
    };

    const conditionTable = (condition, conditionIndex) => (
        <table className={"filter-table"} key={conditionIndex}>
            <tbody>
            <tr>
                <td>
                    <select id={"conditionLeftNotify" + conditionIndex} className={"filter-dropdown-notification"}
                            defaultValue={condition.conditionLeft}
                            onChange={(e) => handleInputChange(conditionIndex, 'conditionLeft', e.target.value)}
                    >
                        <option className={"filter-option"} value={price}>{priceDisplay}</option>
                        <option className={"filter-option"} value={change}>{changeDisplay}</option>
                        <option className={"filter-option"} value={changePercentage}>{changePercentageDisplay}</option>
                        <option className={"filter-option"} value={ask}>{askDisplay}</option>
                        <option className={"filter-option"} value={bid}>{bidDisplay}</option>
                        <option className={"filter-option"} value={spread}>{spreadDisplay}</option>
                        <option className={"filter-option"} value={spreadPercentage}>{spreadPercentageDisplay}</option>
                        <option className={"filter-option"} value={askSize}>{askSizeDisplay}</option>
                        <option className={"filter-option"} value={bidSize}>{bidSizeDisplay}</option>
                        <option className={"filter-option"} value={open}>{openDisplay}</option>
                        <option className={"filter-option"} value={previousClose}>{previousCloseDisplay}</option>
                        <option className={"filter-option"} value={dayLow}>{dayLowDisplay}</option>
                        <option className={"filter-option"} value={dayHigh}>{dayHighDisplay}</option>
                        <option className={"filter-option"} value={yearLow}>{yearLowDisplay}</option>
                        <option className={"filter-option"} value={yearHigh}>{yearHighDisplay}</option>
                        <option className={"filter-option"} value={volume}>{volumeDisplay}</option>
                        <option className={"filter-option"} value={averageVolume}>{averageVolumeDisplay}</option>
                        <option className={"filter-option"} value={sma50}>{sma50Display}</option>
                        <option className={"filter-option"} value={sma200}>{sma200Display}</option>
                        <option className={"filter-option"} value={ema12}>{ema12Display}</option>
                        <option className={"filter-option"} value={ema26}>{ema26Display}</option>
                        <option className={"filter-option"} value={upperBollinger}>{upperBollingerDisplay}</option>
                        <option className={"filter-option"} value={lowerBollinger}>{lowerBollingerDisplay}</option>
                        <option className={"filter-option"} value={rsi}>{rsiDisplay}</option>
                        <option className={"filter-option"} value={aroon}>{aroonDisplay}</option>
                        <option className={"filter-option"} value={stochastic}>{stochasticDisplay}</option>
                        <option className={"filter-option"} value={macd}>{macdDisplay}</option>
                        <option className={"filter-option"} value={macdSignal}>{macdSignalDisplay}</option>
                    </select>
                </td>
                <td>
                    <select id={"notifyComparator" + conditionIndex}
                            className={"filter-dropdown filter-comparator-dropdown"}
                            defaultValue={condition.comparator}
                            onChange={(e) => handleInputChange(conditionIndex, 'comparator', e.target.value)}
                    >
                        <option className={"filter-option"} value={eq}>{eq}</option>
                        <option className={"filter-option"} value={ne}>{ne}</option>
                        <option className={"filter-option"} value={lt}>{lt}</option>
                        <option className={"filter-option"} value={lte}>{lte}</option>
                        <option className={"filter-option"} value={gt}>{gt}</option>
                        <option className={"filter-option"} value={gte}>{gte}</option>
                    </select>
                </td>
                <td>
                    {rightConditions(condition, conditionIndex)}
                </td>
                <td style={{display: "inline"}}>
                    <button type={"button"}
                            className={"filter-number-button-notify"}
                            style={{marginTop: '1px'}}
                            onClick={() => toggleNumber(conditionIndex)}
                    >
                        <div id={"filter-number-button-text"}>#</div>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    );

    const conditionsAreValid = () => {
        for (const condition of conditionsList) {
            if (!condition.number) {
                if (condition.conditionLeft === condition.conditionRight) {
                    addErrorSuccess(condition.conditionLeft + " " + condition.comparator + " " + condition.conditionRight + " is not a valid comparison", red);
                    return false;
                }
            }
        }
        return true;
    };

    const searchConditions = () => {
        try {
            if (!conditionsAreValid()) {
                return;
            }
            setLoading(true);
            setSymbolsList(null);
            searchConditionsCall(true, conditionsList.map(({id, number, ...rest}) => rest))
                .then(response => {
                    if (response) {
                        if (response.length < 1) {
                            addErrorSuccess("No results found", red);
                        } else {
                            addErrorSuccess(response.length + " results found!", green);
                            setSymbolsList(response);
                        }
                    }
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    addErrorSuccess("Could not search", red);
                    if (error.status === 403) {
                        // navigate("/login");
                    }
                });

        } catch (error) {
            setLoading(false);
            addErrorSuccess("Could not search", red);
            console.error("Error saving notification:", error);
        }
    };

    const handleSaveNotification = (e) => {
        e.preventDefault();
        searchConditions();
    };

    const filterContent = (
        <div className={"admin-dashboard-content fade-in"} style={{overflow: 'visible'}}>
            {errorSuccessMessage && (
                <div className="success-or-error-notifications"
                     style={{color: errorSuccessMessage.props.style.color, opacity: errorSuccessOpacity}}>
                    {errorSuccessMessage.props.children}
                </div>
            )}
            <div className={"section-subheading admin-subheading center"} style={{width: "100%", marginTop: 0}}>Conditions</div>
            <form onSubmit={handleSaveNotification}>
                {conditionsList.map((condition, index) => (
                    conditionTable(condition, index)
                ))}
                <div className="notification-condition-buttons" style={{marginTop: '.5rem'}}>
                    <div>
                        <button className={"filter-add-subtract-button"} type={"button"} onClick={addCondition}>
                            <div className={"filter-add-subtract-text dashboard-link"}>+</div>
                        </button>
                        <button className={"filter-add-subtract-button"} type={"button"}
                                onClick={() => removeCondition(conditionsList.length - 1)}>
                            <div className={"filter-add-subtract-text dashboard-link"}>-</div>
                        </button>
                    </div>
                    <button className={"notification-save"} type={"submit"}
                            style={{marginRight: '2px', marginTop: '.5rem'}}>
                        Search
                    </button>
                </div>
            </form>
            {symbolsList && symbolsList.length > 0 ?
                <div className={"max-height-600-400 conditions-symbols"}>
                    {symbolsList?.map((symbol, index) => (
                        <div key={index} className={"conditions-symbol"}>
                            <a className={"dashboard-link"}
                               href={`/analyze/${symbol}`}>
                                {symbol}
                            </a>

                        </div>
                    ))}
                </div>
                :
                <div className={"nothing-here"}>Results will appear here</div>
            }
        </div>
    );

    return (
        <div className={"login page"}>
            <Header/>
            <div className={"small-heading"}>FILTER</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"} style={{minHeight: '222px'}}>
                    {/*<Loading/>*/}
                    {loading ?
                        <Loading/>
                        :
                        filterContent
                    }
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Notify;
