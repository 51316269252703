import React from 'react';

const Error = () => {
    return (
        <div className={"error page"}>
            <div className={"small-heading"}>404 :(</div>
            <span>It's not you, it's us. Click </span>
            <a href={"/"} className={"click-here"}>here</a>
            <span> to go to the home page</span>
        </div>
    );
};

export default Error;
