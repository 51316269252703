import smallLogo from '../images/LogoSmall.png'
import rfSmallLogo from '../images/RFLogoSmall2.png'

function Loading(props) {

    const loading = "Loading"
    return (
        <div className={"loading"}>
            {/*<img src={smallLogo} alt={"small-logo"} className={"loading-logo"}/>*/}
            <img src={rfSmallLogo} alt={"small-logo"} className={"loading-logo"}/>

            {/*<div className={"image-container"}>*/}
            {/*<img src={rfSmallLogo} alt={"small-logo"} className={"rf-loading-logo"}/>*/}
            {/*</div>*/}

            {/*<span className={"loading-text"}>{props.text ? props.text : loading}</span>*/}
            {/*<span className={"loading-dots"}>*/}
            {/*<div></div>*/}
            {/*<div></div>*/}
            {/*<div></div>*/}
            {/*</span>*/}
        </div>
    )
}

export default Loading;