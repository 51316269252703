import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useRef, useState} from "react";
import Loading from "./Loading";
import {useNavigate, useSearchParams} from "react-router-dom";
import {red, green, rfAccessToken} from "../constants/Constants";
import {
    fetchAnalyzeContent,
    fetchAnalyzeContentNoAccount,
    fetchSymbols,
    fetchSymbolsNoAccount
} from "../service/AnalyzeService";
import Stats from "./Stats";
import Charts from "./Charts";
import AnalyzeReddit from "./AnalyzeReddit";
import AnalyzeCongress from "./AnalyzeCongress";
import {isUserLoggedIn, logout} from "../service/AuthService";

const border = 'solid 2px #b619ff';
const none = 'none';


function Analyze() {
    const navigate = useNavigate();

    const [toggle, setToggle] = useState(true);
    const [loading, setLoading] = useState(true);
    const [contentError, setContentError] = useState(false);
    const [analyzeInfo, setAnalyzeInfo] = useState(null);

    const path = window.location.pathname.split("/");
    const symbol = path[path.length - 1] === "analyze" ? null : path[path.length - 1].toUpperCase().split('?')[0];
    const [searchParams, setSearchParams] = useSearchParams();
    const loggedIn = isUserLoggedIn();


    const content = searchParams.get('content');
    // const [heading, setHeading] = useState(symbol === null ? 'Analyze' : symbol);
    const inputRef = useRef(null);
    const suggestionRefs = useRef([]);


    const [suggestions, setSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1); // Step 1: Track the highlighted suggestion index
    const name = !analyzeInfo || analyzeInfo.name === null || analyzeInfo.ticker === null ? "Search a Symbol" : analyzeInfo.name;
    const ticker = !analyzeInfo || analyzeInfo.ticker;

    useEffect(() => {
        if (highlightedIndex >= 0 && suggestionRefs.current[highlightedIndex]) {
            suggestionRefs.current[highlightedIndex].scrollIntoView({
                // behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [highlightedIndex]);


    const [symbolInput, setSymbolInput] = useState("");


    const handleSuggestionClick = (symbol) => {
        setSymbolInput(symbol);
        setSuggestions([]);
        inputRef.current.focus();
        setHighlightedIndex(-1); // Reset the highlighted index when input changes
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex(prevIndex => Math.min(prevIndex + 1, suggestions.length - 1));
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (e.key === 'Enter' && highlightedIndex >= 0) {
            e.preventDefault();
            handleSuggestionClick(suggestions[highlightedIndex]);
        }
    };


    const [errorSuccessMessage, setErrorSuccessMessage] = useState(null);
    const [errorSuccessOpacity, setErrorSuccessOpacity] = useState(1);

    function addErrorSuccess(message, color) {
        if (window.innerWidth >= 991 || color === green) {
            setErrorSuccessMessage(
                <div className="success-or-error-analyze" style={{color: color, opacity: errorSuccessOpacity}}>
                    {message}
                </div>
            );
            setErrorSuccessOpacity(1);

            setTimeout(() => {
                setErrorSuccessOpacity(0);
            }, 2000);

            setTimeout(() => {
                setErrorSuccessOpacity(1);
                setErrorSuccessMessage(null);
            }, 3000);
        } else {
            window.alert(message);
        }


    }

    useEffect(() => {
        if (analyzeInfo && analyzeInfo.search === true && analyzeInfo.ticker === null) {
            addErrorSuccess("Could not find " + symbol, red)
        }
    }, []);


    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);


    const handleInputChange = (e) => {
        const value = e.target.value.toUpperCase();
        setSymbolInput(value);
        if (value.length > 0) {
            const filteredSuggestions = analyzeInfo.symbols.filter(symbol => symbol.startsWith(value));
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
        setHighlightedIndex(-1); // Reset the highlighted index when input changes
    };

    function analyzeSymbol(symbol) {
        symbol = symbol.toUpperCase();

        if (!analyzeInfo.symbols.includes(symbol)) {
            addErrorSuccess(symbol + " is not in symbols list", red);
            return;
        }

        navigate("/analyze/" + symbol + "?content=" + content);
        setToggle(!toggle);
        setSuggestions([]);
    }

    const searchForm = (
        <form style={{margin: "auto"}}
              onSubmit={(e) => {
                  e.preventDefault();
                  analyzeSymbol(document.getElementById("symbol-to-search").value);
              }}>

            <div className={"ticker-input-submit-container"}>
                <input id={"symbol-to-search"}
                       required={true}
                       ref={inputRef}
                       maxLength={5}
                       className={"ticker-input"}
                       value={symbolInput}
                       placeholder={'Symbol'}
                       onChange={(e) => {
                           setSymbolInput(e.target.value);
                           handleInputChange(e)
                       }}
                       onKeyDown={handleKeyDown} // Step 2: Handle keyboard events
                       autoComplete={"off"}
                />
                {suggestions.length > 0 && (
                    <div className={"suggestions-wrapper"}>
                        <ul className="suggestions">
                            {suggestions.sort((a, b) => a.localeCompare(b)).map((suggestion, index) => (
                                <li key={index}
                                    ref={el => suggestionRefs.current[index] = el}
                                    className={`suggestion dashboard-link ${index === highlightedIndex ? 'highlighted' : ''}`}
                                    onClick={() => handleSuggestionClick(suggestion)}>
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className={"center"}>
                    <button type={"submit"} className={"search"}>Analyze</button>
                </div>
            </div>


        </form>
    );


    useEffect(() => {
        if (content === null) {
            setSearchParams({content: 'charts'})
        }
        if (!localStorage.getItem(rfAccessToken)) {
            // navigate("/login");
        }
        if (loggedIn) {
            try {
                setContentError(false);
                setLoading(true);
                fetchAnalyzeContent(true, symbol)
                    .then(response => {
                        setAnalyzeInfo(response);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Error loading analyze content:", error);
                        fetchSymbols(true).then(response => {
                            setAnalyzeInfo({
                                congressTrades: null,
                                fiveDayStockPoints: null,
                                name: null,
                                redditComments: null,
                                search: false,
                                stockPoints: null,
                                symbols: response
                            })
                        })
                        setContentError(true);
                        setLoading(false);
                        if (error.status === 403) {
                            navigate("/login");
                        }
                    });
            } catch (error) {
                console.error("Error loading analyze content:", error);
                setContentError(true);
                setLoading(false);
                if (error.status === 403) {
                    navigate("/login");
                }
            }
        } else {
            try {
                setContentError(false);
                setLoading(true);
                fetchAnalyzeContentNoAccount(symbol)
                    .then(response => {
                        setAnalyzeInfo(response);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Error loading analyze content:", error);
                        fetchSymbolsNoAccount().then(response => {
                            setAnalyzeInfo({
                                congressTrades: null,
                                fiveDayStockPoints: null,
                                name: null,
                                redditComments: null,
                                search: false,
                                stockPoints: null,
                                symbols: response
                            })
                        })
                        setContentError(true);
                        setLoading(false);
                    });
            } catch (error) {
                console.error("Error loading analyze content:", error);
                setContentError(true);
                setLoading(false);
            }
        }
    }, [toggle]);

    const statsContent = loading ? null :
        contentError ? <div>error</div>
            : (
                <Stats analyzeInfo={analyzeInfo}
                       setAnalyzeInfo={setAnalyzeInfo}
                       symbol={symbol}
                       toggle={toggle}
                       setToggle={setToggle}
                       searchForm={searchForm}
                       setSuggestions={setSuggestions}
                       errorSuccessMessage={errorSuccessMessage}
                       errorSuccessOpacity={errorSuccessOpacity}
                       ticker={ticker}
                       name={name}
                />
            );

    const chartsContent = loading ? null :
        contentError ? <div>error</div>
            : (
                <Charts analyzeInfo={analyzeInfo}
                        setAnalyzeInfo={setAnalyzeInfo}
                        symbol={symbol}
                        toggle={toggle}
                        setToggle={setToggle}
                        searchForm={searchForm}
                        setSuggestions={setSuggestions}
                        errorSuccessMessage={errorSuccessMessage}
                        errorSuccessOpacity={errorSuccessOpacity}
                        ticker={ticker}
                        name={name}
                />
            );

    const redditContent = loading ? null :
        contentError ? <div>error</div>
            : (
                <AnalyzeReddit analyzeInfo={analyzeInfo}
                               setAnalyzeInfo={setAnalyzeInfo}
                               symbol={symbol}
                               toggle={toggle}
                               setToggle={setToggle}
                               searchForm={searchForm}
                               setSuggestions={setSuggestions}
                               errorSuccessMessage={errorSuccessMessage}
                               errorSuccessOpacity={errorSuccessOpacity}
                               ticker={ticker}
                               name={name}
                />
            );

    const congressContent = loading ? null :
        contentError ? <div>error</div>
            : (
                loggedIn ?
                <AnalyzeCongress analyzeInfo={analyzeInfo}
                                 setAnalyzeInfo={setAnalyzeInfo}
                                 symbol={symbol}
                                 toggle={toggle}
                                 setToggle={setToggle}
                                 searchForm={searchForm}
                                 setSuggestions={setSuggestions}
                                 errorSuccessMessage={errorSuccessMessage}
                                 errorSuccessOpacity={errorSuccessOpacity}
                                 ticker={ticker}
                                 name={name}
                />
                    :
                    <div className={"must-log-in fade-in"}><span><a href={"/login"} className={"click-here"}>Log in</a> to view congress activity</span></div>
            );

    // const [content, setContent] = useState(statsContent);

    const [chartsBorder, setChartsBorder] = useState(content !== 'stats'
    && content !== 'reddit'
    && content !== 'twitter'
    && content !== 'congress'
    && content !== 'news'
    && content !== 'comments'
        ? border : none);
    const [statsBorder, setStatsBorder] = useState(content === 'stats' ? border : none);
    const [redditBorder, setRedditBorder] = useState(content === 'reddit' ? border : none);
    const [congressBorder, setCongressBorder] = useState(content === 'congress' ? border : none);
    const [contentEntryText, setContentEntryText] = useState(content);

    function handleContentEntryClick(entry) {
        if (entry === 'stats') {
            setSearchParams({content: 'stats'})
            setStatsBorder(border);
            setChartsBorder(none);
            setRedditBorder(none);
            setCongressBorder(none);
            setContentEntryText('stats');
        } else if (entry === 'reddit') {
            setSearchParams({content: 'reddit'})
            setStatsBorder(none);
            setChartsBorder(none);
            setRedditBorder(border);
            setCongressBorder(none);
            setContentEntryText('reddit');
        } else if (entry === 'congress') {
            setSearchParams({content: 'congress'})
            setStatsBorder(none);
            setChartsBorder(none);
            setRedditBorder(none);
            setCongressBorder(border);
            setContentEntryText('congress');
        } else {
            setSearchParams({content: 'charts'})
            setStatsBorder(none);
            setChartsBorder(border);
            setRedditBorder(none);
            setCongressBorder(none);
            setContentEntryText('charts');
        }

    }

    const contentBody = (
        <>
            <div className={"section-subheading admin-subheading fade-in"}
                 style={{width: "100%", marginTop: 0}}>{name}</div>
            {/*<div className={"analyze-price admin-subheading fade-in"} style={{width: "100%"}}>{ticker === null || ticker.price === null ? null : '$' + new Intl.NumberFormat('en-US').format(ticker?.price?.toFixed(2))}</div>*/}
            {searchForm}
            <div className={"reddit-dashboard-entries"}>
                <div className={"analyze-dashboard-entry dashboard-link"}
                     onClick={() => handleContentEntryClick('charts')}
                     style={{border: chartsBorder}}>Charts
                </div>
                <div className={"analyze-dashboard-entry dashboard-link"}
                     onClick={() => handleContentEntryClick('stats')}
                     style={{border: statsBorder}}>Stats
                </div>
                <div className={"analyze-dashboard-entry dashboard-link"}
                     onClick={() => handleContentEntryClick('reddit')}
                     style={{border: redditBorder}}>Reddit
                </div>
                <div className={"analyze-dashboard-entry dashboard-link"}
                     onClick={() => handleContentEntryClick('congress')}
                     style={{border: congressBorder}}>Congress
                </div>
            </div>
            {
                contentError ?
                    <div className={"error-loading"}>Error loading analyze content</div>
                    :
                    contentEntryText === 'stats' ?
                        statsContent
                        :
                        contentEntryText === 'reddit' ?
                            redditContent
                            :
                            contentEntryText === 'congress' ?
                                congressContent
                                :
                                chartsContent

            }
        </>
    );

    return (
        <div className={"login page"}
             onClick={() => setSuggestions([])}>
            <Header home={false}/>
            {symbol === null || symbol === "" ?
                <div className={"small-heading"}>ANALYZE</div>
                :
                <div className={"small-heading"}>{symbol}</div>

            }
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"} style={{minHeight: '220px'}}>
                    {loading ?
                        <Loading text={"Fetching Content"}/>
                        :
                        contentBody
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Analyze;