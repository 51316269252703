import Header from "./Header";
import Footer from "./Footer";

function Terms() {

    return (
        <div className={"login page terms"}>
            <Header home={false}/>
            <div className={"small-heading"}>Terms & Conditions</div>
            <div className={"content"}>
                <div className={"terms-heading"}>TERMS OF SERVICE</div>
                <p className={"terms-paragraph"}>Updated July 2024</p>

                <p className={"terms-paragraph"}>Welcome to https://rexiusfinance.com! These Terms of Service (“Terms”)
                    create a legal Agreement (this “Agreement”) between Rexius Algorithms LLC and the user of the
                    Service entering into this Agreement (“you” or “your”). Rexius Algorithms LLC’s website,
                    https://rexiusfinance.com is available by desktop or mobile web (the “Site”) and controlled by
                    Rexius Algorithms LLC (“we,” “us” or “Rexius Algorithms LLC”). This Agreement governs your use of
                    the Site and the services, features, products and information available on the Site, along with
                    associated and successor websites, applications, features, information, and services, or any part
                    thereof (the “Service”).</p>

                <p className={"terms-paragraph"}>The Site is provided as a service to our customers. Your use of the
                    Site is governed by these Terms. By using the Site, you agree to be bound by these Terms. We reserve
                    the right to update or modify these Terms at any time without prior notice. For this reason, we
                    encourage you to review the Terms whenever you use or access the Site. If you do not agree to these
                    Terms, please do not use or access the Site. Your use of the Site constitutes your agreement to
                    follow and be bound by these Terms.</p>

                <p className={"terms-paragraph"}>By accepting these Terms and Conditions through your use of the
                    website, you certify that you are 18 years of age or older. If you are under the age of 18 but at
                    least 13 years of age, you may use this website only under the supervision of a parent or legal
                    guardian who agrees to be bound by these terms and conditions.</p>

                <div className={"terms-heading"}>Changes to this Agreement</div>

                <p className={"terms-paragraph"}>We reserve the right, in its sole discretion, to change, modify, add
                    to, supplement or delete any terms and conditions of this Agreement (including the <a
                        href={"/privacy"} className={"click-here"}>Privacy Policy</a>,
                    which is incorporated into and governed by this Agreement) at any time; provided, however, that
                    Rexius Algorithms LLC will use reasonable efforts to provide you with notification of any material
                    changes (as determined in Rexius Algorithms LLC’s sole discretion) by email, postal mail, website
                    posting, pop-up screen, or in-Service notice. You should visit this page whenever you use the
                    Service to review this Agreement and learn if any terms have changed. If any future changes to this
                    Agreement are unacceptable to you or cause you to no longer be in compliance with this Agreement,
                    you must immediately stop using the Service. Your continued use of the Service following any
                    revision to this Agreement constitutes your complete acceptance of any and all such changes.</p>

                <div className={"terms-heading"}>Content Permission & Restrictions</div>

                <p className={"terms-paragraph"}>Unless otherwise noted, the design of the Site, including the software,
                    source code, text, images and all other content and materials that are part of the Site
                    (collectively, “Content”) is copyrighted, trademarked, trade dressed, or other intellectual property
                    owned, controlled, or licensed by us. The Content is intended solely for your personal and
                    non-commercial use. No right, title or interest in any Content is granted or transferred to you as a
                    result of your use of the Site.</p>

                <p className={"terms-paragraph"}>Certain parts of the Site may allow you to share Content with your
                    connections on social media platforms. These features grant you a limited license to display the
                    Content as directed, and you understand that you have no other right, title, or interest in or to
                    the Content.</p>

                <p className={"terms-paragraph"}>Except as noted herein, you may not reproduce, publish, transmit,
                    distribute, display, modify, create derivative works from, sell or participate in any sale of, or
                    exploit in any way, any of the Content or the Site. Your misuse of the Content is strictly
                    prohibited and may subject you to liability under federal, state, or international laws.</p>

                <div className={"terms-heading"}>Ownership of Intellectual Property</div>

                <p className={"terms-paragraph"}>Unless otherwise specified in writing, all Content and other materials
                    that are part of the Service are owned, controlled, or licensed by Rexius Algorithms LLC and its
                    licensors and are protected by law from unauthorized use. The entire Contents of the Service are
                    copyrighted under the U.S. copyright laws and/or similar laws of other jurisdictions. Rexius
                    Algorithms LLC, and the Rexius Algorithms LLC logos, are trademarks of Rexius Algorithms LLC and may
                    not be used without the express written permission of Rexius Algorithms LLC.</p>

                <p className={"terms-paragraph"}>You do not acquire any ownership rights by using the Service, or by
                    copying or downloading material from the Service.</p>

                <p className={"terms-paragraph"}>You agree not to copy, redistribute, publish or otherwise exploit
                    Content, except as expressly permitted herein, without the express prior written permission of
                    Rexius Algorithms LLC.</p>

                <p className={"terms-paragraph"}>You hereby grant to Rexius Algorithms LLC a limited, non-exclusive,
                    worldwide, perpetual, irrevocable, royalty-free, sublicensable (through multiple tiers), and
                    transferable right and license to use all comments, feedback, blog or forum statements, suggestions,
                    ideas, emails, and other submissions disclosed or submitted to Rexius Algorithms LLC in connection
                    with your use of the Service, including through the Public Areas (collectively, “Submissions”) in
                    any manner Rexius Algorithms LLC may desire, including, but not limited to, to make, have made, use,
                    sell, offer to sell, import, reproduce, modify, create derivative versions of, distribute, publicly
                    display and publicly perform such Submissions, in any and all forms and media now known or hereafter
                    devised, without compensation to you and without identifying you as the creator. You agree that the
                    provisions in this section will survive any termination of your account(s), the Service, or this
                    Agreement.</p>

                <p className={"terms-paragraph"}>You agree to include, and to not remove or alter, Rexius Algorithms
                    LLC’s trademark, copyright, or other proprietary rights notices, as provided by Rexius Algorithms
                    LLC on or in connection with the Service, when using or sharing content, or otherwise using the
                    Service, and you agree to comply with usage guidelines that may be provided by Rexius Algorithms LLC
                    from time to time. You agree that all goodwill that arises in connection with your use of Rexius
                    Algorithms LLC trademarks inure exclusively to Rexius Algorithms LLC, and you agree not to challenge
                    Rexius Algorithms LLC’s ownership or control of any Rexius Algorithms LLC trademarks, nor use or
                    adopt any trademarks that might be confusingly similar to such Rexius Algorithms LLC trademarks.</p>

                <div className={"terms-heading"}>Accuracy of Information on the Site</div>

                <p className={"terms-paragraph"}>We do our best to ensure that information on the Site is complete,
                    accurate and current. Despite our efforts, however, information on the Site may occasionally be
                    inaccurate, incomplete, or out of date. All specifications and descriptions on the Site are subject
                    to change at any time without notice. We do not warrant the accuracy or completeness of the
                    information, content or materials provided through the Site.</p>

                <div className={"terms-heading"}>Registration, Accounts and Passwords</div>

                <p className={"terms-paragraph"}>In order to access certain Services, you must apply and register for an
                    account for the Services (“User Account”). When you register for a User Account, you will be issued
                    login credentials (“Credentials”), such as a password, which will be assigned specifically to you.
                    Rexius Algorithms LLC, in its sole discretion, may deny User Account registration to you for any or
                    no reason. When registering, you will be asked to provide your email, first name, last name, and
                    password.</p>

                <p className={"terms-paragraph"}>You are responsible for the personal protection and security of any
                    password or username that you may use to access the Site. You are responsible for all activity
                    conducted on the Site that can be linked or traced back to your username or password. You are
                    obligated to immediately report a lost or stolen password or username to our Customer Service.</p>

                <p className={"terms-paragraph"}>If you believe someone has used your password or account without your
                    authorization, you must notify Customer Service immediately. We reserve the right to access and
                    disclose any information including, without limitation, usernames of accounts and other information
                    to comply with applicable laws and lawful government requests.</p>

                <div className={"terms-heading"}>Email Communication</div>

                <p className={"terms-paragraph"}>By subscribing on the Site or otherwise creating an account on the
                    Site, you understand that we may send you communications or data regarding our products and
                    services. You agree to receive such communications from us. Unless such email is necessary to
                    facilitate a transaction or verification, we will give you the opportunity to opt-out of receiving
                    these commercial emails from us by following the opt-out instructions provided in such
                    message(s).</p>

                <div className={"terms-heading"}>User Comments</div>

                <p className={"terms-paragraph"}>From time to time, we may allow users to post comments, suggestions,
                    ideas, materials, and other submissions ("User Comments") on the Site. These User Comments are
                    provided on a non-confidential basis and, by submitting User Comments, you are granting us an
                    irrevocable and unrestricted license to fully exploit such User Comments. You agree that no User
                    Comments submitted by you to the Site will violate any right of any third party, including
                    copyright, trademark, privacy or other personal or proprietary rights. You further agree that no
                    User Comments submitted by you to the Site will be or contain libelous or otherwise unlawful,
                    abusive, or obscene material. You are and shall remain solely responsible for the content of any
                    User Comments you make.</p>

                <p className={"terms-paragraph"}>Although User Comments may be posted on the Site, the posting of those
                    submissions does not constitute our endorsement of such User Comments. We are not responsible or
                    liable for any claim, including, without limitation, loss, or injury to real, tangible, or
                    intellectual property, violations of personal privacy or privacy rights, actual, consequential, or
                    punitive damages, personal injury, or wrongful death made in connection with User Comments. We
                    reserve the right to remove any User Comments that we deem to be in violation of the above.</p>

                <div className={"terms-heading"}>Cookies</div>

                <p className={"terms-paragraph"}>Rexius Algorithms LLC takes certain industry-accepted precautions to
                    secure the Site or portions thereof. However, you understand and agree that such precautions cannot
                    guarantee that use of the Site is invulnerable to security breaches, nor does Rexius Algorithms LLC
                    make any warranty, guarantee, or representation that use of the Site is protected from all viruses,
                    worms, Trojan horses, and other vulnerabilities.</p>

                <p className={"terms-paragraph"}>During your use of the Site, Rexius Algorithms LLC may issue to and
                    request from your computer "cookies" to enable more convenient browsing when you revisit the Site.
                    Additionally, the Site may use Local Storage in your browser to store access tokens and refresh
                    tokens. You must not alter any cookies or data in Local Storage sent to your computer from the Site
                    and must ensure that your computer sends correct and accurate data in response to any relevant
                    request from the Site.</p>

                <div className={"terms-heading"}>Links to Third Party Site</div>

                <p className={"terms-paragraph"}>The Site may include links to other websites maintained by third
                    parties. These websites are neither maintained nor controlled by Rexius Algorithms LLC and may
                    contain content posted on or via the Site by third parties. Rexius Algorithms LLC shall not be
                    responsible for any errors or omissions in any Content on the Site, or the content, products or
                    services of any hyperlinked external Site or any hyperlink contained in a hyperlinked external Site,
                    nor for the privacy and security practices employed by these external Sites, and under no
                    circumstances shall Rexius Algorithms LLC be liable for any loss or damage of any kind incurred as a
                    result of the use of any content posted or contained in emails or otherwise transmitted or displayed
                    via the Site, or arising from access to external Sites. Use of the Site and any hyperlinks and
                    access to external Sites are entirely at the user’s own risk.</p>

                <p className={"terms-paragraph"}>You acknowledge that Rexius Algorithms LLC has no control over and
                    excludes all liability for any material on the Internet which can be accessed by using the Site.
                    Neither will Rexius Algorithms LLC be deemed to have endorsed any such content thereto.</p>

                <div className={"terms-heading"}>Prohibited Uses of Site</div>

                <p className={"terms-paragraph"}>You understand and agree not to (i) post, transmit, redistribute,
                    upload, or promote any communications or content that could harm or negatively impact our business,
                    products or services; (ii) act in a manner or employ any device that restricts, impairs, interferes
                    or inhibits any other user from using or enjoying the Site, or which impacts the security of the
                    Site; (iii) employ any device or attempt to use any engine, software, tool, agent or other device or
                    mechanism (including without limitation spiders, bots, crawlers, avatars or intelligent agents) to
                    navigate or search the Site, or to copy content from the Site; (iv) remove or destroy any copyright
                    notices or other proprietary markings contained on or in the Site or use the Site in violation of
                    any third party’s intellectual property or other proprietary or legal rights; (v) use the Services
                    in violation of any applicable law; (vi) attempt to gain unauthorized access to other computer
                    systems through the Site; (vii) interfere with or attempt to interfere with the proper functioning
                    of the Site or use the Site in any way not expressly permitted by this Terms; and (ix) attempt to
                    harm our Site or use the Site in a manner that could interfere with any party’s use or enjoyment of
                    the Site, including but not limited to, by violating or attempting to violate any related security
                    features, introducing viruses, worms, or similar harmful code into the Site, or interfering or
                    attempting to interfere with use of the Site by any other user, host, or network, including by means
                    of overloading, "flooding," "spamming," "mail bombing," or "crashing" the Site.</p>

                <div className={"terms-heading"}>Disclaimer of Warranty</div>

                <p className={"terms-paragraph"}>You expressly understand and agree that your use of the Site is at your
                    sole legal risk and the Site is provided on an “As is” and “As available” basis. Rexius Algorithms
                    LLC and its afﬁliates, subsidiaries, ofﬁcers, directors, employees, agents, partners and licensors,
                    to the fullest extent permitted by applicable or mandatory law, expressly disclaim all warranties of
                    any kind, whether express or implied, including but not limited to the implied warranties of
                    merchantability, ﬁtness for a particular purpose, and non- infringement. In particular, Rexius
                    Algorithms LLC and its afﬁliates, subsidiaries, ofﬁcers, directors, employees, agents, partners and
                    licensors make no warranty that (a) the Service will meet your requirements; (b) your use of the
                    Site will be timely, uninterrupted, secure or error-free; (c) any information obtained by you as a
                    result of the Service will be accurate or reliable; and (d) any defects or errors in the software
                    provided to you as part of the Service or the Service itself will be corrected.</p>

                <p className={"terms-paragraph"}>Any material or content transmitted, stored, accessed, or otherwise
                    maintained through the use of the Site is done so at your own discretion and risk, and you will be
                    solely responsible for any damage to your device or loss or corruption of data that results from any
                    such use of the Site. You further acknowledge that the Site is not intended or suitable for use in
                    situations where the failure, delay, error, or inaccuracy of the content, data, or information
                    provided by the Site could lead to death, personal injury, or severe physical or environmental
                    damage. No advice or information, whether oral or written, obtained by you from Rexius Algorithms
                    LLC or through or from the service shall create any warranty not expressly stated in these
                    terms.</p>

                <div className={"terms-heading"}>Professional Disclaimer</div>

                <p className={"terms-paragraph"}>The content provided on the Site, including but not limited to any
                    financial information, analysis, opinions, and recommendations, is for informational purposes only
                    and should not be construed as professional financial advice. Rexius Algorithms LLC is not a
                    licensed financial advisor, and the information on this Site is not intended to be a substitute for
                    professional financial advice. You should seek the advice of a qualified financial professional
                    before making any investment decisions. Your use of any information or materials on this Site is
                    entirely at your own risk.</p>

                <p className={"terms-paragraph"}>Rexius Algorithms LLC is not responsible for any loss or damage you may
                    experience as a result of relying on the information provided on this Site. This includes, but is
                    not limited to, financial loss, loss of data, or damage to your computer systems. You agree that
                    Rexius Algorithms LLC will not be held liable for any damages arising from your use of or reliance
                    on any content available on the Site.</p>

                <div className={"terms-heading"}>Market Data Disclaimer</div>

                <p className={"terms-paragraph"}>If the stock market is not currently open, all data provided on the
                    Site is from the last time the stock market was open. There is no extended hours data (no pre-market
                    or after-hours data) available on the Site. You understand and acknowledge that stock market
                    information displayed on the Site may not be up-to-date or accurate. Market data is subject to
                    delays and inaccuracies, and you should verify the information through other reliable sources before
                    making any trading decisions. Rexius Algorithms LLC does not guarantee the accuracy, completeness,
                    or timeliness of the data provided and is not responsible for any trading or investment decisions
                    made based on this information.</p>

                <div className={"terms-heading"}>Notification Disclaimer</div>

                <p className={"terms-paragraph"}>We may send you notifications from time to time or as requested based
                    on the Services you inquire about. Notification conditions are checked on a schedule, and all
                    conditions may need to be true for a minimum of 10 minutes for the notification emails to be sent.
                    However, the failure to send such notifications or any errors in the delivery of notifications is
                    not the fault of Rexius Algorithms LLC. You acknowledge and agree that Rexius Algorithms LLC will
                    not be held responsible for any consequences arising from the failure to receive notifications in a
                    timely manner. It is your responsibility to regularly check the Site for updates and
                    information.</p>

                <p className={"terms-paragraph"}>By using our Services, you agree to not rely solely on notifications
                    for critical or time-sensitive information. Regularly reviewing the Site ensures you are aware of
                    the latest updates and information. Additionally, Rexius Algorithms LLC is not liable for any missed
                    opportunities, financial loss, or other damages that may occur due to the lack of, or errors in,
                    notification delivery. Your proactive engagement with the Site is essential for staying informed and
                    making well-informed decisions based on the most current information available.</p>

                <div className={"terms-heading"}>Limitation of Liability</div>

                <p className={"terms-paragraph"}>You expressly understand and agree that Rexius Algorithms LLC and its
                    afﬁliates, subsidiaries, ofﬁcers, directors, employees, agents, partners, and licensors shall not be
                    liable to you for any direct, indirect, incidental, special, consequential or exemplary damages,
                    including, but not limited to, damages for loss of proﬁts, loss of business opportunities, goodwill,
                    use, data, cost of procurement of substitute goods or services, or other intangible losses (even if
                    Rexius Algorithms LLC has been advised of the possibility of such damages), resulting from: (a) the
                    use or inability to use the Site; (b) any changes made to the Site or any temporary or permanent
                    cessation of the Site or any part thereof; (c) the unauthorized access to or alteration of your
                    transmissions or data; (d) the deletion of, corruption of, or failure to store and/or send or
                    receive your transmissions or data on or through the Site; (e) statements or conduct of any third
                    party on the service; and (f) any other matter relating to the Site.</p>

                <div className={"terms-heading"}>Indemnification</div>

                <p className={"terms-paragraph"}>You agree to defend, indemnify and hold Rexius Algorithms LLC, its
                    afﬁliates, subsidiaries, directors, ofﬁcers, employees, agents, partners and licensors harmless from
                    any claim or demand, including reasonable lawyer’s fees, made by a third party, relating to or
                    arising from: (a) any Content you submit, post, transmit, or otherwise make available through the
                    Site; (b) your use of the Site; (c) any violation by you of these Terms; or (d) your violation of
                    any rights of another. This obligation shall survive the termination or expiration of these Terms
                    and/or your use of the Site.</p>

                <div className={"terms-heading"}>Jurisdiction</div>

                <p className={"terms-paragraph"}>These Terms shall be construed in accordance with the laws of the state
                    of California, without regard to any conflict of law provisions. The waiver of any provision of
                    these Terms shall not be considered a waiver of any other provision or of our right to require
                    strict observance of each of the terms herein. If any provision of these Terms is found to be
                    unenforceable or invalid for any reason, that provision shall be severable, and all other provisions
                    shall remain in full force and effect.</p>

                <div className={"terms-heading"}>Force Majeure</div>

                <p className={"terms-paragraph"}>If a party is prevented in whole or in part from carrying out its
                    obligations under this Agreement as a result of Force Majeure, it will promptly notify the other
                    party accordingly. The notice must:</p>
                <p className={"terms-paragraph"}>• Specify the obligations and the extent to which it cannot perform
                    those obligations;</p>
                <p className={"terms-paragraph"}>• Fully describe the event of Force Majeure;</p>
                <p className={"terms-paragraph"}>• Estimate the time during which the Force Majeure will continue;
                    and</p>
                <p className={"terms-paragraph"}>• Specify the measures proposed to be adapted to remedy or abate the
                    Force Majeure.</p>

                <p className={"terms-paragraph"}>Following a notice in accordance with this Terms and while the Force
                    Majeure continues, the obligations which cannot be performed because of the Force Majeure will be
                    suspended, other than obligations to pay money that is due and payable.</p>

                <p className={"terms-paragraph"}>The party that is prevented from carrying out its obligations under
                    this Agreement as a result of Force Majeure must remedy the Force Majeure to the extent reasonably
                    practicable and resume performance of its obligations as soon as reasonably possible.</p>

                <p className={"terms-paragraph"}>The party that is prevented from carrying out its obligations under
                    this Agreement as a result of Force Majeure must take all action reasonably practicable to mitigate
                    any loss suffered by the other party as a result of the party’s failure to carry out its obligations
                    under this Agreement. The term of this Agreement will not be extended by the period of Force
                    Majeure.</p>

                <div className={"terms-heading"}>Release</div>

                <p className={"terms-paragraph"}>You forever release, discharge, and covenant not to sue Rexius
                    Algorithms LLC for any and all liability, claims, actions, and expenses that may arise, whether
                    caused by the negligence of Rexius Algorithms LLC or otherwise, in connection with your use of the
                    Service or your interaction with any party through or as a result of the Service. In other words,
                    you cannot sue Rexius Algorithms LLC if anything happens to you or your property from using the
                    Service or interacting with any party through the Service. You agree that the provisions in the
                    Limitation of Liability and Release section of this Agreement will survive any termination of your
                    account(s), the Service, or this Agreement.</p>

                <div className={"terms-heading"}>Dispute Resolution</div>

                <p className={"terms-paragraph"}>Mindful of the high cost of a legal dispute, not only in dollars but
                    also in time and energy, both you and Rexius Algorithms LLC agree to the following dispute
                    resolution procedure: In the event of any controversy, claim, action or dispute arising out of or
                    related to any transaction conducted on this Websites, or the breach, enforcement, interpretation,
                    or validity of this Agreement or any part of it ("dispute"), the party asserting the dispute shall
                    first try in good faith to settle such dispute by providing written notice to the other party by
                    registered mail describing the facts and circumstances (including any relevant documentation) of the
                    dispute, and allowing the receiving party 30 days from the date of mailing to respond to the
                    dispute. Notice shall be sent to: Rexius Algorithms LLC, Customer Service, email:
                    legal@rexiusalgorithms.com.</p>

                <p className={"terms-paragraph"}>Unless you indicate otherwise in your notice, Rexius Algorithms LLC
                    shall respond to your notice using your latest information in your online profile.</p>

                <p className={"terms-paragraph"}>In the event that Rexius Algorithms LLC is unable to resolve the
                    dispute with you through the Customer Service department referenced above, you and Rexius Algorithms
                    LLC both agree the parties shall resolve their dispute utilizing governing federal and state courts
                    located in California. You hereby waive your right to a class action lawsuit.</p>

                <div className={"terms-heading"}>Waiver/Severability</div>

                <p className={"terms-paragraph"}>The failure of Rexius Algorithms LLC to require or enforce strict
                    performance by you of any provision of this Agreement or to exercise any right under any provision
                    of this Agreement will not be construed as a waiver or relinquishment of Rexius Algorithms LLC’s
                    right to assert or rely upon any such provision or right in that or any other instance.</p>

                <p className={"terms-paragraph"}>You and Rexius Algorithms LLC agree that if any portion of this
                    Agreement is found to be illegal or unenforceable by any court of competent jurisdiction, such
                    provision will be ineffective to the extent of such determination without affecting the validity or
                    enforceability of the remaining provisions, which will continue to be in full force and effect. If
                    the Dispute Resolution section of this Agreement is found to be illegal or unenforceable then
                    neither you nor Rexius Algorithms LLC will elect to arbitrate any Dispute falling within that
                    portion of the section and such Dispute will be decided by a court of competent jurisdiction within
                    California, and you and Rexius Algorithms LLC agree to submit to the personal jurisdiction of that
                    court.</p>

                <div className={"terms-heading"}>Term and Termination</div>

                <p className={"terms-paragraph"}>This Agreement will remain in effect as long as your account is
                    up-to-date and you remain in compliance with the terms hereof, unless it has been voluntarily
                    suspended or terminated by you or Rexius Algorithms LLC. You may terminate this Agreement by
                    destroying all Service-related materials obtained from the Service, Rexius Algorithms LLC or any
                    other web site or source. The privileges granted to you under this Agreement will terminate
                    immediately and automatically without notice from Rexius Algorithms LLC if, in our sole discretion,
                    you fail to comply with any term or provision of this Agreement or for any reason in Rexius
                    Algorithms LLC’s sole discretion. Following the termination of this Agreement, your account(s), or
                    the Service, Rexius Algorithms LLC shall retain all rights to the Submissions pursuant to this
                    Agreement.</p>

                <p className={"terms-paragraph"}>At Rexius Algorithms LLC’s sole discretion, Rexius Algorithms LLC may
                    modify, suspend, change, or discontinue the Site, or may modify, suspend, change, or terminate your
                    access to the Site, for any reason or no reason, with or without notice to you and without liability
                    to you or any third party. In addition to restricting, suspending, or terminating your access to the
                    Site, for any reason or no reason, Rexius Algorithms LLC reserves the right to take appropriate
                    legal action, including without limitation pursuing civil, criminal, or injunctive redress.</p>

                <div className={"terms-heading"}>Suggestions, Comments and Feedback</div>

                <p className={"terms-paragraph"}>Should the user respond to any part of the materials contained herein
                    in the Site with any communications including feedback data, such as questions, comments,
                    suggestions, or the like, such information shall be deemed to be non-confidential and Rexius
                    Algorithms LLC and its affiliates, subsidiaries, employees, agents, partners, principals and
                    representatives shall have no obligation whatsoever with respect to such communications and shall be
                    free to reproduce, use disclose and distribute the information to others without limitation, and
                    shall be free to use in any way for any purpose whatsoever the content of such communications
                    including any ideas, know-how, techniques or concepts disclosed therein.</p>

                <div className={"terms-heading"}>Personal Data</div>

                <p className={"terms-paragraph"}>Rexius Algorithms LLC is committed to protecting and respecting your
                    personal data.</p>

                <p className={"terms-paragraph"}>Please read the <a href={"/privacy"} className={"click-here"}>Privacy
                    Policy</a> to understand our views and practices
                    regarding your personal data and how we will treat it.</p>

                <div className={"terms-heading"}>Customer Service</div>

                <p className={"terms-paragraph"}>If you do not understand any of the foregoing Terms or if you have any
                    questions or comments, please contact Customer Service a support@rexiusalgorithms.com.</p>

            </div>
            <br/>
            <Footer/>
        </div>
    )


}

export default Terms;