import Header from "./Header";
import Loading from "./Loading";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {green, grey, red, rfAccessToken} from "../constants/Constants";
import {fetchRedditEntities} from "../service/RedditService";
import {useNavigate} from "react-router-dom";
import {getJwtRole} from "../service/AuthService";
import {fetchAdminUser, fetchAdminUsers} from "../service/AdminService";

function AdminUser() {
    const navigate = useNavigate();
    const [contentError, setContentError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const path = window.location.pathname.split("/");
    const id = path[3];

    useEffect(() => {
        if (!localStorage.getItem(rfAccessToken)) {
            navigate("/login");
        } else if (getJwtRole() !== "ROLE_ADMIN") {
            navigate("/");
        }
        try {
            setLoading(true);
            fetchAdminUser(true, id)
                .then(response => {
                    setUser(response);
                    console.log(response)
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error loading admin user content:", error);
                    setContentError(true);
                    setLoading(false);
                    if (error.status === 403) {
                        navigate("/login");
                    }
                });
        } catch (error) {
            console.error("Error loading admin user content:", error);
            setContentError(true);
            setLoading(false);
            if (error.status === 403) {
                navigate("/login");
            }
        }
    }, []);

    function timestampToDate(timestamp) {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: '2-digit',
        });
        return formattedDate;
    }

    const adminContent = (

        <div className={"admin-dashboard-content fade-in"} style={{overflow: 'visible', maxWidth: '1000px'}}>
            {user?.id === 0 ?
                <div className={"center"}>No User
                </div>
                :
                <div>
                    <table className={"dashboard-table fade-in"} style={{maxWidth: '600px', margin: 'auto'}}>
                        <thead>
                        <tr className={"dashboard-tr dashboard-th"}>
                            <th className={"th-width-60-percent"}>First Name</th>
                            <th className={""}>Last Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className={"dashboard-tr"}>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner"}>{user?.firstName}</div>
                            </td>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner"}>{user?.lastName}</div>
                            </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr className={"dashboard-tr dashboard-th"}>
                            <th className={"th-width-60-percent"} style={{paddingTop: '1.5rem'}}>Email</th>
                            <th className={""} style={{paddingTop: '1.5rem'}}>Date Created</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className={"dashboard-tr"}>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner"}>{user?.email}</div>
                            </td>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner"}>{timestampToDate(user?.dateCreated)}</div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table className={"dashboard-table fade-in"} style={{maxWidth: '600px', margin: 'auto'}}>
                        <thead>
                        <tr className={"dashboard-tr dashboard-th"}>
                            <th className={""} style={{paddingTop: '1.5rem'}}>Watch List</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className={"dashboard-tr"}>
                            <td className={"dashboard-td"}>
                                <div className={"dashboard-td-inner"}>{user?.watchList &&
                                    user.watchList.map((symbol, index) => (
                                        <div className={"watchlist-symbol"}>
                                            {index === 0 &&
                                                <span className={"watchlist-symbol"}>[</span>
                                            }
                                            <span className={"watchlist-symbol"}>
                                        {symbol}
                                    </span>
                                            {index < user.watchList.length - 1 ?
                                                <span className={"watchlist-symbol"}>,&nbsp;</span>
                                                :
                                                <span className={"watchlist-symbol"}>]</span>
                                            }
                                        </div>
                                    ))
                                }</div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>

    )


    return (
        <div className={"login page"}>
            <Header/>
            {user && user?.id !== 0 ?
                <div className={"small-heading"}>{user?.id}</div>
                :
                <div className={"small-heading"}>USER</div>
            }
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"} style={{minHeight: '222px'}}>
                    {loading ?
                        <Loading/>
                        :
                        contentError ?
                            <div className={"error-loading"}>Error loading admin content</div>
                            :
                            adminContent
                    }
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default AdminUser;