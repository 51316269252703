import Header from "./Header";
import Footer from "./Footer";

function Privacy() {
    return (
        <div className={"login page terms"}>
            <Header home={false}/>
            <div className={"small-heading"}>Privacy Policy</div>
            <div className={"content"}>
                <div className={"terms-heading"}>
                    PRIVACY POLICY
                </div>
                <p className={"terms-paragraph"}>
                    Updated July 2024
                </p>
                <p className={"terms-paragraph"}>
                    Welcome to https://rexiusfinance.com! This Privacy Policy describes the data protection practices of
                    Rexius Algorithms LLC (“Rexius Algorithms LLC,” “we,” “us,” or “our”). Rexius Algorithms LLC’s
                    website, https://rexiusfinance.com is available by desktop or mobile web (the “Site”).
                    Except as explicitly provided herein, this Privacy Policy applies to information that we collect and
                    use about you when you access or use the Rexius Algorithms LLC applications, websites, software, and
                    services, or any part thereof (collectively, the “Service”). Undefined capitalized terms used herein
                    shall have the definitions as set forth in our Terms of Service.
                </p>
                <p className={"terms-paragraph"}>
                    Please read this Privacy Policy carefully to understand how we handle your information. If you do
                    not agree to this Privacy Policy, please do not use the Service. Rexius Algorithms LLC reserves the
                    right to modify this Privacy Policy at any time, without notice, for any reason. Upon such a change,
                    you will be bound by the new Privacy Policy unless you discontinue use of any product under Rexius
                    Algorithms LLC. Your use of the Service constitutes your agreement to follow and be bound by this
                    policy.
                </p>
                <div className={"terms-heading"}>
                    TABLE OF CONTENTS
                </div>
                <ol className={"terms-paragraph"}>
                    <li>User Consent</li>
                    <li>The Information We Collect</li>
                    <li>How We Use Information Collected</li>
                    <li>How We Share Your Information</li>
                    <li>Retention Policy</li>
                    <li>How We Protect Your Information</li>
                    <li>Corporate Restructuring</li>
                    <li>Other Disclosures</li>
                    <li>Links to Other Sites and Services</li>
                    <li>Cookies</li>
                    <li>Changing or Deleting Your Data</li>
                    <li>Changes to Our Privacy Policy</li>
                    <li>Opting Out</li>
                    <li>Data Breach Notification</li>
                    <li>Children’s Privacy</li>
                    <li>User Responsibilities</li>
                    <li>Legal Basis for Processing</li>
                    <li>California Privacy Notice of Collection</li>
                    <li>Other Rights</li>
                </ol>
                <div className={"terms-heading"}>
                    User Consent
                </div>
                <p className={"terms-paragraph"}>
                    By submitting any data or information through our Site or Services, you agree to the terms of this
                    Privacy Policy and you expressly consent to the collection, use, and disclosure of your data in
                    accordance with this Privacy Policy. Without limiting the foregoing, you agree to the processing of
                    your data in connection with your use of the Service.
                </p>
                <div className={"terms-heading"}>
                    The Information We Collect
                </div>
                <p className={"terms-paragraph"}>
                    We obtain information about you when you use or otherwise access the Service. Please note that we
                    need certain types of information to provide the Service to you. If you do not provide us with such
                    information, or if you ask us to delete that information, you may no longer be able to access or use
                    certain parts of our Service.
                </p>
                <div className={"terms-heading"}>
                    Information You Provide to Us
                </div>
                <p className={"terms-paragraph"}>
                    We collect information you provide directly to us. For example, you may provide us with information
                    through your:
                </p>
                <ul className={"terms-paragraph"}>
                    <li>Use of the Service;</li>
                    <li>Requests or questions you submit to us via online forms, email, or otherwise;</li>
                    <li>Account registration and administration of your account;</li>
                    <li>Requests for customer support and/or technical assistance;</li>
                    <li>Participation in surveys we may make available; or</li>
                    <li>Provision of other information to us within or outside of the Service.</li>
                </ul>
                <p className={"terms-paragraph"}>
                    This Privacy Policy applies to information collected on the Sites. We collect two types of
                    information from visitors to our Sites: (1) Personal information and (2) Non-personal information.
                    “Personal information” is information that identifies you personally and that you provide to us,
                    such as your name, address, telephone number, email address, and sometimes your Internet Protocol
                    (IP) address. We may collect this information when you create a profile on our Sites, visit our
                    Sites, or complete a purchase. “Non-personal information” can be technical in nature. It does not
                    identify you personally. Examples of non-personal information may include, but are not limited to,
                    cookies, and web beacons.
                </p>
                <p className={"terms-paragraph"}>
                    The types of data we collect directly from you include:
                </p>
                <ul className={"terms-paragraph"}>
                    <li>Registration information. If you register for an account on the Service, we collect certain
                        information such as your name, email address, and password. We also collect your facial
                        recognition, ID, passport, driver’s license, or any other identification information in order
                        for us to do a verification of your identity.
                    </li>
                    <li>Profile information. You may provide additional information about yourself to create a user
                        profile (“Profile”), including but not limited to a name and preferences. Please do not add
                        sensitive information to your Profile.
                    </li>
                    <li>Services usage. We collect information about your activity on the Service, including but not
                        limited to any contents you share, photos, videos, and your interactions with other users on the
                        Service.
                    </li>
                    <li>Search history. You may provide additional information about yourself by searching through
                        histories on the Service.
                    </li>
                    <li>Payment information. If you use our Services for any transactions or other financial
                        transactions, we collect certain payment information, such as credit or debit card number and
                        other card information, other account and authentication information, and billing, delivery, and
                        contact details.
                    </li>
                </ul>
                <div className={"terms-heading"}>
                    Information We Collect Automatically
                </div>
                <p className={"terms-paragraph"}>
                    Device/Usage Information. We and our third-party service providers may use cookies, web beacons, and
                    other tracking technologies to collect and store information about your use of the Service. As
                    described further below, we may collect and analyze information including, but not limited to: (1)
                    browser type; (2) ISP; (3) operating system; (4) access time; (5) IP address; (6) unique device
                    identifiers (“Device IDs”) (e.g., IDFA or Android Ad ID); (7) the type of device that you use; (8)
                    the MAC address of your device; (9) information regarding the location (which may include the
                    precise location) of your mobile device or of certain routers through which your device has
                    connected to the Internet; and (10) Access Tokens.
                </p>
                <div className={"terms-heading"}>
                    Sensitive Data
                </div>
                <p className={"terms-paragraph"}>
                    We understand the sensitivity of certain types of personal information, such as health-related data,
                    financial information, racial or ethnic origin, religious beliefs, and other sensitive information.
                    We are committed to treating this information with the utmost care and respect. If our Site collects
                    or processes sensitive personal information, we want to assure you that we take additional measures
                    to ensure its security and confidentiality.
                </p>
                <p className={"terms-paragraph"}>
                    When we refer to sensitive data, we mean information that could potentially lead to specific
                    discrimination or harm if it were to be misused or disclosed. This could include, but is not limited
                    to, information about your health, medical conditions, disabilities, sexual orientation, religious
                    or philosophical beliefs, racial or ethnic origin, political opinions, trade union membership,
                    genetic data, and biometric data.
                </p>
                <p className={"terms-paragraph"}>
                    Our approach to handling sensitive data is guided by the principles of transparency, explicit
                    consent, and purpose limitation. Before collecting or processing sensitive data, we will obtain your
                    explicit consent and clearly explain the purpose for which the data will be used. We want to assure
                    you that we will only use sensitive data for the specific purposes you have consented to, and we
                    will take steps to ensure its confidentiality and security.
                </p>
                <div className={"terms-heading"}>
                    How We Use Information Collected
                </div>
                <p className={"terms-paragraph"}>
                    We may use the information we collect, alone or in combination, for various business and commercial
                    purposes, including, but not limited to providing the Service as well as the following:
                </p>
                <ul className={"terms-paragraph"}>
                    <li>To provide you with content recommendations;</li>
                    <li>To tailor the content and information that we may send or display to you, to retarget content,
                        and to otherwise personalize your experiences while using our Services;
                    </li>
                    <li>For customer support and to respond to your inquiries;</li>
                    <li>For internal record-keeping purposes;</li>
                    <li>To tailor content or advertising to you, such as when we analyze your search to recommend
                        content that may be of interest to you;
                    </li>
                    <li>To communicate with you, including to send you updates, newsletters and other information that
                        may be of interest to you;
                    </li>
                    <li>To improve the Service and to research and develop innovative features and new services;</li>
                    <li>To investigate, prevent, or take action regarding unlawful or harmful activities, including
                        potential threats to physical safety, potential fraud, and violations of our Terms of Service;
                    </li>
                    <li>To safeguard our and others’ rights or property;</li>
                    <li>To otherwise administer and troubleshoot the Service;</li>
                    <li>To process payments as requested; and</li>
                    <li>To enforce our Terms of Service and Privacy Policy.</li>
                </ul>
                <p className={"terms-paragraph"}>
                    We may combine any of the information that we collect from you with other information, including
                    information that we obtain from third parties, or with information derived from any other products
                    or services we provide. For example, we may combine personal information you provide us, such as an
                    email address, with information automatically collected about your device. We may use this combined
                    information to, among other things, provide you with personalized recommendations.
                </p>
                <div className={"terms-heading"}>
                    How We Share Your Information
                </div>
                <p className={"terms-paragraph"}>
                    We may share your information with various third parties, including service providers and business
                    partners, in the following ways or for the following reasons:
                </p>
                <ul className={"terms-paragraph"}>
                    <li>With our affiliates for business purposes.</li>
                    <li>With service providers that help us operate our business by assisting us with content
                        distribution, hosting, data analytics and enhancement, operations and tech support, customer
                        support, marketing, security and other back-end support.
                    </li>
                    <li>To deliver or customize content to you. For instance, when we deliver or retrieve content, a
                        third-party content platform may receive unique information such as your IP address or browser
                        or device identifiers and related information;
                    </li>
                    <li>To tailor advertising to you when you use the Service or interact with other services, apps or
                        content, or to deliver information or offers to you that we believe may be of interest. This may
                        involve sharing various identifiers and data about your activities on our Service with third
                        party platforms (such as advertising or data platforms);
                    </li>
                    <li>When we believe in good faith that doing so is necessary to protect, enforce, or defend the
                        legal rights, privacy, safety, or property of Rexius Algorithms LLC, our affiliates, employees,
                        agents, licensors or users, or to comply with applicable law or legal process, including
                        responding to requests from public and government authorities.
                    </li>
                    <li>When we believe in good faith that doing so may prevent harm to any person.</li>
                    <li>To transfer the information we hold in the event we sell or transfer all or a portion of our
                        business or assets (such as in connection with a merger, acquisition, reorganization,
                        dissolution or liquidation) or take steps (such as due diligence) in anticipation of such a
                        transaction.
                    </li>
                    <li>In addition to the sharing described in this Privacy Policy, we may share information about you
                        with third parties whenever you consent to or direct such sharing.
                    </li>
                    <li>In addition, to help us better understand your use of the Service, we may use third-party
                        analytics on our Service. These third parties use the sorts of technologies described in the
                        “Information We Collect Automatically” section above. The information collected by this
                        technology will be disclosed to or collected directly by these service providers.
                    </li>
                </ul>
                <div className={"terms-heading"}>
                    Retention Policy
                </div>
                <p className={"terms-paragraph"}>
                    We believe in responsible data management, which includes ensuring that we retain your personal
                    information only for as long as it is necessary to fulfill the purposes for which it was collected.
                    Our retention policy is designed to strike a balance between preserving your data for legitimate
                    purposes and respecting your privacy.
                </p>
                <p className={"terms-paragraph"}>
                    The length of time we retain your personal information depends on various factors, including the
                    nature of the information, the purposes for which it was collected, and any legal obligations that
                    require us to keep the information for a specific period.
                </p>
                <p className={"terms-paragraph"}>
                    Why Retain Data: We retain your personal information to provide you with the best possible
                    experience when using our Site. This includes ensuring the functionality of your account, providing
                    customer support, and maintaining accurate records of transactions and interactions.
                </p>
                <p className={"terms-paragraph"}>
                    Retention Periods: Generally, we will keep your personal information for the duration of our
                    business relationship with you and as long as necessary to achieve the purposes outlined in this
                    Privacy Policy. After the retention period expires, we will securely delete or anonymize your
                    personal information to prevent unauthorized access.
                </p>
                <p className={"terms-paragraph"}>
                    Backup and Archiving: It's important to note that copies of your personal information may remain in
                    our backup and archival systems for a limited period of time, even after deletion from our active
                    systems. These measures are in place to ensure data integrity, business continuity, and compliance
                    with legal and regulatory requirements.
                </p>
                <p className={"terms-paragraph"}>
                    Your Control: We understand that your personal information is valuable, and we want you to have
                    control over it. If you wish to have your personal information deleted or if you have any questions
                    about our retention practices, please contact us using the contact information provided below.
                </p>
                <div className={"terms-heading"}>
                    How We Protect Your Information
                </div>
                <p className={"terms-paragraph"}>
                    We take various technical and organizational measures to protect your data against accidental or
                    unlawful destruction or accidental loss, alteration, unauthorized disclosure or access. However, no
                    method of transmission over the Internet or across devices, or method of electronic storage, is
                    absolutely secure. Therefore, while we strive to protect your information, we cannot guarantee its
                    absolute security.
                </p>
                <div className={"terms-heading"}>
                    Corporate Restructuring
                </div>
                <p className={"terms-paragraph"}>
                    We may share some or all of your data in connection with or during negotiation of any merger,
                    financing, acquisition or dissolution transaction or proceeding involving sale, transfer,
                    divestiture, or disclosure of all or a portion of our business or assets. In the event of an
                    insolvency, bankruptcy, or receivership, and the data may also be transferred as a business asset.
                    If another company acquires our company, business, or assets, that company will possess the data
                    collected by us and will assume the rights and obligations regarding your data as described in this
                    Privacy Policy.
                </p>
                <div className={"terms-heading"}>
                    Other Disclosures
                </div>
                <p className={"terms-paragraph"}>
                    Regardless of any choices you make regarding your personal information, we may disclose personal
                    information if we believe in good faith that such disclosure is necessary (i) in connection with any
                    legal investigation; (ii) to comply with relevant laws or to respond to subpoenas or warrants served
                    on the Company; (iii) to protect or defend the rights or property of the Company or users of the
                    Site or Services; and/or (iv) to investigate or assist in preventing any violation or potential
                    violation of the law, this Privacy Policy.
                </p>
                <p className={"terms-paragraph"}>
                    Please be aware that we may transfer and process personal information to servers located in
                    countries other than your own. These countries may have data protection laws that are different from
                    the laws of your country (and, in some cases, may not be as protective). However, we have taken
                    appropriate safeguards to require that your personal information will remain protected in accordance
                    with this Privacy Policy.
                </p>
                <p className={"terms-paragraph"}>
                    When we transfer personal information from the European Economic Area (EEA) to countries that have
                    not been found by the European Commission to provide adequate protection, we use appropriate
                    safeguards such as standard contractual clauses approved by the European Commission or other
                    appropriate transfer mechanisms.
                </p>
                <p className={"terms-paragraph"}>
                    By using the Service or providing us with any information, you consent to the transfer, processing,
                    and storage of your information outside of your country, including in countries that may not have
                    the same level of protection for personal information as your country. If you have any questions
                    about our data transfer practices, please contact us using the contact information provided below.
                </p>
                <div className={"terms-heading"}>
                    Links to Other Websites and Services
                </div>
                <p className={"terms-paragraph"}>
                    The Service may contain links to third-party websites or services. We are not responsible for the
                    content or practices of those websites or services. The collection, use, and disclosure of your
                    information will be subject to the privacy policies of the third-party websites or services, and not
                    this Privacy Policy. We urge you to read the privacy and security policies of these third parties.
                </p>
                <div className={"terms-heading"}>
                    Cookies
                </div>
                <p className={"terms-paragraph"}>
                    If you decide at any time that you no longer wish to accept Cookies for any of the purposes
                    described above, then you can instruct your browser, by changing its settings, to stop accepting
                    Cookies or to prompt you before accepting a Cookie from the websites you visit. Consult your
                    browser's technical information. If you do not accept Cookies, however, you may not be able to use
                    all functionality of the Site. If you have any questions about how to disable or modify Cookies,
                    please let us know at the contact information provided below.
                </p>
                <div className={"terms-heading"}>
                    Changing or Deleting Your Data
                </div>
                <p className={"terms-paragraph"}>
                    All users may review, update, correct or delete the data in their user account by contacting us at
                    the address listed below. We will use commercially reasonable efforts to honor your request. We may
                    retain an archived copy of your records as required by law or for legitimate business purposes.
                </p>
                <div className={"terms-heading"}>
                    Changes to Our Privacy Policy
                </div>
                <p className={"terms-paragraph"}>
                    We may change this Privacy Policy to reflect changes in the law, our information practices or the
                    features of the Service. At the top of our Privacy Policy, we will indicate the date of the most
                    recent update. If we make a material change to the Policy, you will be provided with appropriate
                    notice in accordance with legal requirements. By continuing to use the Service, you are confirming
                    that you have read and understood the latest version of this Privacy Policy.
                </p>
                <div className={"terms-heading"}>
                    Opting Out
                </div>
                <p className={"terms-paragraph"}>
                    You may opt out of certain marketing emails or newsletters you receive from us. If you opt out, we
                    may still send you administrative emails.
                </p>
                <p className={"terms-paragraph"}>
                    You may send requests about your personal information to the contact email address listed below. You
                    may request that we limit certain information that we collect.
                </p>
                <div className={"terms-heading"}>
                    Data Breach Notification
                </div>
                <p className={"terms-paragraph"}>
                    Your privacy and the security of your personal information are of paramount importance to us. In the
                    unfortunate event of a data breach that affects your personal information, we want to assure you
                    that we take immediate and appropriate steps to address the situation and mitigate any potential
                    harm.
                </p>
                <p className={"terms-paragraph"}>
                    A data breach refers to the unauthorized access, disclosure, or loss of personal information that
                    could compromise its security and confidentiality. In the unlikely event that a data breach occurs,
                    we will take the following steps:
                </p>
                <ul className={"terms-paragraph"}>
                    <li>Notification: We will promptly notify you and any relevant authorities in compliance with
                        applicable data protection laws. We will provide you with information about the nature of the
                        breach, the types of data affected, and the measures we are taking to address the breach.
                    </li>
                    <li>Mitigation: We will work diligently to mitigate any potential harm caused by the breach. This
                        may include implementing additional security measures, investigating the breach's impact, and
                        cooperating with law enforcement if necessary.
                    </li>
                    <li>Support and Guidance: We understand that data breaches can be unsettling, and we are here to
                        support you. We will provide guidance on steps you can take to protect yourself, such as
                        changing passwords or monitoring your accounts for suspicious activity.
                    </li>
                    <li>Prevention: Our commitment to your data's security extends to preventing future breaches. We
                        will conduct a thorough review of our security practices and implement any necessary
                        improvements to prevent similar incidents in the future.
                    </li>
                </ul>
                <p className={"terms-paragraph"}>
                    While we take every precaution to prevent data breaches, it's important to note that no system is
                    entirely immune to risks. We want you to be informed and reassured that we have your best interests
                    at heart and will act promptly and transparently in the event of a breach.
                </p>
                <div className={"terms-heading"}>
                    Children’s Privacy
                </div>
                <p className={"terms-paragraph"}>
                    Our Site is not intended for use by individuals under the age of 13 or the relevant age specified by
                    the laws of your jurisdiction ("Child" or "Children"). We do not knowingly collect personal
                    information from Children. If you are a parent or guardian and you believe that your Child has
                    provided us with personal information, please contact us using the contact information provided
                    below. If we become aware that we have collected personal information from Children without
                    verification of parental consent, we will take steps to remove that information from our servers.
                </p>
                <p className={"terms-paragraph"}>
                    If you are a Child, please do not use or provide any personal information through the Service. If
                    you believe we might have any information from or about a Child, please contact us using the contact
                    information provided below.
                </p>
                <div className={"terms-heading"}>
                    User Responsibilities
                </div>
                <p className={"terms-paragraph"}>
                    Your privacy and data security are a shared responsibility between us and you. While we are
                    committed to implementing robust measures to protect your personal information, we also want to
                    empower you to take an active role in safeguarding your own data.
                </p>
                <p className={"terms-paragraph"}>
                    Secure Credentials: One of the most important steps you can take is to use strong and unique
                    passwords for your accounts. Avoid using easily guessable passwords and consider using a combination
                    of letters, numbers, and special characters. Do not share your login credentials with anyone.
                </p>
                <p className={"terms-paragraph"}>
                    Caution with Information Sharing: While our Site is designed to provide a safe and secure
                    environment for your interactions, exercise caution when sharing personal information, especially in
                    public forums or areas accessible by other users. Avoid sharing sensitive information that could be
                    misused.
                </p>
                <p className={"terms-paragraph"}>
                    Privacy Settings: Take advantage of the privacy settings available within our Site. Adjust your
                    preferences to control who can access your information and limit the visibility of your personal
                    details.
                </p>
                <p className={"terms-paragraph"}>
                    Security Updates: Keep your device's operating system, browsers, and security software up to date.
                    Regularly applying security updates helps protect your device from vulnerabilities that could be
                    exploited by malicious actors.
                </p>
                <p className={"terms-paragraph"}>
                    Phishing Awareness: Be cautious of emails, messages, or websites that request your personal
                    information or credentials. Phishing attempts often mimic legitimate communications, so verify the
                    sender's identity and the authenticity of requests before providing any information.
                </p>
                <p className={"terms-paragraph"}>
                    Educate Yourself: Stay informed about best practices for online privacy and security. Educate
                    yourself about potential risks and ways to mitigate them. Familiarize yourself with our Site's
                    privacy features and settings.
                </p>
                <div className={"terms-heading"}>
                    Legal Basis for Processing
                </div>
                <p className={"terms-paragraph"}>
                    We believe in transparency when it comes to the legal basis for processing your personal
                    information. Under data protection laws, every instance of collecting and using personal information
                    must have a valid legal basis. We want to ensure that you are informed about the reasons behind the
                    processing of your data.
                </p>
                <p className={"terms-paragraph"}>
                    Consent: In many cases, we rely on your explicit and informed consent as the legal basis for
                    processing your personal information. Before collecting your data, we will clearly explain the
                    purposes for which the data will be used and seek your consent. You have the right to withdraw your
                    consent at any time.
                </p>
                <p className={"terms-paragraph"}>
                    Contractual Necessity: In some situations, the processing of your personal information is necessary
                    for the performance of a contract between you and us. For example, when you create an account or
                    make a purchase, we need to process certain data to fulfill our contractual obligations.
                </p>
                <p className={"terms-paragraph"}>
                    Legal Obligations: We may process your personal information when required to comply with legal
                    obligations. This includes situations where we need to respond to legal requests, court orders, or
                    other legal proceedings.
                </p>
                <p className={"terms-paragraph"}>
                    Legitimate Interests: We may also process your personal information based on our legitimate
                    interests, provided that such processing is not overridden by your rights and interests. Our
                    legitimate interests could include improving our services, conducting analytics, ensuring the
                    security of our Site, and preventing fraud.
                </p>
                <p className={"terms-paragraph"}>
                    Protection of Vital Interests: In rare cases, we may process personal information to protect
                    someone's vital interests, such as in medical emergencies.
                </p>
                <p className={"terms-paragraph"}>
                    Public Task: If we are performing tasks in the public interest or exercising official authority, we
                    may process personal information on this legal basis.
                </p>
                <p className={"terms-paragraph"}>
                    It's important to note that we assess the legal basis for each processing activity to ensure that it
                    aligns with the principles of fairness, transparency, and accountability. We are committed to
                    respecting your rights and providing you with a clear understanding of how and why we process your
                    personal information.
                </p>
                <div className={"terms-heading"}>
                    California Privacy Notice of Collection
                </div>
                <p className={"terms-paragraph"}>
                    Under the laws of California and certain other US states (i.e., Virginia), we are required to
                    provide you with the following additional information about: (1) the purpose for which we use each
                    category of “personal information” we collect; and (2) the categories of third parties to which we
                    (a) disclose such personal information for a business purpose, (b) “share” personal information for
                    “cross-context behavioural advertising,” and/or (c) “sell” such personal information.
                </p>
                <p className={"terms-paragraph"}>
                    Under California law, “sharing” is defined as the targeting of advertising to a consumer based on
                    that consumer’s personal information obtained from the consumer’s activity across websites, and
                    “selling” is defined as the disclosure of personal information to third parties in exchange for
                    monetary or other valuable consideration. We do not sell or share your personal information,
                    including any Sensitive Personal Information. We also do not sell or share and have no actual
                    knowledge that we have sold or shared any personal information of anyone under 16 years of age.
                </p>
                <p className={"terms-paragraph"}>
                    For more information about each category, purpose of use, and the third parties to which we disclose
                    information, please see the “Categories of Personal Information We Collect”, “Purpose of Collection”
                    and “Categories of 3rd Parties to which Rexius Algorithms LLC discloses this Personal Information
                    for Business Purpose” sections.
                </p>
                <table className={"privacy-table terms-paragraph"}>
                    <thead>
                    <tr>
                        <th className={"th-width-30-percent privacy-th"} style={{textAlign: 'center'}}>Categories of Personal Information</th>
                        <th className={"th-width-30-percent privacy-th"} style={{textAlign: 'center'}}>Purpose of Collection</th>
                        <th className={"th-width-30-percent privacy-th"} style={{textAlign: 'center'}}>Categories of 3rd Parties to which Rexius
                            Algorithms LLC discloses this Personal Information
                            for Business Purpose
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={"privacy-tr"}>
                        <td className={"privacy-td"}>Identifiers</td>
                        <td className={"privacy-td"}>
                            <ul>
                                <li className={"privacy-li"}>Service providers;</li>
                                <li className={"privacy-li"}>Related affiliates;</li>
                                <li className={"privacy-li"}>Advertising networks;</li>
                                <li className={"privacy-li"}>Data analytics providers;</li>
                                <li className={"privacy-li"}>Operating systems and platforms; and</li>
                                <li className={"privacy-li"}>Social networks.</li>
                            </ul>
                        </td>
                        <td className={"privacy-td"}>
                            <ul>
                                <li className={"privacy-li"}>Advertising networks;</li>
                                <li className={"privacy-li"}>Marketing data companies;</li>
                                <li className={"privacy-li"}>Data analytics providers;</li>
                                <li className={"privacy-li"}>Operating systems and platforms;</li>
                                <li className={"privacy-li"}>Financial services companies;</li>
                                <li className={"privacy-li"}>Related affiliates.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr className={"privacy-tr"}>
                        <td className={"privacy-td"}>Categories of information described in Section 1798.80(e) of the
                            California Civil Code (such
                            as physical characteristics or description, insurance policy number, bank account number,
                            credit card number, debit card number, or any other financial information, medical
                            information, or health insurance information)
                        </td>
                        <td className={"privacy-td"}></td>
                        <td className={"privacy-td"}></td>
                    </tr>
                    <tr className={"privacy-tr"}>
                        <td className={"privacy-td"}>Characteristics of protected classifications under California or
                            federal law (such as race
                            or gender)
                        </td>
                        <td className={"privacy-td"}></td>
                        <td className={"privacy-td"}></td>
                    </tr>
                    <tr className={"privacy-tr"}>
                        <td className={"privacy-td"}>Internet or other electronic network activity information (such as
                            browsing history, search
                            history, interactions with a website, email, application, or advertisement)
                        </td>
                        <td className={"privacy-td"}></td>
                        <td className={"privacy-td"}></td>
                    </tr>
                    <tr className={"privacy-tr"}>
                        <td className={"privacy-td"}>Biometric information (such as call recordings)</td>
                        <td className={"privacy-td"}></td>
                        <td className={"privacy-td"}></td>
                    </tr>
                    <tr className={"privacy-tr"}>
                        <td className={"privacy-td"}>Professional or employment-related information</td>
                        <td className={"privacy-td"}></td>
                        <td className={"privacy-td"}></td>
                    </tr>
                    </tbody>
                </table>
                <div className={"terms-heading"}>
                    Other Rights
                </div>
                <p className={"terms-paragraph"}>
                    This Privacy Policy is drafted to comply with the California Consumer Privacy Act (CCPA). We are
                    committed to handling your personal information in accordance with the CCPA’s requirements and
                    ensuring your rights are respected. You have certain rights under CCPA regarding the personal
                    information we hold about you. These rights include:
                </p>
                <ul className={"terms-paragraph"}>
                    <li>Right to Access: You have the right to request access to the personal information we hold about
                        you and to receive a copy of that information.
                    </li>
                    <li>Right to Rectification: If the personal information we hold about you is inaccurate or
                        incomplete, you have the right to ask us to correct it.
                    </li>
                    <li>Right to Erasure: You can request that we delete or remove personal information in certain
                        circumstances, such as when the information is no longer necessary for the purposes for which it
                        was collected.
                    </li>
                    <li>Right to Restrict Processing: You have the right to ask us to restrict the processing of your
                        personal information in certain circumstances, such as if you contest the accuracy of the data
                        or if the processing is unlawful.
                    </li>
                    <li>Right to Data Portability: You can request to receive the personal information you provided to
                        us in a structured, commonly used, and machine-readable format and have the right to transmit
                        that information to another controller.
                    </li>
                    <li>Right to Object: You have the right to object to the processing of your personal information in
                        certain circumstances, including for direct marketing purposes.
                    </li>
                    <li>Rights in Relation to Automated Decision Making and Profiling: You have the right not to be
                        subject to a decision based solely on automated processing, including profiling, which produces
                        legal effects concerning you or similarly significantly affects you.
                    </li>
                </ul>
                <p className={"terms-paragraph"}>
                    To exercise any of these rights, please contact us using the contact information provided below. We
                    will respond to your request within a reasonable timeframe and may ask you for further information
                    to verify your identity before acting on the request.
                </p>
                <p className={"terms-paragraph"}>
                    Remember, these rights might be subject to specific limitations and exceptions under applicable data
                    protection laws.
                </p>
                <p className={"terms-paragraph"}>
                    You have the right to erase any personal information processed by us at any time except for the
                    following situations:
                </p>
                <ul className={"terms-paragraph"}>
                    <li>You have an ongoing matter with Customer Service;</li>
                    <li>You have an open order which has not yet been shipped or partially shipped;</li>
                    <li>You have an unsettled debt with us, regardless of the payment method;</li>
                    <li>If you are suspected or have misused our Services within the last four years;</li>
                    <li>Your credit application has been rejected within the last three months; and</li>
                    <li>If you have made any purchase, we will keep your personal information in connection to your
                        transaction for book-keeping purposes.
                    </li>
                </ul>
                <div className={"terms-heading"}>
                    Contact Us
                </div>
                <p className={"terms-paragraph"}>
                    If you have any questions or concerns about this Privacy Policy or our privacy practices, please
                    contact us at support@rexiusalgorithms.com
                </p>
            </div>
            <br/>
            <Footer/>
        </div>
    );
}

export default Privacy;
